import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';

import {DataColumn} from '../model/data-column';
import {FkInfo} from '../model/fk-info';
import {FkService} from '../fk/fk.service';
import {Logger} from '../log/logger';
import {CResponseFk} from '../model/c-response-fk';
import {selectFkCache} from '../fk/fk.selectors';
import {FkCache} from '../fk/fk-cache';

@Component({
  selector: 'acc-fk-search',
  templateUrl: './fk-search.component.html',
  styleUrls: ['./fk-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FkSearchComponent implements OnChanges, OnDestroy {

  /** Data Column */
  @Input() dataColumn: DataColumn = new DataColumn();
  /** Field Label Override */
  @Input() label?: string;
  /** Initial Search Term */
  @Input() searchTerm?: string;
  /** Parent Map */
  @Input() parentMap: { [key: string]: string } = {};
  /** return value */
  @Output() fkSelected = new EventEmitter<FkInfo>();

  @ViewChild('searchTerm', {static: true})
  inputElement?: ElementRef;
  @ViewChildren('fktr')
  searchTrs?: QueryList<ElementRef<HTMLTableRowElement>>;

  /** Options */
  options: FkInfo[] = [];
  /** Cache if complete */
  fkCache?: FkCache;

  countTotal: number = 0;
  countDisplayed: number = 0;
  countMatched: number = 0;
  touched: boolean = false;

  /** Db Busy */
  busy: boolean = false;
  /** Db Message */
  message?: string;
  /** Db Error */
  error?: string;

  /** Info */
  info?: string;

  private log: Logger = new Logger('FkSearch');
  private subscription?: Subscription;

  constructor(private service: FkService,
              private store: Store<object>,
              private sanitizer: DomSanitizer) {
  }

  get selectDisabled(): boolean {
    return this.options.length === 0;
  }

  /**
   * handle changes = subscribe fkCache, search
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.dataColumn) {
      if (!this.label) { // set label
        this.label = this.dataColumn.label;
      }
      this.log.setSubName(this.dataColumn?.fkTable);

      // get fkCache
      if (!this.fkCache || this.fkCache.fkTable !== this.dataColumn.fkTable) {
        // this.log.debug('ngOnChanges', this.fkCache)();
        this.fkCache = undefined;
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        if (this.dataColumn.fkTable) {
          this.subscription = this.store.pipe(
            select(selectFkCache(this.dataColumn.fkTable)))
            .subscribe((fkCache) => {
              this.log.debug('ngOnChanges.result complete=' + fkCache.isComplete, fkCache, this.parentMap)();
              this.fkCache = fkCache;
            });
        }
      }
    } // dataColumn

    if (this.searchTerm && this.inputElement) { // set input value
      this.inputElement.nativeElement.value = this.searchTerm;
    }

    let someChange = false;
    Object.values(changes).forEach((chg: SimpleChange) => {
      if (!someChange) {
        if (chg.isFirstChange()) {
          someChange = true;
        } else if (!Object.is(chg.currentValue, chg.previousValue)) {
          if (JSON.stringify(chg.currentValue) !== JSON.stringify(chg.previousValue)) {
            someChange = true;
          } else {
            //  this.log.debug('ngOnChanges noChange', chg)();
          }
        }
      }
    });
    if (someChange) {
      // use cache or query
      if (this.fkCache && this.fkCache.isComplete) {
        this.log.debug('ngOnChanges.fkCache', changes, this.parentMap)();
        this.updateDisplay(this.fkCache.options(this.parentMap));
      } else if (this.fkCache && Object.values(this.parentMap).length > 0) {
        this.log.info('ngOnChanges child', this.fkCache, this.parentMap)();
        this.updateDisplay(this.fkCache.options(this.parentMap));
      } else { // search
        this.log.info('ngOnChanges.search', this.fkCache, this.parentMap)();
        this.onSearch();
      }
    }
  } // ngOnChanges

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = undefined;
  }

  onCancel(): void { // onSearchFkSelected
    this.fkSelected.emit(new FkInfo().empty(this.dataColumn.fkTable)); // closes
  }

  /**
   * Select first selected or matched
   */
  onClickSelect(): void {
    this.log.debug('onClickSelected')();
    for (const op of this.options) {
      if (op.isSelected) {
        this.fkSelected.emit(op); // closes
        return;
      }
    }
    for (const op of this.options) {
      if (op.isMatched) {
        this.fkSelected.emit(op); // closes
        return;
      }
    }
  } // onClickSelect

  onClose(): void { // onSearchFkSelected
    this.fkSelected.emit(undefined);
  }

  /**
   * Button click = go to DB
   */
  onSearch(): void {
    const value = this.inputElement?.nativeElement.value;
    this.log.info('onSearch', 'term=' + value)();

    if (this.dataColumn.fkTable) {
      this.busy = true;
      this.service.sendFkSearch(this.dataColumn.fkTable, this.parentMap, value)
        .subscribe((response: CResponseFk) => {
          this.log.debug('onSearch.result', response)();
          this.message = response.message;
          this.error = response.error;
          if (!response.error) {
            this.fkCache = new FkCache('' + this.dataColumn.fkTable, 'error'); // new not shared(locked) cache
            this.fkCache.add(response);
            this.countTotal = response.totalCount ? response.totalCount : 0;
            this.updateDisplay(response.fkInfos);
          }
          this.busy = false;
        });
    }
  } // onSearch

  /**
   * Arrow Up/Down = move selection
   */
  onSearchKeyArrow(down: boolean): void {
    let found: boolean | undefined = false;
    let index = 0;
    for (let x = 0; x < this.options.length; x++) {
      const i = down ? x : this.options.length - x - 1;
      const op = this.options[i];
      if (op.isMatched || !this.touched) {
        if (found) { // previous found
          op.isSelected = true;
          index = i;
          this.log.debug('onSearchKeyArrow-' + (down ? 'Down' : 'Up') + index, op.label)();
          found = undefined;
          break;
        }
        if (op.isSelected) {
          found = true;
          op.isSelected = false;
        }
      }
    } // forAll
    if (found !== undefined) { // found->lastOne !found->none
      for (let x = 0; x < this.options.length; x++) {
        const i = down ? x : this.options.length - x - 1;
        const op = this.options[ i ];
        if (op.isMatched || !this.touched) {
          op.isSelected = true; // select first
          index = i;
          this.log.debug('onSearchKeyArrow=' + (down ? 'Down' : 'Up') + index, op.label)();
          break;
        }
      }
    }
    this.onSearchScroll(index);
  } // onSearchKeyArrow

  /**
   * Key - Enter=search - ESC=clear - Up/Down=select
   */
  onSearchKeyUp(event: KeyboardEvent): void {
    this.searchTerm = this.inputElement?.nativeElement.value;
    this.log.debug('onSearchKeyUp ' + event.key, this.searchTerm)();
    if (event.key === 'Enter') {
      if (this.countMatched === 0) {
        this.onSearch();
      } else {
        this.onClickSelect();
      }
      return;
    } else if (event.key === 'Escape' || event.key === 'Clear') {
      this.searchTerm = '';
    } else if (event.key === 'ArrowUp') {
      this.onSearchKeyArrow(false);
      return;
    } else if (event.key === 'ArrowDown') {
      this.onSearchKeyArrow(true);
      return;
    }
    // update display
    this.touched = true;
    this.updateDisplay();
  } // onSearchKeyUp

  /**
   * scroll to selected
   */
  onSearchScroll(index: number): void {
    if (!this.searchTrs) {
      //  this.log.debug('onSearchScroll NoTRs')();
      return;
    }
    let currentIndex = 0;
    let heightOffset = 0;
    let parent: HTMLDivElement | undefined;
    this.searchTrs.forEach((ref) => {
      const tr = ref.nativeElement;
      if (!parent) {
        parent = tr.parentNode?.parentNode?.parentNode as HTMLDivElement; // tr>thead>table>div
      }
      if (currentIndex < index) {
        heightOffset += tr.getBoundingClientRect().height;
        currentIndex++;
      }
    });
    if (parent) {
      const ph = parent.getBoundingClientRect().height;
      parent.scrollTop = heightOffset - ph / 2;
      //  this.log.debug('onSearchScroll', '#' + currentIndex + ' ' + heightOffset + ' ' + ph + ' = ' + parent.scrollTop)();
    } else {
      this.log.debug('onSearchScroll NoParent', '#' + currentIndex + ' ' + heightOffset)();
    }
  } // onSearchScroll

  /**
   * Select option = emit (closes)
   * @param fkInfo selected fk
   */
  onSelect(fkInfo: FkInfo): void {
    this.fkSelected.emit(fkInfo);
  }

  /**
   * Update Display based on search term
   * @param fkInfos fk info
   */
  private updateDisplay(fkInfos?: FkInfo[]): void {
    if (fkInfos) { // new
      this.options = [];
      fkInfos.forEach((fki) => {
        if (fki.validForSelection) {
          const oo = fki.clone(fki.label === this.searchTerm);
          this.options.push(oo);
        }
      });
      this.options.sort(FkInfo.compare);
    }
    //
    this.countDisplayed = 0;
    this.countMatched = 0;
    this.log.debug('updateDisplay', 'term=' + this.searchTerm, fkInfos)();

    this.options.forEach((fki) => {
      this.countDisplayed++;
      if (fki.match(this.searchTerm ?? '', this.sanitizer)) {
        this.countMatched++;
      } else {
        fki.isSelected = false;
      }
    });
    //
    let selectedIndex = 0;
    for (const op of this.options) {
      if (op.isSelected) {
        break;
      }
      selectedIndex++;
    }
    this.info = 'matched: ' + this.countMatched + ' of ' + this.countDisplayed;
    this.onSearchScroll(selectedIndex);
  } // updateDisplay

} // FkSearchComponent
