import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AccortoService } from '../accorto.service';
import { Logger } from '../log/logger';
import { CRequestData, DataOperation } from '../model/c-request-data';
import { CResponseData } from '../model/c-response-data';
import { AccortoCUtil } from '../model/accorto-c-util';
import { DataView } from '../model/data-view';
import { DataRecord } from '../model/data-record';
import { ServiceBase } from '../utils/service-base';
import { logoutRequestAction } from '../login/login.actions';

@Injectable({
  providedIn: 'root'
})
export class DataService extends ServiceBase {

  private log: Logger = new Logger('DataService');

  constructor(private http: HttpClient,
              private config: AccortoService,
              private store: Store<object>) {
    super();
  }

  /**
   * Delete single record
   * @param record the record
   */
  deleteRecord(record: DataRecord): Observable<CResponseData> {
    const start = new Date();
    const request = new CRequestData();
    this.config.setCRequest(request);
    request.record = record;
    request.operation = DataOperation.DELETE;

    const url = this.config.server + '/data';
    this.log.info('delete ' + url + ' ' + request.tableName, request)();
    const body = JSON.stringify(request);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseData>(url, body, httpOptions)
      .pipe(
        map(response => AccortoCUtil.createCResponseData(response)), // class
        tap((r) => {
          this.log.info('delete.response ' + r.tableName, r)();
          this.markSuccess(start);
        })
      );
  } // deleteRecord

  /**
   * Query List
   * @param tableName table name
   * @param dataView optional query criteria
   */
  query(tableName: string, dataView: DataView | undefined): Observable<CResponseData> {
    const start = new Date();
    const request = new CRequestData();
    this.config.setCRequest(request);
    request.tableName = tableName;
    request.dataView = dataView;
    request.operation = DataOperation.QUERY;

    const url = this.config.server + '/data';
    this.log.info('query ' + url + ' ' + request.tableName, request)();
    const body = JSON.stringify(request);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseData>(url, body, httpOptions)
      .pipe(
        map(response => AccortoCUtil.createCResponseData(response)), // class
        tap((r) => {
          this.log.info('query.response ' + r.tableName, r)();
          if (r.isLoggedOut) {
            this.store.dispatch(logoutRequestAction());
          }
          this.markSuccess(start);
        })
      );
  } // query

  /**
   * Query Record
   * @param tableName table name
   * @param recordId record id
   */
  queryId(tableName: string, recordId: string): Observable<DataRecord> {
    const start = new Date();
    const request = new CRequestData();
    this.config.setCRequest(request);
    request.tableName = tableName;
    request.recordId = recordId;
    request.operation = DataOperation.QUERY;

    const url = this.config.server + '/data';
    this.log.info('queryId ' + url + ' ' + request.tableName, request)();
    const body = JSON.stringify(request);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseData>(url, body, httpOptions)
      .pipe(
        tap(r => {
          this.log.info('queryId.response ' + request.tableName, r)();
        }),
        map(response => AccortoCUtil.createCResponseData(response)), // class
        map(response => response.records[ 0 ]),
        tap((r) => {
          this.markSuccess(start);
        })
      );
  } // queryId

  /**
   * Save single record
   * @param record the record
   */
  saveRecord(record: DataRecord): Observable<CResponseData> {
    const start = new Date();
    const request = new CRequestData();
    this.config.setCRequest(request);
    request.record = record;
    request.operation = DataOperation.SAVE;

    const url = this.config.server + '/data';
    this.log.info('save ' + url + ' ' + request.tableName, request)();
    const body = JSON.stringify(request);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseData>(url, body, httpOptions)
      .pipe(
        map(response => AccortoCUtil.createCResponseData(response)), // class
        tap((r) => {
          this.log.info('save.response ' + r.tableName, r)();
          this.markSuccess(start);
        })
      );
  } // saveRecord

} // DataService
