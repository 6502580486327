import { MenuItem } from './menu-item';
import { CResponse } from './c-response';
import { Session } from './session';

/* GenTsClass client: com.accorto.domain.LoginResponse */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: LoginResponse
 */
export class LoginResponse extends CResponse {

  /**
   * label: Google OAuth Url
   */
  public googleOauth?: string; // optional=null

  /**
   * label: Menu Items
   */
  public menuItems: MenuItem[] = [];

  /**
   * label: Salesforce OAuth Url
   */
  public salesforceOAuth?: string; // optional=null

  /**
   * label: Connected Apps
   */
  public state?: string; // optional=null

  /**
   * label: Session
   */
  public session?: Session; // optional=null

} // LoginResponse
