import { CResponse } from './c-response';
import { UiTab } from './ui-tab';

/* GenTsClass client: com.accorto.domain.CResponseUi */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseUi
 */
export class CResponseUi extends CResponse {

  /**
   * label: UiTab
   */
  public uiTab?: UiTab; // optional=null

  /**
   * label: UiTab Id
   */
  public uiTabId?: number; // optional=null

  /**
   * label: UiTab Name
   */
  public uiTabName?: string; // optional=null

} // CResponseUi
