import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subscription, timer } from 'rxjs';
import { Logger } from '../log/logger';
import { DataColumn } from '../model/data-column';
import { FormManager } from '../form/form-manager';
import { Preference } from '../utils/preference';
import { UiFormField } from '../model/ui-form-field';

@Component({
  selector: 'acc-form-clock',
  templateUrl: './form-clock.component.html',
  styleUrls: [ './form-clock.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class FormClockComponent implements OnInit, OnChanges, OnDestroy {

  // Lightning Disabled rgb(224, 229, 238)
  static readonly COLOR_GRAY = '#E0E5EE';
  // Lightning brand-dark / text-button rgb(0, 112, 210)
  static readonly COLOR_BLUE = '#0070D2';
  // Lightning bg-success rgb(75, 202, 129)
  static readonly COLOR_GREEN = '#4BCA81';
  // Lightning bg-error rgb(212, 80, 76)
  static readonly COLOR_RED = '#D4504C';

  static readonly SEC_MIN = 60;
  static readonly SEC_HOUR = 60 * 60;
  static readonly SEC_12HOUR = 60 * 60 * 12;

  /** Form Field */
  @Input() ff?: UiFormField;
  /** Form Manager */
  @Input() fm?: FormManager;
  /** Base Id */
  @Input() theId?: string;
  /** Size in px */
  @Input() size: number = 150;

  /** Update hours */
  @Output() hoursUpdate = new EventEmitter<string>();
  /** Property Name */
  propertyName: string = '';
  /** Field Label */
  label: string = '';
  /** Data Column */
  dataColumn?: DataColumn;
  /** Form Element Control */
  control?: FormControl;
  /** In/Out */
  isInout: boolean = false;
  showClock: boolean = true;
  showClockHide: boolean = true;
  /** internal */
  seconds: number = 0;
  isRunning: boolean = false;
  /** Layout */
  readonly radius = 512;
  strokeWidth = 0;
  faceRadius = 0;
  faceStroke = '#E0E5EE';
  hhRadius = 0;
  hhStroke = '';
  hhDisplay = '';
  mmRadius = 0;
  mmStroke = '';
  mmDisplay = '';
  ssRadius = 0;
  ssStroke = '';
  ssDisplay = '';
  // hh:mm:ss
  digitalDisplay = '';
  fontSize = '';

  buttonLabel: string = '';
  buttonIcon: string = '';

  private lastHoursUpdate?: string;
  private showTimerPreference = new Preference('showTimer', 'Show Timer',
    'false', {true: 'Yes', false: 'No'});
  private log: Logger = new Logger('FormClock');
  private subscriptions: Subscription[] = [];

  /**
   * Clock - init normalized 1024
   */
  constructor() {
    this.strokeWidth = Math.ceil(this.radius / 6);
    this.faceRadius = this.radius - (this.strokeWidth / 2);
    this.ssRadius = this.radius - this.strokeWidth;
    this.mmRadius = this.radius - 2 * this.strokeWidth;
    this.hhRadius = this.radius - 3 * this.strokeWidth;
    this.fontSize = (this.strokeWidth * 1.5).toFixed(0);
    //
    this.showTimerPreference.load();
    this.showClock = this.showTimerPreference.isValue;
    this.stop0(true); // initialize button to "Start"
  } // constructor

  get stopDisabled(): boolean {
    return this.buttonLabel === 'Start';
  }

  // start button
  get showStart(): boolean {
    if (this.isInout) {
      return !this.isRunning;
    }
    return true;
  }

  // stop button
  get showStop(): boolean {
    if (this.isInout) {
      return this.isRunning;
    }
    return true;
  }

  // value of property name
  get valueMinutes(): number | undefined {
    const record = this.fm?.record;
    if (record) {
      const pp = record.value(this.propertyName);
      if (pp) {
        return Number(pp);
      }
    }
    return undefined;
  } // valueMinutes

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions.length = 0;
  }

  ngOnInit(): void {
    this.subscriptions.push(timer(0, 1000)
      .subscribe((x) => this.tick()));
  }

  /**
   * Show/Hide timer
   */
  onClickShowHide(): void {
    this.showTimerPreference.toggle(); // saves
    this.showClock = this.showTimerPreference.isValue;
    if (!this.showClock) {
      this.stop(true);
    }
  }

  /**
   * Start - Pause - Continue
   */
  onClickStart(): void {
    if (this.buttonLabel === 'Start') {
      this.start(0);
    } else if (this.buttonLabel === 'Pause') {
      this.stop(false);
    } else if (this.buttonLabel === 'Continue') {
      this.start();
    } else {
      this.stop(true);
    }
  } // onClickStart

  /**
   * Stop
   */
  onClickStop(): void {
    this.stop(true);
  }

  /**
   * Reset (Stop + reset seconds)
   */
  onReset(): void {
    this.seconds = 0;
    this.stop(true);
  }

  /**
   * Mode: isInOut
   * - cannot hide
   * - set timeStart/timeEnd
   * not (timer)
   * - allow to hide
   * - set hours
   */
  ngOnChanges(changes: SimpleChanges): void {
    // size set
    if (this.ff && this.ff.dataColumn) {
      this.propertyName = '' + this.ff.name;
      this.label = '' + this.ff.label;
      this.dataColumn = this.ff.dataColumn;
      this.isInout = this.ff.description === 'inout';
    }
    if (!this.control) { // setup
      this.log.setSubName(this.propertyName);
      this.control = this.fm?.formGroup.controls[this.propertyName] as FormControl;
      this.subscriptions.push(this.control.valueChanges.subscribe((value) => {
        this.log.debug('ValueChanges', 'value=' + value)();
        if (value === undefined) { // reset
          this.onReset();
        }
        this.setShowFlags();
      }));
    }
    if (this.control) {
      const value = this.control.value;
      this.log.debug('ngOnChanges', changes,
        (this.control.dirty ? 'dirty ' : '') + (this.control.valid ? '' : 'NotValid ') + 'value=' + value)();
      this.setValue();
    } else {
      this.log.info('ngOnChanges NoControl ' + this.propertyName, this.fm?.formGroup)();
    }
    this.setShowFlags();
  } // ngChanges

  /**
   * Update display based on seconds
   */
  private display(): void {
    const seconds = this.seconds % FormClockComponent.SEC_MIN;
    const minutesAbs = Math.floor(this.seconds / FormClockComponent.SEC_MIN);
    const minutes = minutesAbs % FormClockComponent.SEC_MIN;
    const hours = Math.floor(minutesAbs / FormClockComponent.SEC_MIN);
    let text = '';
    if (hours > 0) {
      text += hours + ':';
      if (minutes < 10) {
        text += '0';
      }
    }
    text += minutes;
    text += ':';
    if (seconds < 10) {
      text += '0';
    }
    text += seconds;
    this.digitalDisplay = text;

    // stroke-dasharry
    const ssTotal = (2 * Math.PI * this.ssRadius);
    const ssMult = (seconds % FormClockComponent.SEC_MIN) / FormClockComponent.SEC_MIN; // in the second
    const ssPart = (ssTotal * ssMult);
    this.ssDisplay = ssPart.toFixed(0) + ' ' + ssTotal.toFixed(0);

    const mmTotal = (2 * Math.PI * this.mmRadius);
    const mmMult = (minutes % 60) / 60; // on the minute
    // const mmMult = (this.value % this._SEC_HOUR) / this._SEC_HOUR; // relative/smooth
    const mmPart = (mmTotal * mmMult);
    this.mmDisplay = mmPart.toFixed() + ' ' + mmTotal.toFixed(0);

    const hhTotal = (2 * Math.PI * this.hhRadius);
    // const hhMult = (hours % 12) / 12; // on the hour
    const hhMult = (this.seconds % FormClockComponent.SEC_12HOUR) / FormClockComponent.SEC_12HOUR; // relative/smooth
    const hhPart = Math.floor(hhTotal * hhMult);
    this.hhDisplay = hhPart.toFixed(0) + ' ' + hhTotal.toFixed(0);

    // timer - update hours
    if (this.isRunning && !this.isInout) {
      const hh = this.seconds / FormClockComponent.SEC_HOUR;
      const hh00 = hh.toFixed(2);
      if (this.lastHoursUpdate !== hh00) {
        // this.hoursUpdate.emit(hh00);
        const hCtrl: AbstractControl | undefined = this.fm?.formGroup.controls.hours;
        if (hCtrl) {
          hCtrl.setValue(hh00, {});
          hCtrl.markAsDirty();
        }
        this.lastHoursUpdate = hh00;
        this.log.debug('display ' + hh00, this.digitalDisplay + ' s=' + this.ssDisplay + ' m=' + this.mmDisplay + ' h=' + this.hhDisplay)();
      }
    } else {
      // this.log.debug('display', this.digitalDisplay + ' s=' + this.ssDisplay + ' m=' + this.mmDisplay + ' h=' + this.hhDisplay)();
    }
  } // display

  /**
   * Show Clock/Hide
   */
  private setShowFlags(): void {
    this.log.debug('setShowFlags', 'inOut=' + this.isInout,
      'id=' + this.fm?.record.id)();
    if (this.fm?.record.id === undefined) { // new
      this.showClock = this.showTimerPreference.isValue;
      this.showClockHide = true;
    } else { // hide
      this.showClock = false;
      this.showClockHide = false;
    }
    if (this.isInout) {
      const endDate = this.fm?.record.value('timeEnd');
      if (!endDate) {
        this.showClock = true;
        this.showClockHide = false;
      }
    }
  } // setShowFlags

  private setTimeEnd(overwrite: boolean): string {
    let timeEndMs = String(Date.now());
    const record = this.fm?.record;
    const timeEnd = record?.value('timeEnd');
    if (timeEnd === undefined || overwrite) {
      const timeEndControl = this.fm?.formGroup.controls.timeEnd;
      if (timeEndControl) {
        timeEndControl.setValue(timeEndMs, {});
        timeEndControl.markAsDirty();
      } else if (record) {
        record.changeMap.timeEnd = timeEndMs;
      }
    } else if (timeEnd) {
      timeEndMs = timeEnd; // previous
    }
    return timeEndMs;
  } // setTimeEnd

  private setTimeStart(overwrite: boolean): string {
    let timeStartMs = String(Date.now());
    const record = this.fm?.record;
    const timeStart = record?.value('timeStart');
    if (timeStart === undefined || overwrite) {
      const timeStartControl = this.fm?.formGroup.controls.timeStart;
      if (timeStartControl) {
        timeStartControl.setValue(timeStartMs, {});
        timeStartControl.markAsDirty();
      } else if (record) {
        record.changeMap.timeStart = timeStartMs;
      }
    } else if (timeStart) {
      timeStartMs = timeStart; // previous
    }
    const timeEndControl = this.fm?.formGroup.controls.timeEnd;
    if (timeEndControl) {
      timeEndControl.setValue(undefined, {});
      timeEndControl.markAsDirty();
    } else if (record) {
      record.changeMap.timeEnd = undefined;
    }
    return timeStartMs;
  } // setTimeStart

  /**
   * Set Value
   */
  private setValue(): void {
    if (this.isInout) {
      const record = this.fm?.record;
      const timeStart = record?.value('timeStart');
      const timeEnd = record?.value('timeEnd');
      if (timeStart && !timeEnd) {
        const time = Number(timeStart);
        if (!isNaN(time)) {
          const now = Date.now();
          const delta = Math.round((now - time) / 1000);
          this.log.debug('setValue', timeStart, delta)();
          this.start0(delta);
        }
      }
    }
  } // setValue

  /**
   * Start / Continue
   * @param value seconds
   */
  private start(value?: number): void {
    this.start0(value);
    //
    if (this.isInout) { // set startTime
      const timeStart = this.setTimeStart(true);
      this.log.debug('start', this.seconds, timeStart)();
    } else {
      this.log.debug('start' + (value === undefined ? '(c)' : ''), this.seconds)();
    }
  } // start

  private start0(value?: number): void {
    if (value !== undefined) {
      this.seconds = value;
    }
    this.buttonLabel = 'Pause';
    this.buttonIcon = '/assets/icons/utility-sprite/svg/symbols.svg#pause';
    //
    this.isRunning = true;
    this.ssStroke = FormClockComponent.COLOR_GREEN;
    this.mmStroke = FormClockComponent.COLOR_BLUE;
    this.hhStroke = FormClockComponent.COLOR_RED;
    this.display();
  } // start0

  /**
   * Stop / Pause
   */
  private stop(isStop: boolean): void {
    this.stop0(isStop);

    if (this.isInout && isStop) { // set endTime
      const timeEndMs = this.setTimeEnd(true);
      this.log.debug('stop' + (isStop ? '' : '(c)'), this.seconds, timeEndMs)();
    } else {
      this.log.debug('stop' + (isStop ? '' : '(c)'), this.seconds)();
    }
  } // stop

  private stop0(isStop: boolean): void {
    if (isStop) {
      this.buttonLabel = 'Start';
      this.buttonIcon = '/assets/icons/utility-sprite/svg/symbols.svg#play'; // record
    } else {
      this.buttonLabel = 'Continue';
      this.buttonIcon = '/assets/icons/utility-sprite/svg/symbols.svg#play';
    }
    this.isRunning = false;
    this.ssStroke = FormClockComponent.COLOR_GRAY;
    this.mmStroke = FormClockComponent.COLOR_GRAY;
    this.hhStroke = FormClockComponent.COLOR_GRAY;
    this.display();
  } // stop0

  /**
   * second tick
   */
  private tick(): void {
    if (this.isRunning) {
      this.seconds++;
    }
    this.display();
  }

} // FormClockComponent
