import { TenantUserI } from './tenant-user-i';
import { TenantI } from './tenant-i';

/* GenTsInterface domain: Resource */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Resource
 */
export interface ResourceI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * fk TenantUserI
   * label: User
   */
  user?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: User
   */
  userId?: number|string;

  /**
   * label: User Sf Id
   */
  userSfId?: string;

  /**
   * label: External User
   */
  isExternal?: boolean;

  /**
   * External User Authorization Name
   * label: Authorization Name
   */
  authorizationName?: string;

  /**
   * label: Code
   */
  code?: string;

  /**
   * label: Manager
   */
  isManager?: boolean;

  /**
   * label: Resource Type
   */
  resourceType?: string;

  /**
   * label: Resource Group
   */
  resourceGroup?: string;

  /**
   * label: Resource Location
   */
  resourceLocation?: string;

  /**
   * label: Skill Set
   */
  skillSet?: string[];

  /**
   * label: Weekend Days
   */
  weekendDays?: string[];

  /**
   * label: Access all Project Lines
   */
  isAccessAllProjectLines?: boolean;

  /**
   * label: Days/week
   */
  daysWeek?: number;

  /**
   * label: Capacity/day
   */
  capacityDay?: number;

} // Resource

/** Resource Definition */
export const ResourceD = {
  recordTypeName: 'Resource',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  user: {
    n: 'user',
    t: 'TenantUserI'
  },
  userId: {
    n: 'userId',
    t: 'number|string'
  },
  userSfId: {
    n: 'userSfId',
    t: 'string'
  },
  isExternal: {
    n: 'isExternal',
    t: 'boolean'
  },
  authorizationName: {
    n: 'authorizationName',
    t: 'string'
  },
  code: {
    n: 'code',
    t: 'string'
  },
  isManager: {
    n: 'isManager',
    t: 'boolean'
  },
  resourceType: {
    n: 'resourceType',
    t: 'string'
  },
  resourceGroup: {
    n: 'resourceGroup',
    t: 'string'
  },
  resourceLocation: {
    n: 'resourceLocation',
    t: 'string'
  },
  skillSet: {
    array: true,
    n: 'skillSet',
    t: 'string'
  },
  weekendDays: {
    array: true,
    n: 'weekendDays',
    t: 'string'
  },
  isAccessAllProjectLines: {
    n: 'isAccessAllProjectLines',
    t: 'boolean'
  },
  daysWeek: {
    n: 'daysWeek',
    t: 'number'
  },
  capacityDay: {
    n: 'capacityDay',
    t: 'number'
  },
}; // Resource
