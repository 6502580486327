import { Account } from './account';
import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { ProjectI, ProjectD } from './project-i';
import { Resource } from './resource';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* GenTsClass domain: Project */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Project
 */
export class Project {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * fk Resource
   * label: Resource/Manager
   */
  public resource?: Resource; // optional=true

  public resourceId?: number; // optional=true

  /**
   * User
   * label: Manager Sf Id
   */
  public managerSfId?: string; // optional=true

  /**
   * Project WBS Code
   * label: Code
   */
  public code?: string; // optional=true

  /**
   * label: Invoiced
   */
  public invoiced?: string; // optional=true

  /**
   * fk Account
   * label: Account
   */
  public account?: Account; // optional=true

  public accountId?: number; // optional=true

  /**
   * label: Account Sf Id
   */
  public accountSfId?: string; // optional=true

  public color?: string; // optional=true

  /**
   * label: Share All Lines
   */
  public isShareAllLines?: boolean; // optional=true

  /**
   * label: Status
   */
  public status?: string; // optional=true

  /**
   * label: Percent Complete
   */
  public percentComplete?: number; // optional=true

  /**
   * label: Progress
   */
  public progress?: string; // optional=true


  /**
   * @return Project record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'Project';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): Project {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, ProjectD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, ProjectD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: ProjectI): Project {
    Object.keys(ProjectD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'resource') { // fk
          this.resource = new Resource();
          this.resource.setFromI(value);
        } else if (key === 'account') { // fk
          this.account = new Account();
          this.account.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // Project
