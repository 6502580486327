import { CResponseI } from './c-response-i';

/* GenTsClass client: com.accorto.client.CResponse */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CResponse
 */
export class CResponse implements CResponseI {

  /**
   * label: Internal Category
   */
  public category?: string; // optional=null

  /**
   * label: Internal debug info
   */
  public debugInfo?: string; // optional=null

  /**
   * label: Error Message
   */
  public error?: string; // optional=null

  /**
   * label: Logged out / Session expired
   */
  public isLoggedOut?: boolean; // optional=null

  /**
   * label: General Message
   */
  public message?: string; // optional=null

  /**
   * label: request id
   */
  public rid?: number; // optional=null

} // CResponse
