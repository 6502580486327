import { DataPickOptionI } from './data-pick-option-i';
import { DataTableI } from './data-table-i';
import { TenantUserI } from './tenant-user-i';
import { TenantI } from './tenant-i';

/* GenTsInterface domain: DataColumn */
/* tslint:disable max-line-length no-inferrable-types */

export enum DataType {
  BLOB = 'BLOB',
  BOOLEAN = 'BOOLEAN',
  CLOB = 'CLOB',
  DATE = 'DATE',
  DECIMAL = 'DECIMAL',
  DOUBLE = 'DOUBLE',
  EMAIL = 'EMAIL',
  FK = 'FK',
  FKID = 'FKID',
  HOURS = 'HOURS',
  ID = 'ID',
  IMAGE = 'IMAGE',
  INTEGER = 'INTEGER',
  LIST = 'LIST',
  LONG = 'LONG',
  MAP = 'MAP',
  MINUTES = 'MINUTES',
  NULL = 'NULL',
  OBJECT = 'OBJECT',
  PASSWORD = 'PASSWORD',
  PHONE = 'PHONE',
  PICK = 'PICK',
  PICKMULTI = 'PICKMULTI',
  SFID = 'SFID',
  STRING = 'STRING',
  TIME = 'TIME',
  TIMESTAMP = 'TIMESTAMP',
  URL = 'URL'
}

export enum DataDisplay {
  BOTH = 'BOTH',
  FORM = 'FORM',
  GRID = 'GRID',
  NONE = 'NONE'
}

/**
 * name: DataColumn
 */
export interface DataColumnI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk DataTableI
   * label: Parent
   */
  dataTable?: DataTableI;

  /**
   * parent fk DataTableI key
   * label: Parent
   */
  dataTableId?: number|string;

  /**
   * label: Data Type
   */
  dataType?: DataType;

  /**
   * label: Nullable (Optional)
   */
  isNullable?: boolean;

  /**
   * Max Length
   * label: Length (max)
   */
  length?: number;

  /**
   * Minimum Length
   * label: Min Length
   */
  lengthMin?: number;

  /**
   * label: Precision
   */
  precision?: number;

  /**
   * label: Scale (decimal digits)
   */
  scale?: number;

  /**
   * label: Default Value
   */
  defaultValue?: string;

  /**
   * label: Primary Key
   */
  isPk?: boolean;

  /**
   * Column Read/Only For Client
   * help: column insertable/updateable, AutoIncrement|Version
   * label: Read/Only
   */
  isReadOnly?: boolean;

  /**
   * label: Unique
   */
  isUnique?: boolean;

  /**
   * Automatically created/calculated
   * label: Auto Calc
   */
  isAutoIncrement?: boolean;

  /**
   * label: FK
   */
  isFk?: boolean;

  /**
   * label: FK Table
   */
  fkTable?: string;

  /**
   * label: FK: Id Column
   */
  fkIdColumn?: string;

  /**
   * label: FK: Name Column
   */
  fkNameColumn?: string;

  /**
   * label: FK: Label Columns
   */
  fkLabelColumn?: string;

  /**
   * label: FK is Parent
   */
  isFkParent?: boolean;

  /**
   * column can reference multiple objects (e.g. User,Group)
   * label: FK: Multiple Ref
   */
  fkMultiReference?: string;

  /**
   * comma separated list of parent column names, first found is used
   * label: FK Restrict Column Names
   */
  fkRestrictColumnNames?: string;

  /**
   * matching values for parent column (property names)
   * label: FK: Restrict Column Values
   */
  fkRestrictColumnValues?: string;

  /**
   * label: Picklist
   */
  isPick?: boolean;

  /**
   * label: Picklist Source
   */
  pickSource?: string;

  /**
   * Field sequence (1..) to uniquely identify the record - 0=DoNotKnow|Ignored -1=CannotUse
   * label: Unique Sequence
   */
  uniqueSequenceId?: number;

  /**
   * label: External Id
   */
  isExternalId?: boolean;

  /**
   * label: Sequence No
   */
  seqNo?: number;

  /**
   * BOTH | FORM | GRID | NONE
   * label: Data Display
   */
  dataDisplay?: DataDisplay;

  /**
   * Required for Client Data Entry
   * label: Required (UI)
   */
  isUiRequired?: boolean;

  /**
   * label: Display Logic
   */
  displayLogic?: string;

  /**
   * Field Level
   * label: Read Only Logic
   */
  readOnlyLogic?: string;

  /**
   * label: Help
   */
  help?: string;

  /**
   * AutoComplete or AutoFill for input - default: off
   * help: https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill
   * label: Auto Complete
   */
  autocomplete?: string;

  /**
   * Overwrite: input - checkbox - select - fk - textarea
   * label: Control Type
   */
  controlType?: string;

  /**
   * e.g. text, checkbox, email
   * label: Input Element: Type
   */
  inputType?: string;

  /**
   * label: Input Element: RegEx
   */
  inputRegex?: string;

  /**
   * label: On Server Only
   */
  isServerOnly?: boolean;

  /**
   * label: Tab Section
   */
  tabSection?: string;

  /**
   * label: Display After ColumnName
   */
  displayAfter?: string;

  /**
   * Javascript code to calculate value
   * label: Column Code
   */
  columnLogic?: string;

  /**
   * label: Properties dependent on this column
   */
  dependentPropertyList?: string[];

  /**
   * e.g. NewLine, FullLine
   * label: Layout Hints
   */
  layout?: string;

  /**
   * label: Picklist Options
   */
  pickOptionList?: DataPickOptionI[];

  /**
   * Transient PickOption Map
   * label: Map: name(lowerCase) -> Picklist Option
   */
  pickOptionListMap?: { [key: string]: DataPickOptionI };

} // DataColumn

/** DataColumn Definition */
export const DataColumnD = {
  recordTypeName: 'DataColumn',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  dataTable: {
    n: 'dataTable',
    t: 'DataTableI'
  },
  dataTableId: {
    n: 'dataTableId',
    t: 'number|string'
  },
  dataType: {
    n: 'dataType',
    t: 'DataType'
  },
  isNullable: {
    n: 'isNullable',
    t: 'boolean'
  },
  length: {
    n: 'length',
    t: 'number'
  },
  lengthMin: {
    n: 'lengthMin',
    t: 'number'
  },
  precision: {
    n: 'precision',
    t: 'number'
  },
  scale: {
    n: 'scale',
    t: 'number'
  },
  defaultValue: {
    n: 'defaultValue',
    t: 'string'
  },
  isPk: {
    n: 'isPk',
    t: 'boolean'
  },
  isReadOnly: {
    n: 'isReadOnly',
    t: 'boolean'
  },
  isUnique: {
    n: 'isUnique',
    t: 'boolean'
  },
  isAutoIncrement: {
    n: 'isAutoIncrement',
    t: 'boolean'
  },
  isFk: {
    n: 'isFk',
    t: 'boolean'
  },
  fkTable: {
    n: 'fkTable',
    t: 'string'
  },
  fkIdColumn: {
    n: 'fkIdColumn',
    t: 'string'
  },
  fkNameColumn: {
    n: 'fkNameColumn',
    t: 'string'
  },
  fkLabelColumn: {
    n: 'fkLabelColumn',
    t: 'string'
  },
  isFkParent: {
    n: 'isFkParent',
    t: 'boolean'
  },
  fkMultiReference: {
    n: 'fkMultiReference',
    t: 'string'
  },
  fkRestrictColumnNames: {
    n: 'fkRestrictColumnNames',
    t: 'string'
  },
  fkRestrictColumnValues: {
    n: 'fkRestrictColumnValues',
    t: 'string'
  },
  isPick: {
    n: 'isPick',
    t: 'boolean'
  },
  pickSource: {
    n: 'pickSource',
    t: 'string'
  },
  uniqueSequenceId: {
    n: 'uniqueSequenceId',
    t: 'number'
  },
  isExternalId: {
    n: 'isExternalId',
    t: 'boolean'
  },
  seqNo: {
    n: 'seqNo',
    t: 'number'
  },
  dataDisplay: {
    n: 'dataDisplay',
    t: 'DataDisplay'
  },
  isUiRequired: {
    n: 'isUiRequired',
    t: 'boolean'
  },
  displayLogic: {
    n: 'displayLogic',
    t: 'string'
  },
  readOnlyLogic: {
    n: 'readOnlyLogic',
    t: 'string'
  },
  help: {
    n: 'help',
    t: 'string'
  },
  autocomplete: {
    n: 'autocomplete',
    t: 'string'
  },
  controlType: {
    n: 'controlType',
    t: 'string'
  },
  inputType: {
    n: 'inputType',
    t: 'string'
  },
  inputRegex: {
    n: 'inputRegex',
    t: 'string'
  },
  isServerOnly: {
    n: 'isServerOnly',
    t: 'boolean'
  },
  tabSection: {
    n: 'tabSection',
    t: 'string'
  },
  displayAfter: {
    n: 'displayAfter',
    t: 'string'
  },
  columnLogic: {
    n: 'columnLogic',
    t: 'string'
  },
  dependentPropertyList: {
    array: true,
    n: 'dependentPropertyList',
    t: 'string'
  },
  layout: {
    n: 'layout',
    t: 'string'
  },
  pickOptionList: {
    array: true,
    n: 'pickOptionList',
    t: 'DataPickOptionI'
  },
  pickOptionListMap: {
    n: 'pickOptionListMap',
    t: '{ [key: string]: DataPickOptionI }'
  },
}; // DataColumn
