import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {select, Store} from '@ngrx/store';

import {ClientStorage} from './utils/client-storage';
import {selectLoginSession} from './login/login.selectors';
import {Session} from './model/session';
import {CRequest} from './model/c-request';
import {Preference} from './utils/preference';
import {CRequestI} from './model/c-request-i';

/**
 * Accorto Configuration
 */
export class AccortoServiceConfig {
  /** Server base url */
  server = '';
  /** Stage Server base url */
  serverStage = '';
  /** Production Flag */
  production = true;
  /** Google Analytics Code UA-... */
  gaCode = '';
  // Timestamp
  ts = '';
  // Product Code
  productCode = '';
  // Product Title
  productTitle = '';
}

/**
 * Accorto Service + Configuration
 */
@Injectable({
  providedIn: 'root'
})
export class AccortoService {

  /** Chrome Browser */
  static isChrome = false;
  /** IE Browser */
  static isIE = false;
  /** client id / state */
  static cid: string = Math.random().toString(36).substr(2);
  /** Locale */
  static locale: string = 'en';
  /** TimeZone Id = e.g. - America/Los_Angeles */
  static timezoneId: string = '-';

  /** Server base url */
  public server: string = '';
  /** Production Flag */
  public production: boolean = true;
  /** Google Analytics Code UA-... */
  public gaCode: string = '';
  /** Timestamp */
  public ts: string = '';
  /** Product Code */
  public productCode: string = '';
  /** Product Title */
  public productTitle: string = '';
  /** In IFrame and confirmed or not in IFrame */
  public inIFrameConfirmed: boolean = false;

  /** Environment Info for Field eval and Parameters */
  public env: { [key: string]: string } = {};
  /** The Session after Login */
  session?: Session;

  /** Number of Requests */
  requestCount: number = 0;

  /** Memory Statistics */
  memStats: MemoryStats[] = [];

  /** Window Width */
  public readonly SIZE_X_LARGE = 'x-large';
  public readonly SIZE_LARGE = 'large';
  public readonly SIZE_MEDIUM = 'medium';
  public readonly SIZE_SMALL = 'small';
  public readonly SIZE_X_SMALL = 'x-small';


  constructor(@Optional() config: AccortoServiceConfig,
              @Inject(PLATFORM_ID) private platformId: Object,
              private store: Store<object>) {
    this.setStatics();
    if (config) {
      this.server = config.server;
      if (isPlatformBrowser(this.platformId)) {
        const currentURL = window.location.href;
        if (currentURL && (currentURL.includes('localhost') || currentURL.includes('.us'))) {
          this.server = config.serverStage;
        }
      }
      this.production = config.production;
      this.gaCode = config.gaCode;
      this.ts = config.ts;
      this.productCode = config.productCode;
      this.productTitle = config.productTitle;
    }
    // --- initial load message ---
    console.log(this.productCode + ' ' + AccortoService.cid
      + ' ' + this.ts // version
      + ' ' + this.server
      + ' ' + AccortoService.locale
      + ' ' + AccortoService.timezoneId
      + (this.isInIFrame() ? ' [' + document.referrer + ']' : '')
      + (AccortoService.isChrome ? ' chrome' : '')
      + (AccortoService.isIE ? ' ie' : ''),
      this.getCurrentSize() + '(' + this.getCurrentWidth() + ')',
      this.env);
    //
    this.store.pipe(select(selectLoginSession))
      .subscribe((session) => {
        this.session = session;
      });
  } // constructor

  /** Debug Flag */
  public get isDebug(): boolean {
    return Preference.prefDebug.isValue;
  }

  /**
   * @param key environment key
   * @return environment (parameter) value
   */
  public getEnv(key: string): string | undefined {
    return this.env[key];
  }

  /**
   * @param key environment key
   * @param defaultValue default if none
   * @return "true" environment value or default
   */
  public getEnvBoolean(key: string, defaultValue: boolean = false): boolean {
    const ss = this.env[ key ];
    if (ss) {
      return ss === 'true';
    }
    return defaultValue;
  }

  /**
   * @param key environment key
   * @return environment value
   */
  public getEnvOrStorage(key: string): string | undefined {
    const env = this.env[key];
    if (env) {
      return env;
    }
    return ClientStorage.get(key);
  }

  /**
   * Get Memory Stats
   */
  public getMemoryStats(): MemoryStats | undefined {
    const memory = 'memory';
    try {
      // @ts-ignore
      if (window.performance && window.performance[memory]) {
        // @ts-ignore
        const mm = window.performance[memory];
        const stats: MemoryStats = {
          time: Date.now(),
          usedJSHeapSize: mm.usedJSHeapSize,
          totalJSHeapSize: mm.totalJSHeapSize,
          jsHeapSizeLimit: mm.jsHeapSizeLimit
        };
        this.memStats.push(stats);
        return stats;
      }
    } catch (e) {
    }
    return undefined;
  } // getMemoryStats

  /** Salesforce Reference for id */
  public getSfHref(id: string): string | undefined {
    if (id && id.length > 10) {
      const url = this.getSfUrl();
      if (url) {
        return url + '/' + id;
      }
    }
    return undefined;
  } // getSfHref

  /** Salesforce instance */
  public getSfUrl(): string | undefined {
    if (this.session) {
      if (this.session.tenant && this.session.tenant.externalId) {
        return this.session.tenant.externalId;
      }
      // if (this.session.requestReferrer) {
      //   return this.session.requestReferrer;
      // }
    }
    return undefined;
  }

  /**
   * Get current url
   * @param type protocol://site.host.domain:port/path#hash?search
   */
  public getCurrentUrl(type?: string): string {
    const hostParts = window.location.hostname.split('.');
    if (type === 'protocol') {
      return window.location.protocol;
    } else if (type === 'site') {
      if (hostParts.length > 2) {
        return hostParts[0];
      }
      return '';
    } else if (type === 'host') {
      if (hostParts.length > 1) {
        return hostParts[hostParts.length - 2];
      }
      return hostParts[0];
    } else if (type === 'domain') {
      if (hostParts.length > 1) {
        return hostParts[hostParts.length - 1];
      }
      return '';
    } else if (type === 'port') {
      return window.location.port;
    } else if (type === 'path') {
      return window.location.pathname; // /confirm/0000
    } else if (type === 'hash') {
      return window.location.hash;
    } else if (type === 'search') {
      return window.location.search;
    }
    return window.location.href;
  } // getCurrentUrl

  /**
   * Window width size - Salesforce
   * @return salesforce size x-large|large|medium|small|x-small
   */
  public getCurrentSize(): string {
    // Salesforce
    //  x-small 320    @media (min-width: 320px) and (max-width: 479px)
    //  small >= 480   @media (min-width: 480px) and (max-width: 767px)
    //  medium >= 768  @media (min-width: 768px) and (max-width: 1023px)
    //  large >= 1024  @media (min-width: 1024px)
    //  x-large >=1280 @media (min-width: 1280px)

    // bootstrap
    //  xs >= 0,
    //  sm >= 576px,
    //  md >= 768px,
    //  lg >= 992px,
    //  xl >= 1200px,
    //  xxl >= 1400px

    // media queries includes scrollbar
    try {
      if (window.matchMedia('(min-width: 1280px)').matches) {
        return this.SIZE_X_LARGE;
      } else if (window.matchMedia('(min-width: 1024px)').matches) {
        return this.SIZE_LARGE;
      } else if (window.matchMedia('(min-width: 786px)').matches) {
        return this.SIZE_MEDIUM;
      } else if (window.matchMedia('(min-width: 480px)').matches) {
        return this.SIZE_SMALL;
      }
      return this.SIZE_X_SMALL;
    } catch (e) {
      console.warn(e);
    }
    // fallback
    const innerWidth = window.innerWidth;
    if (innerWidth >= 1280) {
      return this.SIZE_X_LARGE;
    } else if (innerWidth >= 1024) {
      return this.SIZE_LARGE;
    } else if (innerWidth >= 768) {
      return this.SIZE_MEDIUM;
    } else if (innerWidth >= 480) {
      return this.SIZE_SMALL;
    }
    return this.SIZE_X_SMALL;
  } // getCurrentSize

  public getCurrentWidth() {
    return window.innerWidth;
  }

  public getCurrentHeight() {
    return window.innerHeight;
  }

  public isCurrentSizeSmall(orGreater: boolean = false): boolean {
    // https://viewportsizer.com/devices/
    // iPhone6 - 414
    // iPhone12 = 390
    // iPhone12ProMax = 428
    // Pixel2xl = 412
    if (orGreater) {
      return this.getCurrentSize() !== this.SIZE_X_SMALL;
    }
    return this.getCurrentSize() === this.SIZE_SMALL; // >= 480
  }

  public isCurrentSizeXSmall(): boolean {
    return this.getCurrentSize() === this.SIZE_X_SMALL; // >= 480
  }


  /**
   * @return user email if logged in
   */
  public getUserEmail(): string {
    if (this.session) {
      if (this.session.email) {
        return this.session.email;
      }
      if (this.session.tenantUser) {
        if (this.session.tenantUser.email) {
          return this.session.tenantUser.email;
        }
      }
    }
    return '';
  } // getUserEmail

  /**
   * Application running in IFrame
   */
  public isInIFrame(): boolean {
    /*
    Salesforce
    - Blocked a frame with origin "https://wb.accorto.app" from accessing a cross-origin frame.
    - window.frameElement - returns null
    */
    let inIF: boolean = false;
    try {
      // const inIframe1 = window.location !== window.parent.location;
      // console.log('location=' + window.location + ' parent=' + window.parent.location + ' 1=' + inIframe1);
      if (window.location !== window.parent.location) {
        inIF = true;
      }
    } catch (e) { // Blocked a frame with origin
      // console.warn('#1', e);
      inIF = true;
    }
    return inIF;
  } // inIframe

  /**
   * Preference list
   */
  public preferences(): Preference[] {
    return Preference.allPreferences;
  }

  /**
   * Populate Request
   * @param request Client Request
   */
  public setCRequest(request: CRequest): void {
    request.cid = AccortoService.cid;
    if (this.session) {
      request.sid = this.session.name;
    }
    request.rid = this.requestCount++;
    // current url
    request.requestUrl = location.href;
    //
    const dd = new Date();
    request.timezoneOffsetMinutes = dd.getTimezoneOffset(); // 420
    request.timeMs = dd.getTime();
    // 3/27/2018, PDT
    request.timeString = dd.toLocaleDateString('en', { timeZoneName: 'short' });

    request.timezoneName = AccortoService.timezoneId;
    request.localeName = AccortoService.locale;
    request.referrer = document.referrer;
    this.getMemoryStats();
  } // setCRequest

  /**
   * Populate Request
   * @param request Client Request
   */
  public setCRequestI(request: CRequestI): void {
    request.cid = AccortoService.cid;
    if (this.session) {
      request.sid = this.session.name;
    }
    request.rid = this.requestCount++;
    // current url
    request.requestUrl = location.href;
    //
    const dd = new Date();
    request.timezoneOffsetMinutes = dd.getTimezoneOffset(); // 420
    request.timeMs = dd.getTime();
    // 3/27/2018, PDT
    request.timeString = dd.toLocaleDateString('en', { timeZoneName: 'short' });

    request.timezoneName = AccortoService.timezoneId;
    request.localeName = AccortoService.locale;
    request.referrer = document.referrer;
    this.getMemoryStats();
  } // setCRequestI

  /**
   * @param key environment key
   * @param value environment value
   */
  public setEnv(key: string, value: string): void {
    this.env[ key ] = value;
  }

  /**
   * find timezone (ie)
   */
  private findTimezoneId(): string {
    const dd = new Date();
    const offsetMinutes = dd.getTimezoneOffset();
    const timeString = dd.toLocaleDateString('en', { timeZoneName: 'short' });
    console.log(timeString + ' ' + offsetMinutes);
    if (timeString.includes('PST') || timeString.includes('PDT')) { // Pacific Time
      return 'America/Los_Angeles';
    }
    if (timeString.includes('MST') || timeString.includes('MDT')) { // Mountain Time
      return 'America/Denver';
    }
    if (timeString.includes('CST') || timeString.includes('CDT')) { // Central Time
      return 'America/Chicago';
    }
    if (timeString.includes('EST') || timeString.includes('EDT')) { // Eastern Time
      return 'America/New_York';
    }

    // https://www.timeanddate.com/time/zone/uk
    if (timeString.includes('BST') || timeString.includes('GMT')) { // British Summer
      return 'Europe/London';
    }
    if (timeString.includes('CET') || timeString.includes('CEST')) { // Central European
      return 'Europe/Paris';
    }
    //
    if (offsetMinutes === 0) {
      return 'Zulu';
    }

    if (offsetMinutes === 360) { // Central Time
      return 'America/Chicago';
    }
    return '-';
  }

  /**
   *  Set static values + parameters
   */
  private setStatics(): void {
    AccortoService.isChrome = navigator.vendor.includes('Google');
    // @ts-ignore
    AccortoService.isIE = document[ 'documentMode' ] !== undefined;
    // locale
    try {
      AccortoService.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    } catch (e) {
      AccortoService.locale = navigator ? navigator.language : 'en';
    }
    // timezoneId
    try {
      AccortoService.timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
    }
    if (!AccortoService.timezoneId || AccortoService.timezoneId === '-') {
      AccortoService.timezoneId = this.findTimezoneId();
    }


    // location/parameters
    const search = window.location.search;      // ?aa=bb&cc
    if (search) {
      try {
        const pp = new URLSearchParams(search);
        pp.forEach((value, key) => {
          this.env[ key ] = value;
        });
      } catch (e) {
        search.substr(1).split('&')
          .forEach(item => {
            const [key, value] = item.split('=');
            if (value) {
              this.env[key] = decodeURIComponent(value);
            } else {
              this.env[key] = '';
            }
          });
      }
    }
    const href = window.location.href;          // https://track4d.us/a/b/c?aa=bb&cc
    const pathName = window.location.pathname;  // /a/b/c
    if (pathName && !href.includes('sid=')) { // callback
      const redirect = (pathName === '/login' ? '/' : pathName) + search;
      ClientStorage.set('redirect', redirect);
      // console.log('redirect=' + redirect);
    }
    this.inIFrameConfirmed = !this.isInIFrame();
    //
    this.getMemoryStats();
  } // setStatics

} // AccortoService

/**
 * Chrome window.performance.memory
 */
export interface MemoryInfo {
  usedJSHeapSize: number;
  totalJSHeapSize: number;
  jsHeapSizeLimit: number;
}

/**
 * Timed Memory Info
 */
export interface MemoryStats extends MemoryInfo {
  time: number;
}
