import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Logger } from '../log/logger';
import { AccortoService } from '../accorto.service';
import { CRequestUi } from '../model/c-request-ui';
import { ServiceBase } from '../utils/service-base';
import { CResponseUiI } from '../model/c-response-ui-i';

@Injectable({
  providedIn: 'root'
})
export class UiService extends ServiceBase {

  private log: Logger = new Logger('UiService');

  constructor(private http: HttpClient,
              private config: AccortoService) {
    super();
  }

  /**
   * @param uiName get ui with table name
   */
  get(uiName: string): Observable<CResponseUiI> {
    const start = new Date();
    const url = this.config.server + '/ui/' + uiName;

    const request = new CRequestUi();
    request.uiTabName = uiName;
    this.config.setCRequest(request);

    this.log.info('get ' + url, request)();
    const body = JSON.stringify(request);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseUiI>(url, body, httpOptions)
      .pipe(
        tap(r => {
          this.log.info('get.response', r.message, r.error)();
          this.markSuccess(start);
        })
      );
  } // get

} // UiService
