import { createAction, props } from '@ngrx/store';
import { CResponseFk } from '../model/c-response-fk';

export enum FkActionTypes {
  FkIdRequest = '[Fk] Id Request',
  FkOptionsRequest = '[Fk] Options Request',
  FkInfoResponse = '[Fk] Info Response',
  FkError = '[Fk] Error'
}

/**
 * Get Fk info for existing/used ids
 */
export const fkIdRequestAction = createAction(
  FkActionTypes.FkIdRequest,
  props<{ fkTable: string, fkId?: string, fkIds?: string[] }>()
);

/**
 * Get Fk options to select from
 */
export const fkOptionsRequestAction = createAction(
  FkActionTypes.FkOptionsRequest,
  props<{ fkTable: string, parentMap: { [ key: string ]: string }, searchTerm?: string }>()
);


export const fkInfoResponseAction = createAction(
  FkActionTypes.FkInfoResponse,
  props<{ response: CResponseFk }>()
);


export const fkErrorAction = createAction(
  FkActionTypes.FkError,
  props<{ err: any }>()
);
