/**
 * readOnly, link, select, list, search
 */
export enum FkType {
  READONLY = 'READONLY',
  LINK = 'LINK',
  VALUE = 'VALUE',
  SELECT = 'SELECT',
  LIST = 'LIST',
  SEARCH = 'SEARCH',
  ALL = 'ALL'
}
