import { TenantI } from './tenant-i';

/* GenTsInterface domain: TenantUser */
/* tslint:disable max-line-length no-inferrable-types */

export enum SsoProvider {
  Google = 'Google',
  None = 'None',
  Salesforce = 'Salesforce'
}

/**
 * name: TenantUser
 */
export interface TenantUserI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  firstName?: string;

  email?: string;

  /**
   * label: User Email confirmed
   */
  isEmailConfirmed?: boolean;

  /**
   * label: Administrator
   */
  isAdmin?: boolean;

  /**
   * label: Manager
   */
  isManager?: boolean;

  ssoProvider?: SsoProvider;

  photoUrl?: string;

  /**
   * label: Salesforce User Name
   */
  sfUserName?: string;

  /**
   * label: Salesforce Resource Id
   */
  sfResourceId?: string;

  /**
   * label: Salesforce Org Id
   */
  sfOrgId?: string;

  /**
   * label: Salesforce Thumbnail
   */
  sfThumbnail?: string;

  /**
   * fk TenantUserI
   * label: Proxy User
   */
  proxyUser?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Proxy User
   */
  proxyUserId?: number|string;

  /**
   * label: Salesforce Proxy User
   */
  proxyUserSfId?: string;

  /**
   * label: Street
   */
  addrStreet?: string;

  /**
   * label: City
   */
  addrCity?: string;

  /**
   * label: State/Province
   */
  addrState?: string;

  /**
   * label: Zip/Postal Code
   */
  addrZip?: string;

  /**
   * label: Country
   */
  addrCountry?: string;

  phone?: string;

  mobilePhone?: string;

  /**
   * label: User Locale
   */
  locale?: string;

  /**
   * 1..7 = Sun..Sat
   * label: First Day of Week
   */
  firstDayOfWeek?: number;

  language?: string;

  /**
   * label: Timezone
   */
  timezone?: string;

  /**
   * label: Timezone Offset (s)
   */
  utcOffset?: number;

} // TenantUser

/** TenantUser Definition */
export const TenantUserD = {
  recordTypeName: 'TenantUser',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  firstName: {
    n: 'firstName',
    t: 'string'
  },
  email: {
    n: 'email',
    t: 'string'
  },
  isEmailConfirmed: {
    n: 'isEmailConfirmed',
    t: 'boolean'
  },
  isAdmin: {
    n: 'isAdmin',
    t: 'boolean'
  },
  isManager: {
    n: 'isManager',
    t: 'boolean'
  },
  ssoProvider: {
    n: 'ssoProvider',
    t: 'SsoProvider'
  },
  photoUrl: {
    n: 'photoUrl',
    t: 'string'
  },
  sfUserName: {
    n: 'sfUserName',
    t: 'string'
  },
  sfResourceId: {
    n: 'sfResourceId',
    t: 'string'
  },
  sfOrgId: {
    n: 'sfOrgId',
    t: 'string'
  },
  sfThumbnail: {
    n: 'sfThumbnail',
    t: 'string'
  },
  proxyUser: {
    n: 'proxyUser',
    t: 'TenantUserI'
  },
  proxyUserId: {
    n: 'proxyUserId',
    t: 'number|string'
  },
  proxyUserSfId: {
    n: 'proxyUserSfId',
    t: 'string'
  },
  addrStreet: {
    n: 'addrStreet',
    t: 'string'
  },
  addrCity: {
    n: 'addrCity',
    t: 'string'
  },
  addrState: {
    n: 'addrState',
    t: 'string'
  },
  addrZip: {
    n: 'addrZip',
    t: 'string'
  },
  addrCountry: {
    n: 'addrCountry',
    t: 'string'
  },
  phone: {
    n: 'phone',
    t: 'string'
  },
  mobilePhone: {
    n: 'mobilePhone',
    t: 'string'
  },
  locale: {
    n: 'locale',
    t: 'string'
  },
  firstDayOfWeek: {
    n: 'firstDayOfWeek',
    t: 'number'
  },
  language: {
    n: 'language',
    t: 'string'
  },
  timezone: {
    n: 'timezone',
    t: 'string'
  },
  utcOffset: {
    n: 'utcOffset',
    t: 'number'
  },
}; // TenantUser
