import { DataRecord } from './data-record';
import { CResponseDataI } from './c-response-data-i';
import { CResponse } from './c-response';

/* GenTsClass client: com.accorto.domain.CResponseData */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseData
 */
export class CResponseData extends CResponse implements CResponseDataI {

  /**
   * e.g. Name ASC
   * label: Primary Sort
   */
  public primarySort?: string; // optional=null

  /**
   * also for count
   * label: record count
   */
  public recordCount?: number; // optional=null

  /**
   * label: Error results (list save/delete)
   */
  public recordErrors: string[] = [];

  /**
   * label: Data Records
   */
  public records: DataRecord[] = [];

  /**
   * label: Table Id
   */
  public tableId?: number; // optional=null

  /**
   * label: Table Name
   */
  public tableName?: string; // optional=null

  /**
   * without where clause - -1 if not applicable
   * label: total record count
   */
  public totalCount?: number; // optional=null

} // CResponseData
