import { DataView } from './data-view';
import { CRequest } from './c-request';
import { DataRecord } from './data-record';

/* GenTsClass client: com.accorto.domain.CRequestData */
/* tslint:disable max-line-length no-inferrable-types */

export enum DataOperation {
  DELETE = 'DELETE',
  QUERY = 'QUERY',
  SAVE = 'SAVE'
}

/**
 * extends: CRequest
 * name: CRequestData
 */
export class CRequestData extends CRequest {

  /**
   * SELECT ... WHERE ... ORDER
   * label: Query Data View
   */
  public dataView?: DataView; // optional=null

  /**
   * label: Count only
   */
  public isCount?: boolean; // optional=null

  /**
   * label: Query Limit: Number of records
   */
  public limit?: number; // optional=null

  /**
   * label: Query Offset: Start
   */
  public offset?: number; // optional=null

  /**
   * label: Operation
   */
  public operation?: DataOperation; // optional=null

  /**
   * label: Table Name
   */
  public tableName?: string; // optional=null

  /**
   * save, delete
   * label: Data Record
   */
  public record?: DataRecord; // optional=null

  /**
   * query, delete
   * label: Record Id
   */
  public recordId?: string; // optional=null

  /**
   * query, delete
   * label: Record Ids
   */
  public recordIds: string[] = [];

  /**
   * save, delete
   * label: Data Records
   */
  public records: DataRecord[] = [];

  /**
   * label: Query Table Id
   */
  public tableId?: number; // optional=null

} // CRequestData
