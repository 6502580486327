import { CRequest } from './c-request';

/* GenTsClass client: com.accorto.domain.CRequestFk */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CRequest
 * name: CRequestFk
 */
export class CRequestFk extends CRequest {

  /**
   * label: Fk Table Name
   */
  public fkTable?: string; // optional=null

  /**
   * label: Optional Id
   */
  public fkId?: string; // optional=null

  /**
   * label: Optional List of Ids
   */
  public fkIds: string[] = [];

  /**
   * label: Optional Limit
   */
  public limit?: number; // optional=null

  /**
   * label: Optional Search Term
   */
  public searchTerm?: string; // optional=null

  /**
   * label: Optional Parent Map - propertyName->value
   */
  public parentMap: {[key: string]: string|undefined|null} = {};

} // CRequestFk
