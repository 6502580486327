import { Logger } from '../log/logger';
import { CRequestFk } from '../model/c-request-fk';

/**
 * Track Fk Requests
 */
export class FkRequests {

  /** key -> request ids */
  private static sendMap = new Map<string, number[]>();

  private static log: Logger = new Logger('FkRequests');

  /**
   * Was this requested?
   * @param fkTable table
   * @param fkId fk id
   * @param parentMap parent query
   */
  static isRequested(fkTable: string,
                     fkId?: string,
                     parentMap?: { [key: string]: string }): boolean {
    const key = this.key(fkTable, fkId, parentMap);
    const requests = this.sendMap.get(key);
    const found = requests != null && requests.length > 1;
    // this.log.info('isRequested ' + key, 'found=' + found, 'requests=' + JSON.stringify(requests))();
    return found;
  }

  /**
   * Request Reply received
   * @param request the request
   */
  static requestReceived(request: CRequestFk): void {
    const key = this.key(request.fkTable, request.fkId, request.parentMap);
    const requests: number[] | undefined = this.sendMap.get(key);
    if (requests && request.rid) {
      const index = requests.indexOf(request.rid);
      if (index !== -1) {
        if (requests.length === 1) {
          this.sendMap.delete(key);
        } else {
          requests.splice(index, 1);
        }
        // this.log.debug('requestReceived ' + key + ' ' + request.rid, this.toString())();
      } else {
        this.log.info('requestReceived ' + key, 'NotFound=' + request.rid,
          'requests=' + JSON.stringify(requests))();
      }
    } else {
      this.log.info('requestReceived ' + key, 'NotFound', request.rid, this.toString())();
    }
  } // requestReceived

  /**
   * Request Sent
   * @param request the request
   */
  static requestSent(request: CRequestFk): void {
    const key = this.key(request.fkTable, request.fkId, request.parentMap);
    let requests: number[] | undefined = this.sendMap.get(key);
    // this.log.debug('requestSent ' + key, 'requests=' + JSON.stringify(requests), request.rid)();
    if (!requests) {
      requests = [];
    }
    if (request.rid) {
      requests.push(request.rid);
      this.sendMap.set(key, requests); // single
    }
  }

  /**
   * Status info
   */
  static toString(): string {
    const count = this.sendMap.size;
    if (count === 0) {
      return 'NoOpenFkRequests';
    }
    let info = 'OpenFkRequests #' + count;
    this.sendMap.forEach((v, k) => {
      info += ' ' + k + JSON.stringify(v);
    });
    return info;
  }

  /** create key */
  private static key(fkTable?: string,
                     fkId?: string,
                     parentMap?: { [key: string]: string | undefined | null }): string {
    let kk = fkTable ? fkTable : 'None';
    if (fkId) {
      kk += '-' + fkId;
    }
    if (parentMap) {
      Object.keys(parentMap).forEach((k) => {
        kk += '_' + k + '-' + parentMap[k];
      });
    }
    return kk;
  } // key

} // FkRequests
