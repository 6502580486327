import { Project } from './project';
import { ProjectPhase } from './project-phase';
import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { ProjectLineI, ProjectLineD } from './project-line-i';
import { Resource } from './resource';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* GenTsClass domain: ProjectLine */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: ProjectLine
 */
export class ProjectLine {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * parent fk Project
   * label: Project
   */
  public project?: Project; // optional=true

  public projectId?: number; // optional=true

  /**
   * label: Project
   */
  public projectSfId?: string; // optional=true

  /**
   * parent fk ProjectPhase
   * label: Project Phase
   */
  public projectPhase?: ProjectPhase; // optional=true

  public projectPhaseId?: number; // optional=true

  /**
   * label: Project Phase
   */
  public projectPhaseSfId?: string; // optional=true

  /**
   * Project Line WBS Code
   * label: WBS
   */
  public wbs?: string; // optional=true

  /**
   * fk Resource
   * label: Resource
   */
  public resource?: Resource; // optional=true

  public resourceId?: number; // optional=true

  /**
   * label: Resource
   */
  public resourceSfId?: string; // optional=true

  /**
   * label: Line Type
   */
  public lineType?: string; // optional=true

  /**
   * label: Priority
   */
  public priority?: string; // optional=true

  /**
   * label: Invoiced
   */
  public invoiced?: string; // optional=true

  /**
   * Give access to this Line for all who have access to the Project (no assignments necessary)
   * label: Share all
   */
  public isShareAll?: boolean; // optional=true

  /**
   * in hours
   * label: Planned Effort
   */
  public plannedEffort?: number; // optional=true

  /**
   * label: Planned Start
   */
  public plannedStart?: number; // optional=true

  /**
   * in hours
   * label: Planned Effort Billable
   */
  public plannedEffortBillable?: number; // optional=true

  /**
   * label: Planned End
   */
  public plannedEnd?: number; // optional=true

  /**
   * Overwrites Resource Price
   * label: Unit Price (Billing)
   */
  public unitPrice?: number; // optional=true

  /**
   * Amount charged regardless of effort
   * label: Fixed Amount
   */
  public fixedAmount?: number; // optional=true

  /**
   * used with estimated effort
   * label: Planned Cost Rate
   */
  public plannedCostRate?: number; // optional=true

  /**
   * Amount budgeted regardless of effort
   * label: Budget Cost Amount
   */
  public plannedCostBudget?: number; // optional=true

  /**
   * used with estimated effort
   * label: Planned Rate (Average Price)
   */
  public plannedRate?: number; // optional=true

  /**
   * Amount budgeted regardless of effort
   * label: Budget Revenue Amount
   */
  public budgetAmount?: number; // optional=true

  /**
   * label: Status
   */
  public status?: string; // optional=true

  /**
   * label: Percent Complete
   */
  public percentComplete?: number; // optional=true

  /**
   * label: Progress
   */
  public progress?: string; // optional=true

  /**
   * fk ProjectLine
   * label: Prerequisite
   */
  public prerequisite?: ProjectLine; // optional=true

  public prerequisiteId?: number; // optional=true

  /**
   * Prerequisite line to be completed before starting this
   * label: Prerequisite
   */
  public prerequisiteSfId?: string; // optional=true

  /**
   * Internal list of prerequisite item Ids
   * label: Prerequisite Id List
   */
  public prerequisiteIdList?: string; // optional=true

  /**
   * If selected, to start the Line, ALL prior Lines need to be completed (the lines this line depends on).
   * label: All Complete
   */
  public isAllComplete?: boolean; // optional=true


  /**
   * @return ProjectLine record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'ProjectLine';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): ProjectLine {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, ProjectLineD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, ProjectLineD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: ProjectLineI): ProjectLine {
    Object.keys(ProjectLineD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'resource') { // fk
          this.resource = new Resource();
          this.resource.setFromI(value);
        } else if (key === 'prerequisite') { // fk
          this.prerequisite = new ProjectLine();
          this.prerequisite.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // ProjectLine
