import {ApplicationRef, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {first} from 'rxjs/operators';
import {concat, interval} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {AccortoService} from '../accorto.service';

@Injectable()
export class ServiceWorkerUpdateService {

  constructor(updates: SwUpdate,
              appRef: ApplicationRef,
              @Inject(PLATFORM_ID) private platform: Object,
              config: AccortoService) {
    //
    if (isPlatformBrowser(platform)) {
      if (updates.isEnabled) {
        console.log('ServiceWorker');

        updates.available.subscribe(event => {
          console.log('ServiceWorkerUpdate current version ', event.current);
          // if (promptUser(event)) {
          //  updates.activateUpdate().then(() => document.location.reload());
          // }
        });
        //
        updates.activated.subscribe(event => {
          console.log('ServiceWorkerUpdate old version', event.previous);
          console.log('- new version', event.current);
        });

        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
        const everySixHours$ = interval(6 * 60 * 60 * 1000);
        const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

        everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
      } // enabled
    }
  } // constructor

} // ServiceWorkerUpdateServiceS
