import { FkInfo } from './fk-info';
import { CResponse } from './c-response';

/* GenTsClass client: com.accorto.domain.CResponseFk */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseFk
 */
export class CResponseFk extends CResponse {

  /**
   * label: All FKs Loaded for FkTable
   */
  public isComplete?: boolean; // optional=null

  /**
   * label: Fk Table Name
   */
  public fkTable?: string; // optional=null

  /**
   * label: Limit used
   */
  public limit?: number; // optional=null

  /**
   * label: Fk Infos
   */
  public fkInfos: FkInfo[] = [];

  /**
   * label: Optional Parent Map (AND) used - propertyName->value
   */
  public parentMap: {[key: string]: string|undefined|null} = {};

  /**
   * label: Optional Search Term used
   */
  public searchTerm?: string; // optional=null

  /**
   * label: total count without restrictions
   */
  public totalCount?: number; // optional=null

} // CResponseFk
