import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { Resource } from './resource';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';
import { ProjectLine } from './project-line';
import { ProjectLineSharingI, ProjectLineSharingD } from './project-line-sharing-i';

/* GenTsClass domain: ProjectLineSharing */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: ProjectLineSharing
 */
export class ProjectLineSharing {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * parent fk ProjectLine
   * label: Project Line
   */
  public projectLine?: ProjectLine; // optional=true

  public projectLineId?: number; // optional=true

  /**
   * label: Project Line
   */
  public projectLineSfId?: string; // optional=true

  /**
   * fk Resource
   * label: Resource
   */
  public resource?: Resource; // optional=true

  public resourceId?: number; // optional=true

  /**
   * label: Resource
   */
  public resourceSfId?: string; // optional=true

  /**
   * 15 of 18 characters
   * label: Project
   */
  public projectSfId?: string; // optional=true

  /**
   * label: Mandatory
   */
  public isMandatory?: boolean; // optional=true

  /**
   * label: Max Effort (h) for this Resource
   */
  public maxEffort?: number; // optional=true

  /**
   * label: Percent for this Resource
   */
  public percent?: number; // optional=true

  /**
   * label: Resource Priority relative to other Resources
   */
  public resourcePriority?: number; // optional=true

  /**
   * in hours
   * label: Resource Planned Effort
   */
  public plannedEffort?: number; // optional=true

  /**
   * label: Resource Planned Effort Details
   */
  public plannedDetails?: string; // optional=true


  /**
   * @return ProjectLineSharing record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'ProjectLineSharing';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): ProjectLineSharing {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, ProjectLineSharingD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, ProjectLineSharingD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: ProjectLineSharingI): ProjectLineSharing {
    Object.keys(ProjectLineSharingD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'resource') { // fk
          this.resource = new Resource();
          this.resource.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // ProjectLineSharing
