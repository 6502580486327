/**
 * Note info
 */
export class Note {

  static staticId = 1;

  bodyId: string;
  noteId: string;

  isInfo = false;
  isWarning = false;
  isSuccess = false;
  isError = false;

  useHref: string;

  multiLine: string[];

  // auto-confirm after seconds
  autoConfirmSecs = 0;
  link?: string;

  /**
   * @param subject heading
   * @param info details - multi-line with \\n
   * @param type i(nfo) w(arn) s(success) e(error)
   */
  constructor(public subject: string = '', public info: string = '', type: string = 'i') {
    this.bodyId = 'dialog-body-id-' + Note.staticId;
    this.noteId = 'note-' + Note.staticId;
    Note.staticId++;
    // content
    this.multiLine = info.split('\n');
    //
    if (type === undefined || type.startsWith('i')) {
      this.isInfo = true;
      this.useHref = '/assets/icons/utility-sprite/svg/symbols.svg#info';
    } else if (type.startsWith('w')) {
      this.isWarning = true;
      this.useHref = '/assets/icons/utility-sprite/svg/symbols.svg#warning';
    } else if (type.startsWith('s')) {
      this.isSuccess = true;
      this.useHref = '/assets/icons/utility-sprite/svg/symbols.svg#success';
    } else {
      this.isError = true;
      this.useHref = '/assets/icons/utility-sprite/svg/symbols.svg#error';
    }
  }

} // Note
