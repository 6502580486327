import { createAction, props } from '@ngrx/store';
import { UiTabI } from '../model/ui-tab-i';


export const uiRequestAction = createAction(
  '[Ui] Request',
  props<{ uiName: string }>()
);

export const uiResponseAction = createAction(
  '[Ui/api] Response',
  props<{ uiTab: UiTabI }>()
);

export const uiErrorAction = createAction(
  '[]Ui/api] Response Error',
  props<{ err: any }>()
);
