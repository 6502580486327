import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from './modal.service';
import { ModalInfo } from './modal-info';
import { Subscription } from 'rxjs';

/**
 * Modal
 * - add tag acc-modal to html
 * - add service: private modalService: ModalService
 *
 * <code>
 *  const modalInfo = new ModalInfo(
 *    'Delete ' + this.ui.label + '?',
 *    'Delete ' + fm.record.name + '?');
 *  this.modalService.doConfirm(modalInfo,
 *    () => {
 *      this.log.info('onDelete confirmed')();
 *    },
 *    () => {
 *    }
 *  );
 * </code>
 */
@Component({
  selector: 'acc-modal',
  templateUrl: './modal.component.html',
  styleUrls: [ './modal.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewChecked {

  modalInfo?: ModalInfo;

  private subscription?: Subscription;

  constructor(private service: ModalService, private el: ElementRef) {
  }

  /**
   * If displayed, focus default button (non-destructive)
   */
  ngAfterViewChecked(): void {
    const ele = this.el.nativeElement as HTMLDivElement;
    const buttons = ele.getElementsByClassName('slds-button');
    if (buttons.length > 0) {
      ele.focus();
      if (this.modalInfo?.yesIsDestructive) {
        const no = buttons.namedItem('modal-no') as HTMLButtonElement;
        no.focus();
      } else {
        const yes = buttons.namedItem('modal-yes') as HTMLButtonElement;
        yes.focus();
      }
    }
  } // ngAfterViewChecked

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subscription = this.service.getModalInfo()
      .subscribe(modalInfo => {
        this.modalInfo = modalInfo;
      });
  }

  /** Cancel/Close */
  onCancel(): void {
    if (this.modalInfo?.cancelFn) {
      this.modalInfo.cancelFn();
    }
  }

  /**
   * Cancel on Escape
   */
  onKeyUp(event: KeyboardEvent): void {
    if (event.code === 'Escape') { // event.keyCode === 27
      this.onCancel();
    }
  }

  onNo(): void {
    if (this.modalInfo?.noFn) {
      this.modalInfo.noFn();
    }
  }

  onYes(): void {
    if (this.modalInfo?.yesFn) {
      this.modalInfo.yesFn();
    }
  }

} // ModalComponent
