import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { UiTab } from '../model/ui-tab';
import { selectUi } from './ui.selectors';
import { uiRequestAction } from './ui.actions';

/**
 * Ui Resolver
 * - loads uiTab into snapshot data
 *
 * route:
 *  path: 'ui/:uiTab',
 *  component: ObjectHomeComponent,
 *  canActivate: [ LoginGuard ],
 *  resolve: {
 *    uiTab: UiResolver
 *  }
 */
@Injectable({
  providedIn: 'root'
})
export class UiResolver implements Resolve<UiTab | undefined> {

  constructor(private store: Store<object>) {
  }

  /**
   * Load UI from route param uiTab
   */
  public resolve(route: ActivatedRouteSnapshot,
                 state: RouterStateSnapshot): Observable<UiTab | undefined> | Promise<UiTab | undefined> | UiTab {
    const uiName = route.params.uiTab;

    return this.store
      .pipe(
        select(selectUi(uiName)),
        tap(uiTab => {
          if (!uiTab) {
            this.store.dispatch(uiRequestAction({uiName}));
          }
        }),
        filter(uiTab => !!uiTab),
        first()
      );
  } // resolve

} // UiResolver
