import { AccountI } from './account-i';
import { TenantUserI } from './tenant-user-i';
import { TenantI } from './tenant-i';

/* GenTsInterface domain: Contact */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Contact
 */
export interface ContactI {

  /**
   * label: Last Modified Date
   */
  lastModifiedDate?: number;

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk AccountI
   * label: Account
   */
  account?: AccountI;

  /**
   * parent fk AccountI key
   * label: Account
   */
  accountId?: number|string;

  accountSfId?: string;

  /**
   * label: Email
   */
  email?: string;

  /**
   * label: Business Fax
   */
  fax?: string;

  /**
   * label: First Name
   */
  firstName?: string;

  /**
   * label: Home Phone
   */
  homePhone?: string;

  /**
   * label: Business Phone
   */
  phone?: string;

  /**
   * label: Last Name
   */
  lastName: string;

  /**
   * label: Mobile Phone
   */
  mobilePhone?: string;

  /**
   * label: Other Phone
   */
  otherPhone?: string;

  /**
   * label: Mailing Address
   */
  mailingAddress?: string;

  /**
   * label: Mailing City
   */
  mailingCity?: string;

  /**
   * label: Mailing Street
   */
  mailingStreet?: string;

  /**
   * label: Mailing Zip/Postal Code
   */
  mailingPostalCode?: string;

  /**
   * label: Mailing State/Province
   */
  mailingState?: string;

  /**
   * label: Title
   */
  title?: string;

  /**
   * label: Mailing Country
   */
  mailingCountry?: string;

  /**
   * fk ContactI
   * label: Reports To
   */
  reportsTo?: ContactI;

  /**
   * fk ContactI key
   * label: Reports To
   */
  reportsToId?: number|string;

  reportsToSfId?: string;

  /**
   * label: Department
   */
  department?: string;

  /**
   * label: Birthdate
   */
  birthdate?: number;

  /**
   * label: Assistant's Name
   */
  assistantName?: string;

  /**
   * label: Asst. Phone
   */
  assistantPhone?: string;

  /**
   * label: Salutation
   */
  salutation?: string;

} // Contact

/** Contact Definition */
export const ContactD = {
  recordTypeName: 'Contact',
  lastModifiedDate: {
    n: 'lastModifiedDate',
    t: 'number'
  },
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  account: {
    n: 'account',
    t: 'AccountI'
  },
  accountId: {
    n: 'accountId',
    t: 'number|string'
  },
  accountSfId: {
    n: 'accountSfId',
    t: 'string'
  },
  email: {
    n: 'email',
    t: 'string'
  },
  fax: {
    n: 'fax',
    t: 'string'
  },
  firstName: {
    n: 'firstName',
    t: 'string'
  },
  homePhone: {
    n: 'homePhone',
    t: 'string'
  },
  phone: {
    n: 'phone',
    t: 'string'
  },
  lastName: {
    n: 'lastName',
    t: 'string'
  },
  mobilePhone: {
    n: 'mobilePhone',
    t: 'string'
  },
  otherPhone: {
    n: 'otherPhone',
    t: 'string'
  },
  mailingAddress: {
    n: 'mailingAddress',
    t: 'string'
  },
  mailingCity: {
    n: 'mailingCity',
    t: 'string'
  },
  mailingStreet: {
    n: 'mailingStreet',
    t: 'string'
  },
  mailingPostalCode: {
    n: 'mailingPostalCode',
    t: 'string'
  },
  mailingState: {
    n: 'mailingState',
    t: 'string'
  },
  title: {
    n: 'title',
    t: 'string'
  },
  mailingCountry: {
    n: 'mailingCountry',
    t: 'string'
  },
  reportsTo: {
    n: 'reportsTo',
    t: 'ContactI'
  },
  reportsToId: {
    n: 'reportsToId',
    t: 'number|string'
  },
  reportsToSfId: {
    n: 'reportsToSfId',
    t: 'string'
  },
  department: {
    n: 'department',
    t: 'string'
  },
  birthdate: {
    n: 'birthdate',
    t: 'number'
  },
  assistantName: {
    n: 'assistantName',
    t: 'string'
  },
  assistantPhone: {
    n: 'assistantPhone',
    t: 'string'
  },
  salutation: {
    n: 'salutation',
    t: 'string'
  },
}; // Contact
