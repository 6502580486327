import { DataTableI } from './data-table-i';
import { TenantUserI } from './tenant-user-i';
import { UiFormSectionI } from './ui-form-section-i';
import { UiGridFieldI } from './ui-grid-field-i';
import { TenantI } from './tenant-i';

/* GenTsInterface domain: UiTab */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: UiTab
 */
export interface UiTabI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * fk DataTableI
   * label: Parent Table
   */
  dataTable?: DataTableI;

  /**
   * fk DataTableI key
   * label: Parent Table
   */
  dataTableId?: number|string;

  /**
   * label: Child Tabs
   */
  childTabNames?: string[];

  /**
   * label: Form Sections
   */
  formSectionList?: UiFormSectionI[];

  /**
   * label: Grid Fields
   */
  gridFieldList?: UiGridFieldI[];

  /**
   * label: Help
   */
  help?: string;

  iconClass?: string;

  iconHref?: string;

  /**
   * label: Child Tab
   */
  isChildTab?: boolean;

  /**
   * label: Main Menu
   */
  isMainMenu?: boolean;

  /**
   * label: Label Plural
   */
  labelPlural?: string;

  /**
   * link column names to parent record
   * label: Related
   */
  relatedLinkColumnList?: string[];

  /**
   * label: Menu Sequence
   */
  seqNo?: number;

  /**
   * label: html id
   */
  uiId?: string;

} // UiTab

/** UiTab Definition */
export const UiTabD = {
  recordTypeName: 'UiTab',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  dataTable: {
    n: 'dataTable',
    t: 'DataTableI'
  },
  dataTableId: {
    n: 'dataTableId',
    t: 'number|string'
  },
  childTabNames: {
    array: true,
    n: 'childTabNames',
    t: 'string'
  },
  formSectionList: {
    array: true,
    n: 'formSectionList',
    t: 'UiFormSectionI'
  },
  gridFieldList: {
    array: true,
    n: 'gridFieldList',
    t: 'UiGridFieldI'
  },
  help: {
    n: 'help',
    t: 'string'
  },
  iconClass: {
    n: 'iconClass',
    t: 'string'
  },
  iconHref: {
    n: 'iconHref',
    t: 'string'
  },
  isChildTab: {
    n: 'isChildTab',
    t: 'boolean'
  },
  isMainMenu: {
    n: 'isMainMenu',
    t: 'boolean'
  },
  labelPlural: {
    n: 'labelPlural',
    t: 'string'
  },
  relatedLinkColumnList: {
    array: true,
    n: 'relatedLinkColumnList',
    t: 'string'
  },
  seqNo: {
    n: 'seqNo',
    t: 'number'
  },
  uiId: {
    n: 'uiId',
    t: 'string'
  },
}; // UiTab
