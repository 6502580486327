import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Note } from './note';
import { NotificationService } from './notification.service';

/**
 * Notification
 * - display using {@link NotificationService} add methods
 *
 * z-index:
 *  slds-notification-container: default 8500 - 9999
 *  slds-modal: 9001
 */
@Component({
  selector: 'acc-notification',
  templateUrl: './notification.component.html',
  styleUrls: [ './notification.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent {

  // notes
  notes: Note[] = [];

  private isDisabled: boolean = false;

  constructor(private service: NotificationService) {
    service.notes$.subscribe((note) => {
      this.addNote(note);
    });
  }

  /**
   * @param note notification to add
   */
  addNote(note: Note): void {
    if (this.isDisabled) {
      this.notes = [];
    } else {
      if (note.subject) {
        this.notes.push(note);
        if (note.autoConfirmSecs) {
          setTimeout(() => {
            this.onClickNote(note, undefined);
          }, note.autoConfirmSecs * 1000);
        }
      }
    }
  } // addNote

  onClick(): void {
    console.log('Notification.disabled---');
    this.isDisabled = true;
    this.notes = [];
  }

  /**
   * Close
   * @param note the note
   * @param event manual click
   */
  onClickNote(note: Note, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
    //
    const index = this.notes.indexOf(note);
    if (index >= 0) {
      this.notes.splice(index, 1);
    }
  } // handleClose

} // NotificationComponent
