import { Action, createReducer, on } from '@ngrx/store';
import { TzInfo } from '../model/tz-info';
import { TzInfoResponse } from '../model/tz-info-response';
import * as TzActions from './timezone.actions';

export interface TzState {
  status?: string;
  response?: TzInfoResponse;
  tz?: TzInfo;
}

export const tzInitialState: TzState = {
  status: undefined,
  response: undefined,
  tz: undefined
};


const tzReducer0 = createReducer(
  tzInitialState,

  on(TzActions.timezoneRequestAction,
    (state, action) => {
      return {
        ...state,
        status: 'requested'
      };
    }),

  on(TzActions.timezoneReponseAction,
    (state, action) => {
      return {
        ...state,
        status: 'ok',
        response: action.response
      };
    }),

  on(TzActions.timezoneErrorAction,
    (state, action) => {
      return {
        ...state,
        status: 'error'
      };
    }),

  on(TzActions.timezoneSetAction,
    (state, action) => {
      return {
        ...state,
        tz: action.tz
      };
    }),
); // tzReducer

export function tzReducer(state: TzState, action: Action): TzState {
  return tzReducer0(state, action);
}
