import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Store} from '@ngrx/store';

import {UiTab} from '../model/ui-tab';
import {DataRecord} from '../model/data-record';
import {Logger} from '../log/logger';
import {FormManager} from './form-manager';
import {AccortoService} from '../accorto.service';
import {FkUtil} from '../fk/fk-util';
import {UiFormSection} from '../model/ui-form-section';

/**
 * Form
 */
@Component({
  selector: 'acc-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormComponent implements OnChanges, OnDestroy {

  // The UI Tab
  @Input() ui: UiTab = new UiTab();
  /** The Data Record */
  @Input() record: DataRecord = new DataRecord();

  // slds-form_horizontal - slds-form_stacked
  @Input() layout: string = 'slds-form_stacked';
  // form classes
  formClasses: string[] = [ 'slds-form',
    this.layout,
    'slds-gutters', // 'slds-m-around--xx-small',
    'slds-is-relative' ]; // busy

  /** Pre-populated values */
  @Input() preFillValues: { [ key: string ]: string } = {};

  /** show reset/save */
  @Input() showFooter: boolean = true;
  @Input() saveLabel: string = 'Save';
  @Input() showClose: boolean = false;

  /** Initial Editor Tab Name */
  @Input() initialTab?: string;

  /** Record Saved (undefined for close) */
  @Output() saveRecord = new EventEmitter<DataRecord>();
  /** Status Updated (reset, ...) */
  @Output() statusUpdate = new EventEmitter<string>();
  /** Record Value Changed */
  @Output() recordChanged = new EventEmitter<FormManager>();

  /** active tab */
  activeTab: number | string | undefined;

  busy: boolean = false;

  /** Form Manager */
  fm: FormManager = new FormManager(this.fb, this.ui, this.record, undefined,
    this.conf, undefined,
    this.saveRecord, this.statusUpdate, this.recordChanged);

  private log: Logger = new Logger('Form');

  // Form
  constructor(private fb: FormBuilder,
              private store: Store<object>,
              private conf: AccortoService) {
  }

  get debug(): boolean {
    return this.conf.isDebug;
  }

  /**
   * @return read write
   */
  get isReadWrite(): boolean {
    if (!this.ui.formSectionList || this.ui.formSectionList.length === 0) {
      return false; // no tab
    }
    // this.log.debug('isReadWrite (readOnly ui=' + this.ui.isRecordsReadOnly
    //  + ' table=' + (this.ui.dataTable ? this.ui.dataTable.isRecordsReadOnly : '')
    //  + ' record=' + this.record.isReadOnly + ')');
    return this.record && !this.record.isReadOnly
      ; //  && this.ui && !this.ui.isRecordsReadOnly;
  }

  /**
   * No Need to Save
   * @return form/record not changed
   */
  get recordUnchanged(): boolean {
    return this.fm.recordUnchanged;
  }

  /**
   * @return form is valid
   */
  get recordValid(): boolean {
    return this.fm.recordValid;
  }

  /**
   * Form Reset
   */
  doReset(): void {
    this.log.info('doReset')();
    this.fm.onReset();
  }

  /**
   * Save Button
   */
  doSave(): void {
    this.log.info('doSave')();
    this.fm.onSave();
  }

  get isShowTabs(): boolean {
    return this.ui.formSectionList.length > 1;
  }

  ngOnDestroy(): void {
    if (this.fm) {
      this.fm.destroy();
    }
  }

  get sectionList(): UiFormSection[] {
    if (!this.isShowTabs) {
      return this.ui.formSectionList;
    }
    return [];
  }

  get tabList(): UiFormSection[] {
    if (this.isShowTabs) {
      return this.ui.formSectionList;
    }
    return [];
  }

  /**
   * Form Changes
   * @param changes simple changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.log.debug('ngOnChanges', this.ui, this.record)();
    if (this.ui) {
      this.log.setSubName(this.ui.name);
      FkUtil.preloadFormFKs(this.store, this.ui, this.record);

      // initial tab
      if (this.ui.formSectionList.length > 0) {
        this.activeTab = this.ui.formSectionList[ 0 ].id;
      }
      if (this.initialTab) {
        for (const s of this.ui.formSectionList) {
          if (s.name === this.initialTab) {
            this.activeTab = s.id;
            break;
          }
        }
      }
      // form manager
      if (this.ui.name !== this.fm.ui.name) {
        this.fm = new FormManager(this.fb, this.ui, this.record, undefined,
          this.conf, undefined,
          this.saveRecord, this.statusUpdate, this.recordChanged);
      } else {
        this.fm.setRecord(this.record);
      }
      this.fm.setValues(this.preFillValues);
    }
  } // ngOnChanges

  onClose(): void {
    this.saveRecord.emit(undefined);
  }

  onTabClick(name: number | string | undefined | null): void {
    this.log.debug('onTabClick', name)();
    if (name) {
      this.activeTab = name;
    }
  }

} // FormComponent
