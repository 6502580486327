import { CRequest } from './c-request';

/* GenTsClass client: com.accorto.domain.CRequestUi */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CRequest
 * name: CRequestUi
 */
export class CRequestUi extends CRequest {

  /**
   * label: UiTab Id
   */
  public uiTabId?: number; // optional=null

  /**
   * label: UiTab Name
   */
  public uiTabName?: string; // optional=null

} // CRequestUi
