import {ValidationErrors} from '@angular/forms';
import {DataColumn} from '../model/data-column';

/**
 * General Utilities
 */
export class AUtil {

  /**
   * xs below 480
   * small  30em / 480px and higher
   * medium  48em / 768px and higher
   * large  64em / 1024px and higher
   * @return xs, small, medium, large
   * @see https://www.lightningdesignsystem.com/utilities/sizing
   */
  public static getWindowSize(): string {
    const iw = window.innerWidth;
    if (iw < 480) {
      return 'xs';
    }
    if (iw < 768) {
      return 'small';
    }
    if (iw < 1024) {
      return 'medium';
    }
    return 'large';
  }

  /**
   * @return XS or Small (< 768px)
   */
  public static isWindowSmall(): boolean {
    const iw = window.innerWidth;
    return iw < 768;
  }

  /**
   * @return iPhone or Android
   */
  public static isMobile(): boolean {
    return navigator.appVersion.includes('iPhone')
      || navigator.appVersion.includes('Android');
  }

  /**
   * Get Validation Errors with optional label
   * @param errors input validation errors
   * @param pn property name
   * @param column data column (for label)
   * @return error info or empty string
   */
  public static getValidationErrors(
    errors: ValidationErrors | null,
    pn?: string,
    column?: DataColumn): string {
    if (errors) {
      // console.debug('getValidationErrors', errors, column ? column.name : '-', name);
      let msg = column ? column.label + ': ' : (pn ? pn + ': ' : '');
      Object.keys(errors).forEach(((key) => {
        const value = errors[key];
        if (value === true) {
          msg += key + ' '; // required = true
        } else if (typeof (value) === 'object') {
          // msg += JSON.stringify(value) + ' '; {"requiredLength":2,"actualLength":1}
          Object.keys(value).forEach((key2) => {
            const value2 = value[ key2 ];
            msg += key2 + '=' + value2 + ' ';
          });
        } else {
          msg += key + '=' + String(value) + ' '; // invalidFk=123
        }
      }));
      return msg;
    }
    return '';
  } // getValidationErrors

  /**
   * Get absolute url for ui name
   * @param tabName ui name
   * @return url
   */
  public static getUrlForTabName(tabName?: string): string {
    if (tabName) {
      /* for (const route of SldsUtils.td4routes) {
        if (route.data && route.data[ 'ui' ]) {
          const tab = route.data[ 'ui' ];
          if (tabName === tab) {
            return '/' + route.path;
          }
        }
      } */
      return '/d/' + tabName;
    }
    return '#';
  } // getUrl

  /**
   * Iso Date string
   * @param iso iso date
   */
  public static dateString(iso: Date): string {
    if (iso) {
      const dd = iso.toISOString();
      return dd.substring(0, 10);
    }
    return '-';
  }

  /**
   * Iso Date string
   * @param ms milliseconds
   */
  public static dateStringMs(ms: number | undefined | null): string {
    if (ms) {
      const dd = new Date(ms).toISOString();
      return dd.substring(0, 10);
    }
    return '-';
  } // dateStringMs

  /**
   * Is it the same value
   * @param one first
   * @param two second
   * @return true if null/undefined/empty
   */
  public static isSame(one: any, two: any): boolean {
    const c1 = one == null ? '' : one;
    const c2 = two == null ? '' : two;
    return Object.is(c1, c2);
  }

} // AUtil
