/**
 * (Local) Preference/Setting Option
 */
export class PreferenceOption {
  constructor(public name: string, public label: string, public selected: boolean) {
  }
}

/**
 * (Local) Preference/Setting
 */
export class Preference {

  static prefDebug = new Preference('debug', 'Debug Mode',
    'false', { true: 'Yes', false: 'No' });
  static prefShowWeekends = new Preference('showWeekends', 'Show Weekends (Timesheet)',
    'false', { true: 'Yes', false: 'No' });
  static prefFkHover = new Preference('fkHover', 'Fk Hover',
    'false', { true: 'Yes', false: 'No' });
  static prefFkType = new Preference('fk', 'FK Type',
    'list', { list: 'List', search: 'Search', select: 'Select' });

  /** All Preferences */
  static allPreferences: Preference[] = [ Preference.prefDebug, Preference.prefFkType, Preference.prefFkHover ];

  /**
   * actual instance value
   * @see #isValue
   */
  value: string | null = null;

  /**
   * Preference / Setting
   * @param name internal name
   * @param label display
   * @param valueDefault default
   * @param valueOptions value -> label
   */
  constructor(public name: string, public label: string,
              private valueDefault: string,
              public valueOptions: { [ key: string ]: string }) {
    this.load();
  }

  /**
   * Is the Value true (for binary options)
   */
  get isValue(): boolean {
    return 'true' === this.value;
  }

  get id(): string {
    return 'pref_' + this.name;
  }

  /**
   * Current Options
   */
  get options(): PreferenceOption[] {
    const opts: PreferenceOption[] = [];
    Object.keys(this.valueOptions).forEach(((key) => {
      const opLabel = String(this.valueOptions[ key ]);
      // console.debug('options ' + this.name, opLabel, key, this.value);
      opts.push(new PreferenceOption(key, opLabel, key === this.value));
    }));
    return opts;
  }


  /**
   * Load Value
   */
  public load(): string {
    if (localStorage) {
      this.value = localStorage.getItem(this.id);
      //  console.log('Preference.load ' + this.name, this.value);
    }
    if (!this.value) {
      this.value = this.getValueDefault();
    }
    return this.value;
  }

  /**
   * Toggle boolean and save
   */
  public toggle(): void {
    const newValue = this.isValue ? 'false' : 'true';
    this.save(newValue);
  }

  /**
   * Save Value
   */
  public save(newValue: string): void {
    this.value = newValue;
    if (localStorage) {
      localStorage.setItem(this.id, newValue);
      console.log('Preference.save ' + this.name, newValue);
    }
  }

  public saveBoolean(newValue: boolean): void {
    this.save(newValue ? 'true' : 'false');
  }

  /**
   * Special Handling of defaults
   */
  private getValueDefault(): string {
    if (this.name === 'debug') {
      return window.location.hostname === 'localhost' ? 'true' : 'false';
    }
    return this.valueDefault;
  }

} // Preference
