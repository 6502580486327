import { TenantUserI } from './tenant-user-i';
import { TenantI } from './tenant-i';

/* GenTsInterface domain: LogRecord */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: LogRecord
 */
export interface LogRecordI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * label: Target
   */
  requestUrl?: string;

  /**
   * label: Source
   */
  remoteAddr?: string;

  userAgent?: string;

  cid?: string;

  sid?: string;

  userName?: string;

  userId?: string;

  orgName?: string;

  orgId?: string;

  product?: string;

  productVersion?: string;

  userInterface?: string;

  loggerName?: string;

  methodName?: string;

  logLevel?: string;

  message?: string;

  params?: string[];

  exception?: string;

  statistics?: string;

  statisticsValue?: number;

  durationMs?: number;

} // LogRecord

/** LogRecord Definition */
export const LogRecordD = {
  recordTypeName: 'LogRecord',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  requestUrl: {
    n: 'requestUrl',
    t: 'string'
  },
  remoteAddr: {
    n: 'remoteAddr',
    t: 'string'
  },
  userAgent: {
    n: 'userAgent',
    t: 'string'
  },
  cid: {
    n: 'cid',
    t: 'string'
  },
  sid: {
    n: 'sid',
    t: 'string'
  },
  userName: {
    n: 'userName',
    t: 'string'
  },
  userId: {
    n: 'userId',
    t: 'string'
  },
  orgName: {
    n: 'orgName',
    t: 'string'
  },
  orgId: {
    n: 'orgId',
    t: 'string'
  },
  product: {
    n: 'product',
    t: 'string'
  },
  productVersion: {
    n: 'productVersion',
    t: 'string'
  },
  userInterface: {
    n: 'userInterface',
    t: 'string'
  },
  loggerName: {
    n: 'loggerName',
    t: 'string'
  },
  methodName: {
    n: 'methodName',
    t: 'string'
  },
  logLevel: {
    n: 'logLevel',
    t: 'string'
  },
  message: {
    n: 'message',
    t: 'string'
  },
  params: {
    array: true,
    n: 'params',
    t: 'string'
  },
  exception: {
    n: 'exception',
    t: 'string'
  },
  statistics: {
    n: 'statistics',
    t: 'string'
  },
  statisticsValue: {
    n: 'statisticsValue',
    t: 'number'
  },
  durationMs: {
    n: 'durationMs',
    t: 'number'
  },
}; // LogRecord
