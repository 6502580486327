import { DataTable } from './data-table';
import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { DataColumnI, DataColumnD } from './data-column-i';
import { DataPickOption } from './data-pick-option';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* GenTsClass domain: DataColumn */
/* tslint:disable max-line-length no-inferrable-types */

export enum DataType {
  BLOB = 'BLOB',
  BOOLEAN = 'BOOLEAN',
  CLOB = 'CLOB',
  DATE = 'DATE',
  DECIMAL = 'DECIMAL',
  DOUBLE = 'DOUBLE',
  EMAIL = 'EMAIL',
  FK = 'FK',
  FKID = 'FKID',
  HOURS = 'HOURS',
  ID = 'ID',
  IMAGE = 'IMAGE',
  INTEGER = 'INTEGER',
  LIST = 'LIST',
  LONG = 'LONG',
  MAP = 'MAP',
  MINUTES = 'MINUTES',
  NULL = 'NULL',
  OBJECT = 'OBJECT',
  PASSWORD = 'PASSWORD',
  PHONE = 'PHONE',
  PICK = 'PICK',
  PICKMULTI = 'PICKMULTI',
  SFID = 'SFID',
  STRING = 'STRING',
  TIME = 'TIME',
  TIMESTAMP = 'TIMESTAMP',
  URL = 'URL'
}

export enum DataDisplay {
  BOTH = 'BOTH',
  FORM = 'FORM',
  GRID = 'GRID',
  NONE = 'NONE'
}

/**
 * name: DataColumn
 */
export class DataColumn {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * parent fk DataTable
   * label: Parent
   */
  public dataTable?: DataTable; // optional=true

  public dataTableId?: number; // optional=true

  /**
   * label: Data Type
   */
  public dataType?: DataType; // optional=true

  /**
   * label: Nullable (Optional)
   */
  public isNullable?: boolean; // optional=true

  /**
   * Max Length
   * label: Length (max)
   */
  public length?: number; // optional=true

  /**
   * Minimum Length
   * label: Min Length
   */
  public lengthMin?: number; // optional=true

  /**
   * label: Precision
   */
  public precision?: number; // optional=true

  /**
   * label: Scale (decimal digits)
   */
  public scale?: number; // optional=true

  /**
   * label: Default Value
   */
  public defaultValue?: string; // optional=true

  /**
   * label: Primary Key
   */
  public isPk?: boolean; // optional=true

  /**
   * Column Read/Only For Client
   * help: column insertable/updateable, AutoIncrement|Version
   * label: Read/Only
   */
  public isReadOnly?: boolean; // optional=true

  /**
   * label: Unique
   */
  public isUnique?: boolean; // optional=true

  /**
   * Automatically created/calculated
   * label: Auto Calc
   */
  public isAutoIncrement?: boolean; // optional=true

  /**
   * label: FK
   */
  public isFk?: boolean; // optional=true

  /**
   * label: FK Table
   */
  public fkTable?: string; // optional=true

  /**
   * label: FK: Id Column
   */
  public fkIdColumn?: string; // optional=true

  /**
   * label: FK: Name Column
   */
  public fkNameColumn?: string; // optional=true

  /**
   * label: FK: Label Columns
   */
  public fkLabelColumn?: string; // optional=true

  /**
   * label: FK is Parent
   */
  public isFkParent?: boolean; // optional=true

  /**
   * column can reference multiple objects (e.g. User,Group)
   * label: FK: Multiple Ref
   */
  public fkMultiReference?: string; // optional=true

  /**
   * comma separated list of parent column names, first found is used
   * label: FK Restrict Column Names
   */
  public fkRestrictColumnNames?: string; // optional=true

  /**
   * matching values for parent column (property names)
   * label: FK: Restrict Column Values
   */
  public fkRestrictColumnValues?: string; // optional=true

  /**
   * label: Picklist
   */
  public isPick?: boolean; // optional=true

  /**
   * label: Picklist Source
   */
  public pickSource?: string; // optional=true

  /**
   * Field sequence (1..) to uniquely identify the record - 0=DoNotKnow|Ignored -1=CannotUse
   * label: Unique Sequence
   */
  public uniqueSequenceId?: number; // optional=true

  /**
   * label: External Id
   */
  public isExternalId?: boolean; // optional=true

  /**
   * label: Sequence No
   */
  public seqNo?: number; // optional=true

  /**
   * BOTH | FORM | GRID | NONE
   * label: Data Display
   */
  public dataDisplay?: DataDisplay; // optional=true

  /**
   * Required for Client Data Entry
   * label: Required (UI)
   */
  public isUiRequired?: boolean; // optional=true

  /**
   * label: Display Logic
   */
  public displayLogic?: string; // optional=true

  /**
   * Field Level
   * label: Read Only Logic
   */
  public readOnlyLogic?: string; // optional=true

  /**
   * label: Help
   */
  public help?: string; // optional=true

  /**
   * AutoComplete or AutoFill for input - default: off
   * help: https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill
   * label: Auto Complete
   */
  public autocomplete?: string; // optional=true

  /**
   * Overwrite: input - checkbox - select - fk - textarea
   * label: Control Type
   */
  public controlType?: string; // optional=true

  /**
   * e.g. text, checkbox, email
   * label: Input Element: Type
   */
  public inputType?: string; // optional=true

  /**
   * label: Input Element: RegEx
   */
  public inputRegex?: string; // optional=true

  /**
   * label: On Server Only
   */
  public isServerOnly?: boolean; // optional=true

  /**
   * label: Tab Section
   */
  public tabSection?: string; // optional=true

  /**
   * label: Display After ColumnName
   */
  public displayAfter?: string; // optional=true

  /**
   * Javascript code to calculate value
   * label: Column Code
   */
  public columnLogic?: string; // optional=true

  /**
   * label: Properties dependent on this column
   */
  public dependentPropertyList: string[] = [];

  /**
   * e.g. NewLine, FullLine
   * label: Layout Hints
   */
  public layout?: string; // optional=true

  /**
   * label: Picklist Options
   */
  public pickOptionList: DataPickOption[] = [];

  /**
   * Transient PickOption Map
   * label: Map: name(lowerCase) -> Picklist Option
   */
  public pickOptionListMap: {[key: string]: DataPickOption} = {};


  /**
   * @return DataColumn record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'DataColumn';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * propertyName from ColumnName
   */
  public get propertyName(): string {
    if (this.name && this.name.length > 0) {
      return this.name.charAt(0).toLowerCase() + this.name.substring(1);
    }
    return this.name ? this.name : '?';
  } // get propertyName


  /**
   * @param id id of the DataPickOption
   * @return DataPickOption entity or undefined
   */
  public getPickOptionById(id: number): DataPickOption | undefined {
    if (this.pickOptionList) {
      for (const entity of this.pickOptionList) {
        if (entity.id === id) {
          return entity;
        }
      }
    }
    return undefined;
  } // getPickOptionById


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): DataColumn {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, DataColumnD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, DataColumnD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: DataColumnI): DataColumn {
    Object.keys(DataColumnD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'pickOptionList') { // child
          this.pickOptionList = [];
          this.pickOptionListMap = {};
          for (const vv of value) {
            const cc = new DataPickOption();
            cc.setFromI(vv);
            cc.dataColumn = this; // parent
            this.pickOptionList.push(cc); // list
            this.pickOptionListMap[ ('' + cc.name).toLowerCase() ] = cc; // map
          }
        }
      }
    });
    return this;
  } // setFromI

} // DataColumn
