
/* GenTsClass client: com.accorto.domain.CResponseImportColumn */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CResponseImportColumn
 */
export class CResponseImportColumn {

  /**
   * label: Target Field Alias (Sf)
   */
  public alias?: string; // optional=null

  /**
   * label: Heading
   */
  public heading?: string; // optional=null

  /**
   * label: icon
   */
  public icon?: string; // optional=null

  /**
   * label: Target Field Label
   */
  public label?: string; // optional=null

  /**
   * label: Target Field Name
   */
  public name?: string; // optional=null

} // CResponseImportColumn
