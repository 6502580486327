import { CResponseImportColumn } from './c-response-import-column';
import { CRequest } from './c-request';

/* GenTsClass client: com.accorto.domain.CRequestImport */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CRequest
 * name: CRequestImport
 */
export class CRequestImport extends CRequest {

  /**
   * label: Import Data
   */
  public importData?: string; // optional=null

  /**
   * label: Import First Line is Heading
   */
  public importIsFirstLineHeading?: boolean; // optional=null

  /**
   * e.g. Project,..
   * label: Import Type
   */
  public importObject?: string; // optional=null

  /**
   * label: Headings with Import Column Mappings
   */
  public headingColumns: CResponseImportColumn[] = [];

  /**
   * label: Mapping is Complete
   */
  public mappingIsComplete?: boolean; // optional=null

  /**
   * label: Mapping Name
   */
  public mappingName?: string; // optional=null

  /**
   * label: Mapping infos
   */
  public mappingInfos?: string; // optional=null

  /**
   * label: Test Only
   */
  public testMode?: boolean; // optional=null

} // CRequestImport
