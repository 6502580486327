import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { AccortoService, AccortoServiceConfig } from './accorto.service';
import { LogService } from './log/log.service';
import { NotificationComponent } from './notification/notification.component';
import { NotificationService } from './notification/notification.service';
import { TimezoneComponent } from './timezone/timezone.component';
import { tzReducer } from './timezone/timezone.reducer';
import { FormElementComponent } from './form-element/form-element.component';
import { RecordValueComponent } from './record-value/record-value.component';
import { FormElementFkComponent } from './form-element-fk/form-element-fk.component';
import { FormSectionComponent } from './form-section/form-section.component';
import { FormComponent } from './form/form.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { uiReducer } from './ui/ui.reducer';
import { UiEffects } from './ui/ui.effects';
import { dataReducer } from './data/data.reducer';
import { DataEffects } from './data/data.effects';
import { TimezoneEffects } from './timezone/timezone.effects';
import { GoogleAnalyticsService } from './utils/google-analytics.service';
import { ServiceWorkerUpdateService } from './utils/service-worker-update.service';
import { PopoverComponent } from './popover/popover.component';
import { IconComponent } from './icon/icon.component';
import { LoginComponent } from './login/login.component';
import { LoginEffects } from './login/login.effects';
import { loginReducer } from './login/login.reducer';
import { GlobalHeaderComponent } from './global-header/global-header.component';
import { StatusLineComponent } from './status-line/status-line.component';
import { GlobalNavigationComponent } from './global-navigation/global-navigation.component';
import { AppLauncherComponent } from './app-launcher/app-launcher.component';
import { ObjectHomeComponent } from './object-home/object-home.component';
import { RecordHomeComponent } from './record-home/record-home.component';
import { UiResolver } from './ui/ui.resolver';
import { DataTableComponent } from './data-table/data-table.component';
import { ObjectFilterComponent } from './object-filter/object-filter.component';
import { FkComponent } from './fk/fk.component';
import { StatusComponent } from './status/status.component';
import { DateValidatorDirective } from './validators/date-validator.directive';
import { HoursValidatorDirective } from './validators/hours-validator.directive';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FkEffects } from './fk/fk.effects';
import { fkReducer } from './fk/fk.reducer';
import { FkSearchComponent } from './fk-search/fk-search.component';
import { FormDateComponent } from './form-date/form-date.component';
import { FormHoursComponent } from './form-hours/form-hours.component';
import { FormFileComponent } from './form-file/form-file.component';
import { SliderComponent } from './slider/slider.component';
import { DateMsPipe } from './date-picker/date-ms.pipe';
import { DataService } from './data/data.service';
import { FormClockComponent } from './form-clock/form-clock.component';
import { HelpComponent } from './help/help.component';
import { HelpButtonComponent } from './help-button/help-button.component';
import { FormSearchComponent } from './form-search/form-search.component';
import { ModalComponent } from './modal/modal.component';
import { ModalFloatComponent } from './modal-float/modal-float.component';
import { DataRecordSelectorComponent } from './data-record-selector/data-record-selector.component';
import { SanitizeHtmlPipe } from './utils/sanitize-html.pipe';
import { DateElementComponent } from './date-element/date-element.component';


/**
 * Accorto Routes
 */
export const accortoRoutes: Routes = [
  {
    path: 'ui/:uiTab',
    component: ObjectHomeComponent,
    resolve: {
      uiTab: UiResolver
    }
  }
];

/**
 * Core/Base Accorto Library
 */
@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forChild(accortoRoutes),
    ReactiveFormsModule,

    StoreModule.forFeature('timezone', tzReducer),
    StoreModule.forFeature('login', loginReducer),
    StoreModule.forFeature('ui', uiReducer),
    StoreModule.forFeature('data', dataReducer),
    StoreModule.forFeature('fk', fkReducer),

    EffectsModule.forRoot([]),
    EffectsModule.forFeature([ TimezoneEffects, UiEffects, DataEffects, LoginEffects, FkEffects ]),
  ],
  declarations: [
    NotificationComponent,
    TimezoneComponent,

    FormElementComponent,
    FormElementFkComponent,
    FormFileComponent,
    RecordValueComponent,
    FormSectionComponent,
    FormComponent,
    SpinnerComponent,
    PopoverComponent,
    IconComponent,
    LoginComponent,
    GlobalHeaderComponent,
    StatusLineComponent,
    GlobalNavigationComponent,
    AppLauncherComponent,
    ObjectHomeComponent,
    RecordHomeComponent,
    DataTableComponent,
    ObjectFilterComponent,
    FkComponent,
    StatusComponent,
    DateValidatorDirective,
    HoursValidatorDirective,
    DatePickerComponent,
    FkSearchComponent,
    FormDateComponent,
    FormHoursComponent,
    SliderComponent,
    DateMsPipe,
    FormClockComponent,
    HelpComponent,
    HelpButtonComponent,
    FormSearchComponent,
    ModalComponent,
    ModalFloatComponent,
    DataRecordSelectorComponent,
    SanitizeHtmlPipe,
    DateElementComponent
  ],
  exports: [
    NotificationComponent,
    TimezoneComponent,
    FormComponent,
    SpinnerComponent,
    PopoverComponent,
    IconComponent,
    LoginComponent,
    StatusLineComponent,
    GlobalHeaderComponent,
    GlobalNavigationComponent,
    ObjectHomeComponent,
    RecordHomeComponent,
    FkComponent,
    StatusComponent,
    DatePickerComponent,
    DateValidatorDirective,
    HoursValidatorDirective,
    DataTableComponent,
    FormFileComponent,
    FormDateComponent,
    FormElementComponent,
    DateMsPipe,
    HelpButtonComponent,
    HelpComponent,
    FormSearchComponent,
    ModalComponent,
    ModalFloatComponent,
    DataRecordSelectorComponent,
    SanitizeHtmlPipe,
    DateElementComponent,
    FormSectionComponent
  ],
  providers: [
    AccortoService,
    GoogleAnalyticsService,
    LogService,
    NotificationService,
    ServiceWorkerUpdateService,
    DataService
  ]
})
export class AccortoModule {

  constructor(@Optional() @SkipSelf() parentModule: AccortoModule) {
    if (parentModule) {
      throw new Error(
        'AccortoModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: AccortoServiceConfig): ModuleWithProviders<AccortoModule> {
    return {
      ngModule: AccortoModule,
      providers: [
        { provide: AccortoServiceConfig, useValue: config },
        { provide: ErrorHandler, useClass: LogService },
        NotificationService,
        GoogleAnalyticsService
      ]
    };
  }

} // AccortoModule
