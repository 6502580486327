import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { DataRecord } from '../model/data-record';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';

/**
 * Data Resolver
 * - loads record into snapshot data
 *
 * route:
 *  path: 'ui/:uiTab/:id',
 *  component: RecordHomeComponent,
 *  canActivate: [ LoginGuard ],
 *  resolve: {
 *    uiTab: UiResolver,
 *    record: DataResolver
 *  }
 */
@Injectable({
  providedIn: 'root'
})
export class DataResolver implements Resolve<DataRecord> {


  constructor(private service: DataService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<DataRecord> | Promise<DataRecord> | DataRecord {
    const uiName = route.params.uiTab;
    const recordId = route.params.id;

    console.log('DataResolver ' + uiName + '_' + recordId);

    if ('' + recordId === '-1') {
      return new DataRecord(); // new
    }

    return this.service.queryId(uiName, recordId);
  }

} // DataResolver
