
/* GenTsClass client: com.accorto.domain.MenuItem */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: MenuItem
 */
export class MenuItem {

  /**
   * meta description, title for menu item
   * label: description
   */
  public description?: string; // optional=null

  /**
   * label: icon class
   */
  public iconClass?: string; // optional=null

  /**
   * /assets/icons/utility-sprite/svg/symbols.svg#home or /assets/accorto/timeCapture-100.svg
   * label: icon href
   */
  public iconHref?: string; // optional=null

  /**
   * label: On Main Menu
   */
  public isMainMenu?: boolean; // optional=null

  /**
   * meta keywords
   * label: keywords
   */
  public keywords?: string; // optional=null

  /**
   * meta title
   * label: Label (Plural)
   */
  public label?: string; // optional=null

  /**
   * label: Ui Name
   */
  public name?: string; // optional=null

  /**
   * overwrite /ui/name
   * label: Navigation Path
   */
  public path?: string; // optional=null

  /**
   * server use only
   * label: Sort Sequence
   */
  public seqNo?: number; // optional=null


  /**
   * navigation id
   */
  public id(): string {
    const raw: string = ('nav-' + this.name).toLocaleLowerCase();
    return raw.replace(' ', '-');
  } // id


  /**
   * router commands
   */
  public routerLink(): any[] {
    if (this.path) {
      return [ this.path ];
    }
    return [ '/ui', this.name ];
  } // routerLink


  /**
   * set main values
   */
  public setName(name: string, label: string, description: string, iconRef: string): MenuItem {
    this.name = name;
    this.label = label;
    this.description = description;
    this.iconHref = iconRef;
    return this;
  } // setName


  /**
   * set main values
   */
  public setNav(path: string): MenuItem {
    this.isMainMenu = true;
    this.path = path;
    return this;
  } // setNav

} // MenuItem
