import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';

import {DataColumn} from '../model/data-column';
import {UiGridField} from '../model/ui-grid-field';
import {UiFormField} from '../model/ui-form-field';
import {FkInfo} from '../model/fk-info';
import {FkService} from './fk.service';
import {Logger} from '../log/logger';
import {selectFkCache} from './fk.selectors';
import {FkCache} from './fk-cache';
import {FkType} from './fk-type';
import {Preference} from '../utils/preference';
import {FormManager} from '../form/form-manager';
import {FormFocus} from '../form/form-focus';
import {fkIdRequestAction, fkOptionsRequestAction} from './fk.actions';
import {FkRequests} from './fk-requests';

/**
 * FK
 * - when DataTable ui is loaded
 * -- it selectFkOptions(fkTable) and if no result new FkOptionsRequest({ fkTable, fkIds })
 *
 * Values
 * - initiate load - this.store.dispatch(new FkIdRequest({ fkTable, fkId, fkIds }));
 */
@Component({
  selector: 'acc-fk',
  templateUrl: './fk.component.html',
  styleUrls: ['./fk.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FkComponent
  implements OnChanges, OnDestroy, AfterViewInit, FormFocus {

  /** Grid Field */
  @Input() gf?: UiGridField;
  /** Form Fld */
  @Input() ff?: UiFormField;
  /** Form Manager */
  @Input() fm?: FormManager;

  /** Base Id */
  @Input() theId: string = '';
  /**
   * readOnly, link, select, list, search
   */
  @Input() fkType: FkType = FkType.LIST;
  /** ReadOnly Overwrite */
  @Input() readonly: boolean = true;

  /** The Value */
  value: number | string | undefined;

  /** Property Name */
  propertyName: string = '';
  /** Field Label (for dialog) */
  label: string = '';
  /** Data Column */
  dataColumn: DataColumn = new DataColumn();

  /** Valid Options */
  options: FkInfo[] = [];
  /** All Options */
  fkCache: FkCache = new FkCache('-', undefined);
  /** Current Value - FK Info */
  fkInfo: FkInfo = new FkInfo();
  /** optional Value */
  optionalFkInfo = new FkInfo().empty();

  /** Hover vs Click */
  useHover: boolean = true;

  /** disabled (no options) */
  isDisabled: boolean = false;

  @ViewChild('fki', {static: false})
  inputElement?: ElementRef;
  @ViewChild('fks', {static: false})
  selectElement?: ElementRef;
  @ViewChild('fkse', {static: false})
  searchElement?: ElementRef;
  @ViewChild('fkar', {static: false})
  autoReductionElement?: ElementRef;
  @ViewChildren('fkarli')
  autoReductionList?: QueryList<ElementRef<HTMLLIElement>>;

  /** Element Control */
  control: AbstractControl = new FormControl();

  /** Error Message */
  errorMessage?: string;

  /** Show Search */
  showSearch: boolean = false;
  /** Search Term */
  searchTerm?: string;

  /** Show AutoReduction */
  showAutoReduction: boolean = false;

  private subOptions?: Subscription;
  private subValues?: Subscription;

  private log: Logger = new Logger('Fk');
  private logAll = false;

  /**
   * FK
   */
  constructor(private service: FkService,
              private store: Store<object>,
              private sanitizer: DomSanitizer) {
    this.optionalFkInfo.validForSelection = true;
    // temp
    this.fkCache.isComplete = false;
  }

  get isError(): boolean {
    return !!this.errorMessage;
  }

  // Read only
  get isReadOnly(): boolean {
    if (this.readonly !== undefined && this.readonly) {
      return true; // override
    }
    if (this.fm && this.fm.record.isReadOnly) {
      return true; // record
    }
    if (this.dataColumn) {
      return !!this.dataColumn.isReadOnly; // column
    }
    return false;
  }

  /**
   * Required
   */
  get isUiRequired(): boolean {
    if (this.ff) {
      return this.ff.isRequired();
    }
    if (this.gf && this.gf.dataColumn) {
      return !!this.gf.dataColumn.isUiRequired;
    }
    return false;
  }

  /**
   * @return parent map propertyName->id
   */
  get parentMap(): { [ key: string ]: string } {
    const pm: { [ key: string ]: string } = {};
    if (this.dataColumn?.fkRestrictColumnValues && this.fm) {
      const parts = this.dataColumn.fkRestrictColumnValues.split(',');
      for (const expr of parts) {
        const pn = expr.replace('row.', '');
        const ctrl = this.fm.formGroup.controls[pn];
        if (ctrl) { // if parent control is displayed
          // this.log.debug('parentMap', pn + '=' + ctrl.value)();
          pm[pn] = ctrl.value;
        }
      }
    }
    return pm;
  } // parentMap

  /** ** **
   * Display
   * @param fkInfo new value
   */
  public displayFkInfo(fkInfo?: FkInfo): void {
    const fkId = fkInfo ? fkInfo.fkId : '';
    const label = fkInfo ? fkInfo.label : '';
    this.fkInfo = fkInfo ? fkInfo : this.optionalFkInfo; // sets span + a
    // this.log.debug('displayFkInfo', fkInfo)();
    // input
    if (this.inputElement) {
      // this.log.debug('displayFkInfo input ' + fkId)();
      this.inputElement.nativeElement.value = fkId;
    }
    // set select
    if (this.selectElement) {
      // this.log.debug('displayFkInfo select ' + fkId)();
      this.selectElement.nativeElement.value = fkId;
    }
    // set search
    if (this.searchElement) {
      // this.log.debug('displayFkInfo search ' + label)();
      this.searchElement.nativeElement.value = label;
    }
    // set auto reduction
    if (this.autoReductionElement) {
      // this.log.debug('displayFkInfo auto ' + label)();
      this.autoReductionElement.nativeElement.value = label;
    }
  } // displayFkInfo

  /**
   * @return fk id value
   */
  fkId(): string {
    if (this.fkInfo && this.fkInfo.fkId) {
      return this.fkInfo.fkId;
    }
    return '';
  }

  /**
   * @return fkTable
   */
  fkTable(): string {
    if (this.fkInfo && this.fkInfo.fkTable) {
      return this.fkInfo.fkTable;
    }
    if (this.dataColumn) {
      if (this.dataColumn.fkTable && this.dataColumn.fkTable !== '') {
        return this.dataColumn.fkTable;
      }
    }
    return '';
  } // fkTable

  /** is FkType - called from html */
  isType(type: string): boolean {
    if (this.fkType) {
      if (this.fkType === FkType.ALL) {
        return true;
      }
      return this.fkType === type;
    }
    return FkType.LIST === type;
  }

  public ngAfterViewInit(): void { // first time
    // this.log.debug('ngAfterViewInit display')();
    this.displayFkInfo(this.fkInfo); // setFkValue might be before
  }

  /** **
   * Values Changed - update ui
   * @param changes changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.fkType) { // from preferences
      const value = Preference.prefFkType.value;
      if (value) {
        this.fkType = FkType[value as keyof typeof FkType];
        this.useHover = Preference.prefFkHover.isValue;
      }
    }
    if (this.fm && this.fm.record.isReadOnly) {
      this.fkType = FkType.READONLY; // FkType.LINK;
    }
    // this.log.debug('ngOnChanges', Object.keys(changes), this.fkType)();

    // form/grid
    if (this.ff) {
      this.propertyName = this.ff.name ? this.ff.name : '?';
      this.label = this.ff.label ? this.ff.label : '?';
      if (this.ff.dataColumn) {
        this.dataColumn = this.ff.dataColumn;
      }
    } else if (this.gf) {
      this.propertyName = this.gf.name ? this.gf.name : '?';
      this.label = this.gf.label ? this.gf.label : '?';
      if (this.gf.dataColumn) {
        this.dataColumn = this.gf.dataColumn;
      }
    }

    // control
    if (!this.control && this.fm) {
      this.log.setSubName(this.theId);
      if (this.fm.registerFocus(this.propertyName, this)) {
        // nativeElement.focus()
      }
      this.control = this.fm.formGroup.controls[this.propertyName]; // get control from form group
      if (this.control) {
        // listen for control changes
        this.subValues = this.control.valueChanges.subscribe((value) => {
          if (value !== this.fkInfo.fkId) {
            if (this.logAll) {
              this.log.debug('ValueChanges value=' + value,
                (this.control.dirty ? 'dirty ' : '')
                + (this.control.valid ? '' : 'notValid ')
                + (this.errorMessage ? 'error=' + this.errorMessage : ''))();
            }
            this.setFkValue(value);
          }
        });
      } else {
        this.log.info('ngOnChanges NoControl ' + this.propertyName, this.fm.formGroup)();
      }
    } // control
    const fkId: string = this.control && this.control.value ? String(this.control.value) : '';
    // this.log.info('ngOnChanges', 'fkId=' + fkId, changes, this.control)();

    // fk info
    const fkTable = this.fkTable();
    this.optionalFkInfo.empty(fkTable);
    this.fkInfo = new FkInfo().empty(fkTable);
    // label
    if (!this.label && this.dataColumn?.label) { // overwrite
      this.label = this.dataColumn.label;
    }
    this.fkInfo.label = fkId;
    // fk value
    this.fkInfo.fkId = fkId;

    // fk table
    if (this.dataColumn?.isFk) { // fk info
      // get fk info
      if (this.fkInfo.fkTable) {
        // get fk options
        if (this.subOptions) {
          this.subOptions.unsubscribe();
        }
        // listen for Options
        this.subOptions = this.store
          .pipe(
            select(selectFkCache(this.fkInfo.fkTable))
          )
          .subscribe((fkCache: FkCache) => {
            if (fkCache) {
              this.fkCache = fkCache;
            }
            // current value
            const fkId1 = this.control && this.control.value ? String(this.control.value) : ''; // current value
            const fkId2 = this.fkId(); // from fkInfo
            if (this.logAll) {
              this.log.debug('ngOnChanges-fkCache',
                'value=' + fkId1 + '|' + fkId2, this.fkCache.toString())();
            }
            this.setFkValue(fkId1); // calls setOptions
          });

      } else {
        this.fkInfo.label = 'FK=' + this.fkId;
        this.fkInfo.description = 'NoFkTable';
        this.log.info('ngOnChanges-noFk', this.fkInfo, this.dataColumn)();
      }
    } else {
      this.fkInfo.label = fkId; // no fk
    }
  } // ngOnChanges

  public ngOnDestroy(): void {
    if (this.subOptions) {
      this.subOptions.unsubscribe();
    }
    if (this.subValues) {
      this.subValues.unsubscribe();
    }
  } // ngOnDestroy

  /**
   * AutoReduction - click on clear button
   * = clear selection and focus
   */
  onAutoReductionClear(event: MouseEvent): void {
    if (!(this.isReadOnly || this.isDisabled)) {
      this.log.debug('onAutoReductionClear')();
      this.onAutoReductionSelect(undefined);
      this.showAutoReduction = true;
      if (this.autoReductionElement) {
        this.autoReductionElement.nativeElement.focus();
      }
    }
  } // onAutoReductionClear

  /**
   * AutoReduction - click in field = show/hide dropdown
   */
  onAutoReductionClick(event: MouseEvent): void {
    if (this.isReadOnly || this.isDisabled) {
      this.showAutoReduction = false;
    } else {
      this.showAutoReduction = !this.showAutoReduction;
      this.log.debug('onAutoReductionClick show=' + this.showAutoReduction)();
      if (this.showAutoReduction) { // scroll
        setTimeout(() => { // not displayed yet
          this.onAutoReductionScroll(0);
        }, 100);
      } else {
        //  this.useHover = false;
      }
    }
  } // onAutoReductionClick

  /**
   * AutoReduction - Arrow Up/Down = move selection
   */
  onAutoReductionKeyArrow(down: boolean): void {
    let found: boolean | undefined = false;
    let index = 0;
    for (let x = 0; x < this.options.length; x++) {
      const i = down ? x : this.options.length - x - 1;
      const op = this.options[i];
      // console.log('#' + i, op.isMatched);
      if (op.isMatched) {
        if (found) { // previous found
          op.isSelected = true;
          index = i;
          // this.log.debug('onAutoReductionKeyArrow-' + (down ? 'Down' : 'Up') + index, op.label)();
          found = undefined;
          break;
        }
        if (op.isSelected) {
          found = true;
          op.isSelected = false;
        }
      }
    } // forAll
    if (found !== undefined) { // found->lastOne !found->none
      for (let x = 0; x < this.options.length; x++) {
        const i = down ? x : this.options.length - x - 1;
        const op = this.options[ i ];
        if (op.isMatched) {
          op.isSelected = true; // select first
          index = i;
          // this.log.debug('onAutoReductionKeyArrow=' + (down ? 'Down' : 'Up') + index, op.label)();
          break;
        }
      }
    }
    this.onAutoReductionScroll(index);
  } // onAutoReductionKeyArrow

  /**
   * AutoReduction - check for Tab Key
   */
  onAutoReductionKeyDown(event: KeyboardEvent): void {
    // this.log.debug('onAutoReductionKeyDown ' + event.key)();
    if (event.key === 'Tab') {
      this.onAutoReductionKeyEnter();
    }
  }

  /**
   * AutoReduction - Enter/Tab = select selected or first matched or none
   */
  onAutoReductionKeyEnter(): void {
    this.log.debug('onAutoReductionKeyEnter')();
    this.showAutoReduction = false;
    for (const op of this.options) { // selected
      if (op.isSelected) {
        this.onAutoReductionSelect(op);
        return;
      }
    }
    for (const op of this.options) { // matched
      if (op.isMatched) {
        this.onAutoReductionSelect(op);
        return;
      }
    }
    this.onAutoReductionSelect(undefined);
  } // onAutoReductionKeyEnter

  /**
   * AutoReduction - handle keyboard event = update dropdown
   */
  onAutoReductionKeyUp(event: KeyboardEvent): void {
    this.showAutoReduction = true;
    const target = event.target as HTMLInputElement;
    let searchTerm = target.value; // this.autoReductionElement.nativeElement.value;
    this.log.debug('onAutoReductionKeyUp ' + event.key, searchTerm)();
    // special keys
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Home' || event.key === 'End') {
      return; // no term change

    } else if (event.key === 'Escape' || event.key === 'Clear') { // ** Esc
      searchTerm = ''; // clear w/o triggering change
      target.value = searchTerm; // this.autoReductionElement.nativeElement.value = searchTerm;
      for (const op of this.options) {
        op.isSelected = false;
      }

    } else if (event.key === 'ArrowUp') { // ** Up
      this.onAutoReductionKeyArrow(false);
      return;

    } else if (event.key === 'ArrowDown') { // ** Down
      this.onAutoReductionKeyArrow(true);
      return;

    } else if (event.key === 'Enter') { // ** Enter
      this.onAutoReductionKeyEnter();
      target.blur(); // nextSobling is dropdown
      return;

    }
    // update dropdown based on searchTerm
    let count = 0;
    let foundSelected = 'n'; // no
    for (const op of this.options) {
      if (op.match(searchTerm, this.sanitizer)) {
        count++;
      } else {
        op.isSelected = false; // not matching
        continue;
      }
      if (foundSelected === '-') {
        op.isSelected = false; // following
      } else if (foundSelected === 'y') {
        op.isSelected = true; // first
        foundSelected = '-';
      } else if (op.isSelected) {
        foundSelected = 'y'; // next matching
      }
    }
    this.errorMessage = undefined;
    if (count === 0) {
      if (searchTerm) {
        this.errorMessage = 'no match';
      }
      this.showAutoReduction = false;
    }
  } // onAutoReductionKeyUp

  /**
   * AutoReduction = scroll to selected
   */
  onAutoReductionScroll(index: number): void {
    if (!this.autoReductionList) {
      return;
    }
    let currentIndex = 0;
    let heightOffset = 0;
    let parent: HTMLDivElement | undefined;
    let foundSelected = false;
    this.autoReductionList.forEach((ref) => {
      const li = ref.nativeElement;
      if (!parent && li.parentNode) {
        parent = li.parentNode.parentNode as HTMLDivElement; // li>ul>div
      }
      const div = li.firstChild as HTMLElement;
      if (index === undefined) {
        if (!foundSelected) {
          foundSelected = div.getAttribute('aria-selected') === 'true'; // previous dom update
          heightOffset += li.getBoundingClientRect().height;
        }
        if (!foundSelected) {
          currentIndex++;
        }
      } else {
        if (currentIndex < index) {
          heightOffset += li.getBoundingClientRect().height;
          currentIndex++;
        }
      }
    });
    if (parent && (foundSelected || index !== undefined)) {
      const ph = parent.getBoundingClientRect().height;
      parent.scrollTop = heightOffset - ph / 2;
      this.log.debug('onAutoReductionScroll', '#' + currentIndex + ' ' + heightOffset + ' ' + ph + ' = ' + parent.scrollTop)();
    }
  } // onAutoReductionScroll

  /**
   * AutoReduction - select option
   * - hit enter in control
   * - click on Dropdown option = select
   */
  onAutoReductionSelect(op: FkInfo | undefined): void {
    this.log.debug('onAutoReductionSelect', op)();
    this.options.forEach((op0) => {
      op0.isSelected = false;
    });
    if (op) {
      op.isSelected = true;
      this.control.setValue(op.fkId); // ** update control **
      this.control.markAsDirty();
      this.displayFkInfo(op);
    } else {
      this.control.setValue(undefined);
      this.control.markAsDirty();
      this.displayFkInfo(this.optionalFkInfo);
    }

    this.showAutoReduction = false;
    if (this.autoReductionElement) {
      this.autoReductionElement.nativeElement.blur();
    }
    if (this.useHover) {
      this.useHover = false; // hide dropdown
      setTimeout(() => {
        this.useHover = true;
      }, 250);
    }
  } // onAutoReductionSelect

  onFocus(): void {
    if (this.fm) {
      this.fm.onFocus(this.propertyName);
    }
  }

  onFocusChangedTo(propertyName: string): void {
    if (this.propertyName !== propertyName) {
      this.showAutoReduction = false;
      this.showSearch = false;
    }
  }

  /**
   * Text entry
   */
  onInputChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const fkId = target.value;
    this.log.debug('onInputChange', fkId)(); // , event)();
    this.control.setValue(fkId); // ** update control **
  }

  /**
   * @param event Search Click - open search
   */
  onSearchClick(event: Event): void {
    if (this.searchElement) {
      this.searchTerm = this.searchElement.nativeElement.value;
      this.log.debug('onSearchClick', this.searchTerm)();
      this.showSearch = true;
    }
  }

  /**
   * @param fkInfo callback from FkSearch
   */
  onSearchFkSelected(fkInfo: FkInfo): void {
    this.log.debug('onSearchFkSelected', fkInfo)();
    this.showSearch = false;
    if (fkInfo) {
      this.control.setValue(fkInfo.fkId);
    }
  }

  /**
   * @param event Search key Tab = check label - open search
   */
  onSearchKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      const target = event.target as HTMLInputElement;
      this.searchTerm = target.value;
      this.log.debug('onSearchKeyDown ' + event.key, this.searchTerm)();
      if (this.fkInfo.label !== this.searchTerm) {
        this.showSearch = true;
      }
    }
  }

  /**
   * @param event Search key - Enter=open search
   */
  onSearchKeyUp(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    this.searchTerm = target.value;
    this.log.debug('onSearchKeyUp ' + event.key, this.searchTerm)();
    if (event.key === 'Enter') {
      this.showSearch = true;
    }
  } // onSearchKeyUp

  /**
   * Select Changed
   */
  onSelectChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const fkId = target.value;
    this.log.debug('onSelectChange', fkId)(); // , event)();
    this.control.setValue(fkId); // ** update control **
  }

  /** ** ** **
   * Set + Validate fkId - called from ngOnChange
   */
  private setFkValue(fkId: string): void {
    const fkInfo = fkId ? this.fkCache.value(fkId) : this.optionalFkInfo.clone(false);
    const controlFkId = this.control.value;
    // this.log.info('setFkValue =' + fkId + ' (' + controlFkId + ')', this.fkCache, fkInfo, this.control.errors)();
    this.errorMessage = undefined;
    if (this.control.errors) { // reset
      //  this.control.setErrors(null);
    }
    if (fkInfo) { // info found
      if (!fkInfo.validForSelection) {
        this.errorMessage = 'Invalid Selection: ' + fkInfo.label;
        this.control.setErrors({invalidSelection: this.errorMessage});
        this.log.info('setFkValue(1)', this.errorMessage, fkInfo)();
      } else if (fkInfo.fkId === '' && this.dataColumn?.isUiRequired) {
        this.errorMessage = 'Required';
        this.control.setErrors({required: true});
        this.log.info('setFkValue(2)', 'Required')();
      }
    } else { // not found
      if (this.fkCache.isComplete) {
        this.fkInfo.label = '?' + fkId + '?';
        this.fkInfo.description = 'invalid';
        this.fkInfo.validForSelection = false;
        this.errorMessage = 'Not Found id=' + fkId;
        this.control.setErrors({ invalidFk: fkId });
        this.log.info('setFkValue(3)', 'invalid=' + fkId, this.fkCache.toString())();
      } else {
        this.fkInfo.label = '.' + fkId + '.';
        this.fkInfo.description = 'querying';
        const fkTable = this.fkTable();
        if (!FkRequests.isRequested(fkTable, fkId, undefined)) {
          if (this.logAll) {
            this.log.debug('setFkValue(4)', 'request fkId=' + fkId, this.fkCache.toString())();
          }
          this.store.dispatch(fkIdRequestAction({ fkTable, fkId })); // request specifically
        }
      }
    }
    this.displayFkInfo(fkInfo); // update ui value
    //
    this.setOptions(fkId);
  } // setFkValue

  /**
   * Set Options from allOptions
   */
  private setOptions(fkId: string): void {
    if (this.control.errors || this.showAutoReduction) {
      //  return; // don't update options
    }
    this.options = [];

    // restrictions
    const pm: { [key: string]: string } = this.parentMap;
    let parentId: string | undefined; // first (pp - pj)
    Object.values(pm).forEach((v) => {
      if (v && !parentId) {
        parentId = v;
      }
    });

    // this.log.debug('setOptions value=' + fkId, 'parentId=' + parentId,  pm,  this.fkInfo, this.fkCache)();

    let fkInfosTemp = this.fkCache.options(pm); // will be converted to options
    if (!this.fkCache.isComplete) { // incomplete
      if (this.fkCache.fkTable === '-') {
        return; // temp
      }
      if (parentId) {
        if (this.fkCache.parentIsComplete(parentId)) {
          const tt = this.fkCache.parentOptions(parentId);
          if (tt) {
            fkInfosTemp = tt;
            // this.log.debug('setOptions value=' + fkId + ' found parent=' + parentId, pm, fkInfosTemp)();
          }
        } else {
          const fkTable = this.fkTable();
          if (!FkRequests.isRequested(fkTable, undefined, pm)) {
            this.log.debug('setOptions ' + fkId, 'request parent=' + parentId, pm, this.fkCache.toString(), fkInfosTemp)();
            this.store.dispatch(fkOptionsRequestAction({ fkTable, parentMap: pm }));
          }
          return;
        }
      } else if (this.fkCache.hasParents) { // Object.values(pm).length > 0
        fkInfosTemp = []; // empty parent
      } else { // noParent
        if (this.fkType) {
          this.log.warn('setOptions ' + fkId, 'switch from ' + this.fkType, this.fkCache.toString())();
          this.fkType = FkType.SEARCH;
          return;
        }
      }
    } // incomplete

    // convert to options (clone)
    this.options = fkInfosTemp.map((fki) => {
      const cloneFki = fki.clone(fki.fkId === fkId);
      cloneFki.match('', this.sanitizer);
      return cloneFki;
    });
    // disable if there are no options
    this.isDisabled = this.options.length === 0;

    // this.log.debug('setOptions', this.options)();
    // optional
    if (!this.dataColumn?.isUiRequired) {
      this.options.unshift(this.optionalFkInfo);
    }
  } // setOptions

} // FkComponent
