import { Account } from './account';
import { ContactI, ContactD } from './contact-i';
import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* GenTsClass domain: Contact */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Contact
 */
export class Contact {

  /**
   * label: Last Modified Date
   */
  public lastModifiedDate?: number; // optional=true

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * parent fk Account
   * label: Account
   */
  public account?: Account; // optional=true

  public accountId?: number; // optional=true

  public accountSfId?: string; // optional=true

  /**
   * label: Email
   */
  public email?: string; // optional=true

  /**
   * label: Business Fax
   */
  public fax?: string; // optional=true

  /**
   * label: First Name
   */
  public firstName?: string; // optional=true

  /**
   * label: Home Phone
   */
  public homePhone?: string; // optional=true

  /**
   * label: Business Phone
   */
  public phone?: string; // optional=true

  /**
   * label: Last Name
   */
  public lastName: string = ''; // default

  /**
   * label: Mobile Phone
   */
  public mobilePhone?: string; // optional=true

  /**
   * label: Other Phone
   */
  public otherPhone?: string; // optional=true

  /**
   * label: Mailing Address
   */
  public mailingAddress?: string; // optional=true

  /**
   * label: Mailing City
   */
  public mailingCity?: string; // optional=true

  /**
   * label: Mailing Street
   */
  public mailingStreet?: string; // optional=true

  /**
   * label: Mailing Zip/Postal Code
   */
  public mailingPostalCode?: string; // optional=true

  /**
   * label: Mailing State/Province
   */
  public mailingState?: string; // optional=true

  /**
   * label: Title
   */
  public title?: string; // optional=true

  /**
   * label: Mailing Country
   */
  public mailingCountry?: string; // optional=true

  /**
   * fk Contact
   * label: Reports To
   */
  public reportsTo?: Contact; // optional=true

  public reportsToId?: number; // optional=true

  public reportsToSfId?: string; // optional=true

  /**
   * label: Department
   */
  public department?: string; // optional=true

  /**
   * label: Birthdate
   */
  public birthdate?: number; // optional=true

  /**
   * label: Assistant's Name
   */
  public assistantName?: string; // optional=true

  /**
   * label: Asst. Phone
   */
  public assistantPhone?: string; // optional=true

  /**
   * label: Salutation
   */
  public salutation?: string; // optional=true


  /**
   * @return Contact record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'Contact';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): Contact {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, ContactD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, ContactD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: ContactI): Contact {
    Object.keys(ContactD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'reportsTo') { // fk
          this.reportsTo = new Contact();
          this.reportsTo.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // Contact
