import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { ResourceI, ResourceD } from './resource-i';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* GenTsClass domain: Resource */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Resource
 */
export class Resource {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * fk TenantUser
   * label: User
   */
  public user?: TenantUser; // optional=true

  public userId?: number; // optional=true

  /**
   * label: User Sf Id
   */
  public userSfId?: string; // optional=true

  /**
   * label: External User
   */
  public isExternal?: boolean; // optional=true

  /**
   * External User Authorization Name
   * label: Authorization Name
   */
  public authorizationName?: string; // optional=true

  /**
   * label: Code
   */
  public code?: string; // optional=true

  /**
   * label: Manager
   */
  public isManager?: boolean; // optional=true

  /**
   * label: Resource Type
   */
  public resourceType?: string; // optional=true

  /**
   * label: Resource Group
   */
  public resourceGroup?: string; // optional=true

  /**
   * label: Resource Location
   */
  public resourceLocation?: string; // optional=true

  /**
   * label: Skill Set
   */
  public skillSet: string[] = [];

  /**
   * label: Weekend Days
   */
  public weekendDays: string[] = [];

  /**
   * label: Access all Project Lines
   */
  public isAccessAllProjectLines?: boolean; // optional=true

  /**
   * label: Days/week
   */
  public daysWeek?: number; // optional=true

  /**
   * label: Capacity/day
   */
  public capacityDay?: number; // optional=true


  /**
   * @return Resource record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'Resource';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): Resource {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, ResourceD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, ResourceD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: ResourceI): Resource {
    Object.keys(ResourceD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'user') { // fk
          this.user = new TenantUser();
          this.user.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // Resource
