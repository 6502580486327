import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { DataColumn } from './data-column';
import { DataTableI, DataTableD } from './data-table-i';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* GenTsClass domain: DataTable */
/* tslint:disable max-line-length no-inferrable-types */

export enum DataQueryScope {
  ALL = 'ALL',
  ORG = 'ORG',
  USR = 'USR'
}

/**
 * name: DataTable
 */
export class DataTable {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  public isSystemTable?: boolean; // optional=true

  public isDefinitionReadOnly?: boolean; // optional=true

  public isTransaction?: boolean; // optional=true

  public isRecordsReadOnly?: boolean; // optional=true

  /**
   * Record Level
   * label: Read Only Logic
   */
  public readOnlyLogic?: string; // optional=true

  public parentTable?: string; // optional=true

  public parentIdColumn?: string; // optional=true

  public parentLinkColumn?: string; // optional=true

  public defaultSort?: string; // optional=true

  public isCsvFirstRowReader?: boolean; // optional=true

  public csvHeaderRow?: string; // optional=true

  public sfOrgId?: string; // optional=true

  public sfUserId?: string; // optional=true

  /**
   * label: Row Count
   */
  public statRowCount?: number; // optional=true

  /**
   * label: Column Count
   */
  public statColCount?: number; // optional=true

  public statKeyPrefix?: string; // optional=true

  public labelPlural?: string; // optional=true

  public help?: string; // optional=true

  public iconRef?: string; // optional=true

  public columnList: DataColumn[] = [];

  /**
   * Transient Column Map
   * label: Map: name(LowerCase) -> DataColumn
   */
  public columnListMap: {[key: string]: DataColumn} = {};

  public dataQueryScope?: DataQueryScope; // optional=true


  /**
   * @return DataTable record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'DataTable';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param id id of the DataColumn
   * @return DataColumn entity or undefined
   */
  public getColumnById(id: number): DataColumn | undefined {
    if (this.columnList) {
      for (const entity of this.columnList) {
        if (entity.id === id) {
          return entity;
        }
      }
    }
    return undefined;
  } // getColumnById


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): DataTable {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, DataTableD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, DataTableD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: DataTableI): DataTable {
    Object.keys(DataTableD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'columnList') { // child
          this.columnList = [];
          this.columnListMap = {};
          for (const vv of value) {
            const cc = new DataColumn();
            cc.setFromI(vv);
            cc.dataTable = this; // parent
            this.columnList.push(cc); // list
            this.columnListMap[ ('' + cc.name).toLowerCase() ] = cc; // map
          }
        }
      }
    });
    return this;
  } // setFromI

} // DataTable
