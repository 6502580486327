import { Project } from './project';
import { ProjectPhase } from './project-phase';
import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { Invoice } from './invoice';
import { Resource } from './resource';
import { ActivityType } from './activity-type';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';
import { ProjectLine } from './project-line';
import { InvoiceLineI, InvoiceLineD } from './invoice-line-i';

/* GenTsClass domain: InvoiceLine */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: InvoiceLine
 */
export class InvoiceLine {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * parent fk Invoice
   * label: Invoice
   */
  public invoice?: Invoice; // optional=true

  public invoiceId?: number; // optional=true

  /**
   * label: Invoice
   */
  public invoiceSfId?: string; // optional=true

  /**
   * fk Project
   * label: Project
   */
  public project?: Project; // optional=true

  public projectId?: number; // optional=true

  /**
   * label: Project
   */
  public projectSfId?: string; // optional=true

  /**
   * fk ProjectPhase
   * label: Project Phase
   */
  public projectPhase?: ProjectPhase; // optional=true

  public projectPhaseId?: number; // optional=true

  /**
   * label: Project Phase
   */
  public projectPhaseSfId?: string; // optional=true

  /**
   * fk ProjectLine
   * label: Project Line
   */
  public projectLine?: ProjectLine; // optional=true

  public projectLineId?: number; // optional=true

  /**
   * label: Project Line
   */
  public projectLineSfId?: string; // optional=true

  /**
   * fk Resource
   * label: Resource
   */
  public resource?: Resource; // optional=true

  public resourceId?: number; // optional=true

  /**
   * label: Resource
   */
  public resourceSfId?: string; // optional=true

  /**
   * fk ActivityType
   * label: Activity Type
   */
  public activityType?: ActivityType; // optional=true

  public activityTypeId?: number; // optional=true

  /**
   * label: Activity Type
   */
  public activityTypeSfId?: string; // optional=true

  public quantity?: number; // optional=true

  public hours?: number; // optional=true

  public listPrice?: number; // optional=true

  public unitPrice?: number; // optional=true


  /**
   * @return InvoiceLine record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'InvoiceLine';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): InvoiceLine {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, InvoiceLineD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, InvoiceLineD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: InvoiceLineI): InvoiceLine {
    Object.keys(InvoiceLineD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'project') { // fk
          this.project = new Project();
          this.project.setFromI(value);
        } else if (key === 'projectPhase') { // fk
          this.projectPhase = new ProjectPhase();
          this.projectPhase.setFromI(value);
        } else if (key === 'projectLine') { // fk
          this.projectLine = new ProjectLine();
          this.projectLine.setFromI(value);
        } else if (key === 'resource') { // fk
          this.resource = new Resource();
          this.resource.setFromI(value);
        } else if (key === 'activityType') { // fk
          this.activityType = new ActivityType();
          this.activityType.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // InvoiceLine
