import { CRequest } from './c-request';

/* GenTsClass client: com.accorto.domain.LoginRequest */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CRequest
 * name: LoginRequest
 */
export class LoginRequest extends CRequest {

  /**
   * label: User email
   */
  public email?: string; // optional=null

  /**
   * label: User password
   */
  public pw?: string; // optional=null

  /**
   * label: Salesforce Auth Server
   */
  public sfAuth?: string; // optional=null

  /**
   * label: Salesforce Endpoint
   */
  public sfEndpoint?: string; // optional=null

  /**
   * label: Salesforce User Password
   */
  public sfPw?: string; // optional=null

  /**
   * label: Salesforce Session Id
   */
  public sfSid?: string; // optional=null

  /**
   * label: Salesforce User Token
   */
  public sfToken?: string; // optional=null

  /**
   * label: Salesforce Username
   */
  public sfUn?: string; // optional=null

  /**
   * label: User name
   */
  public un?: string; // optional=null

  /**
   * label: Forgot Password
   */
  public isForgotPassword?: boolean; // optional=null

} // LoginRequest
