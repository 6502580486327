import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FkState} from './fk.reducer';
import {FkCache} from './fk-cache';


export const selectFkState = createFeatureSelector<FkState>('fk');

// { [ key: string ]: FkCache }
export const selectFkCaches = createSelector(
  selectFkState,
  fkState => fkState.fkCaches
);

/**
 * @param fkTable e.g. ProjectLine
 * @return FkCache
 */
export const selectFkCache = (fkTable: string) => createSelector(
  selectFkState,
  fkState => fkState.fkCaches[ fkTable ]
);

// Set<string>
export const selectFkIdRequested = createSelector(
  selectFkState,
  fkState => fkState.fkIdRequested
);
