import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataColumn } from '../model/data-column';
import { Logger } from '../log/logger';
import { UiFormField } from '../model/ui-form-field';
import { UiGridField } from '../model/ui-grid-field';
import { FormManager } from '../form/form-manager';
import { FormFocus } from '../form/form-focus';

@Component({
  selector: 'acc-form-hours',
  templateUrl: './form-hours.component.html',
  styleUrls: [ './form-hours.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class FormHoursComponent
  implements OnChanges, AfterViewInit, OnDestroy, FormFocus {

  /** Grid Field */
  @Input() gf?: UiGridField;
  /** Form Field */
  @Input() ff?: UiFormField;
  /** Form Manager */
  @Input() fm?: FormManager;
  /** Base Id */
  @Input() theId?: string;
  /** ReadOnly Overwrite */
  @Input() readonly: boolean = false;
  /** Hours Only */
  @Input() hoursOnly: boolean = true;

  /** Property Name */
  propertyName: string = '';
  /** Field Label */
  label: string = '';
  /** Data Column */
  dataColumn?: DataColumn;

  /** The Hours Value */
  value: number | undefined | null;

  /** Form Element Control */
  control?: FormControl;
  /** Input - immediately available */
  @ViewChild('inp', {static: true}) inputElement?: ElementRef;

  errorMessage?: string;

  showDescription: boolean = false;
  requireDescription: boolean = false;
  propertyNameDescription?: string;

  controlDescription?: FormControl;
  /** TextArea - available afterViewInit */
  @ViewChild('ta', {static: false}) descriptionElement?: ElementRef;
  /** The Description Value */
  valueDescription?: string;


  private subscriptions: Subscription[] = [];
  private log: Logger = new Logger('FormHours');

  constructor() {
  }

  get inputValue(): string {
    if (this.inputElement) {
      return this.inputElement.nativeElement.value;
    }
    return '';
  }

  get isReadOnly(): boolean {
    if (this.readonly || !this.fm) {
      return true;
    }
    return this.gf != null && this.gf.dataColumn != null && this.fm.isReadOnly(this.gf.dataColumn);
  }

  /**
   * Required
   */
  get isUiRequired(): boolean {
    if (this.ff) {
      return this.ff.isRequired();
    }
    if (this.gf && this.gf.dataColumn) {
      return !!this.gf.dataColumn.isUiRequired;
    }
    return false;
  }

  get valid(): boolean {
    if (this.errorMessage) {
      return false;
    }
    if (this.control) {
      // this.log.log('valid ' + this.control.valid, this.control.errors)();
      if (this.control.valid) {
        return true;
      } else {
        return this.control.pristine; // true if untouched
      }
    }
    return false;
  }

  public ngAfterViewInit(): void {
    this.displayValueDescription(this.valueDescription);
  }

  /**
   * Initialize
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.ff) {
      this.propertyName = '' + this.ff.name;
      this.label = '' + this.ff.label;
      this.dataColumn = this.ff.dataColumn;
    } else if (this.gf) {
      this.propertyName = '' + this.gf.name;
      this.label = '' + this.gf.label;
      this.dataColumn = this.gf.dataColumn;
    }
    // description settings
    if (!this.hoursOnly && this.fm && this.fm.getSettings()) {
      // settings.TEShowDescriptions__c == 'yes' || settings.TEShowDescriptions__c == 'required'
      this.showDescription = this.fm.getSettings().hoursDescriptions === 'yes'
        || this.fm.getSettings().hoursDescriptions === 'required';
      this.requireDescription = this.fm.getSettings().hoursDescr === 'required';
      this.propertyNameDescription = this.propertyName.replace('x', 'd');
    }
    // hours
    if (!this.control && this.fm) { // setup
      this.log.setSubName(this.propertyName);
      if (this.fm.registerFocus(this.propertyName, this)) {
        setTimeout(() => {
          if (this.inputElement) {
            this.log.debug('ngOnChanges.focus')();
            this.inputElement.nativeElement.focus();
          }
        }, 500);
      }
      this.control = this.fm.formGroup.controls[this.propertyName] as FormControl;
      this.subscriptions.push(
        this.control.valueChanges.subscribe((value) => {
          this.log.debug('ValueChanges' + (this.errorMessage ? 'error=' + this.errorMessage : ''),
            (this.control?.dirty ? 'dirty ' : '')
            + (this.control?.valid ? '' : 'NotValid ') + 'value=' + value)();
          this.displayValue(value);
        })
      );
    }
    // hours - value
    if (this.control) {
      // const value = this.control.value;
      // this.log.debug('ngOnChanges', changes,
      //    (this.control.dirty ? 'dirty ' : '') + (this.control.valid ? '' : 'NotValid ') + 'value=' + value)();
      this.displayValue(this.control.value);
    } else {
      this.log.info('ngOnChanges NoControl ' + this.propertyName, this.fm?.formGroup)();
    }

    // description
    if (this.showDescription && this.propertyNameDescription) {
      if (!this.controlDescription && this.fm) {
        this.controlDescription = this.fm.formGroup.controls[this.propertyNameDescription] as FormControl;
        this.subscriptions.push(this.controlDescription.valueChanges.subscribe((value) => {
            this.log.debug('ValueChanges(D)',
              (this.controlDescription?.dirty ? 'dirty ' : '')
              + (this.controlDescription?.valid ? '' : 'NotValid ') + 'value=' + value)();
            this.displayValueDescription(value);
          })
        );
      }
      // value
      if (this.controlDescription) {
        const value = this.controlDescription.value;
        this.log.debug('ngOnChanges (D)', changes,
          (this.controlDescription.dirty ? 'dirty ' : '')
          + (this.controlDescription.valid ? '' : 'NotValid ') + 'value=' + value)();
        this.displayValueDescription(value);
      } else {
        this.log.info('ngOnChanges (D) NoControl ' + this.propertyNameDescription, this.fm?.formGroup)();
      }
    }
  } // ngOnChanges

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.subscriptions = [];
  }

  /**
   * Blur - set/display value (hours)
   */
  onBlur(event: Event): void {
    this.log.debug('onBlur', this.value)();
    // this.setValue(Str this.value);
    if (this.control) {
      if (this.value) {
        this.control.setValue(String(this.value.toFixed(2))); // ***
        this.control.markAsDirty();
      } else {
        this.control.setValue(undefined);
        this.control.markAsDirty();
      }
    }
    // this.errorMessage = undefined;
  } // onBlur

  onBlurDescription(event: Event): void {
    const ta = event.target as HTMLTextAreaElement; // 2x7
    ta.style.height = '2rem'; // data-table.component.scss
    ta.style.width = '7rem';
    this.valueDescription = this.descriptionElement?.nativeElement.value;
    this.log.debug('onBlurDescription', this.valueDescription)();
    if (this.controlDescription) {
      if (this.valueDescription) {
        this.controlDescription.setValue(this.valueDescription); // ***
        this.controlDescription.markAsDirty();
      } else {
        this.controlDescription.setValue(undefined);
        this.controlDescription.markAsDirty();
      }
    }
    this.setErrorMessage();
  } // onBlurDescription

  onFocus(): void {
    if (this.fm) {
      this.fm.onFocus(this.propertyName);
    }
  }

  onFocusChangedTo(propertyName: string): void {
  }

  onFocusDescription(event: Event): void {
    const ta = event.target as HTMLTextAreaElement;
    ta.style.height = '7rem';
    ta.style.width = '15rem';
  }

  /**
   * KeyUp - parse + set value
   */
  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Clear') {
      this.displayValue(undefined);
    } else {
      this.value = this.parse(this.inputValue);
      this.log.debug('onKeyUp ' + event.key, this.inputValue, this.value)();
    }
    this.setErrorMessage();
  } // onKeyUp

  setErrorMessage(): void {
    this.errorMessage = undefined;
    if (this.value) {
      if (isNaN(this.value)) {
        this.errorMessage = 'invalid hours';
        this.value = undefined;
      } else {
        if (this.requireDescription) {
          if (this.valueDescription == null || this.valueDescription.length === 0) {
            this.errorMessage = 'description required';
          }
        } else {
          this.errorMessage = undefined;
        }
      }
    } else { // no hours
      if (this.valueDescription) {
        this.errorMessage = 'enter hours';
      }
    }
  } // setErrorMessage

  /**
   * Parse Hours
   * Format options:
   * (a) hh.ff - decimal hours - 1.5
   * -- if hh > 15 - in minutes - 90
   * (b) hh:mm - hours with minutes - 1:30
   * (c) hh,ff - decimal comma - 1,5
   * (d) (d)ay (h)ours (m)minutes - 1d1h1m
   *
   * @param inpValue string input
   * @returns number (hours) or NaN (error) or null (no input)
   */
  parse(inpValue: string): number | null {
    if (inpValue == null || inpValue === '') {
      return null;
    }
    // (a) hh.ff - hours with fraction or minutes (>= 15)
    let hours: number = Number(inpValue);
    if (!isNaN(hours)) { // valid
      if (hours >= 15 && inpValue.indexOf('.') === -1) {
        return hours / 60; // minutes
      }
      return hours;
    }

    // (b) hh:mm - hours with minutes
    let index: number = inpValue.indexOf(':');
    if (index >= 0) {
      hours = 0;
      if (index > 0) {
        const hoursString: string = inpValue.substring(0, index);
        hours = Number(hoursString);
        if (isNaN(hours)) {
          return hours; // invalid
        }
      }
      const minString: string = inpValue.substring(index + 1);
      if (minString.length > 0) {
        const min: number = Number(minString);
        if (isNaN(min)) {
          return min; // invalid
        }
        hours += (min / 60);
      }
      return hours;
    }

    // (c) hh,ff (decimal comma)
    index = inpValue.indexOf(',');
    if (index >= 0) {
      const pointString: string = inpValue.replace(',', '.');
      hours = Number(pointString);
      return hours; // might be invalid
    }

    // (d) (d)ay (h)ours (m)minutes
    hours = 0;
    let remainingString: string = inpValue;
    let hasDHM: boolean = false;
    index = remainingString.indexOf('d');
    if (index !== -1) {
      const valueString: string = remainingString.substring(0, index);
      if (valueString.length > 0) {
        const valueNum: number = Number(valueString);
        if (isNaN(valueNum)) {
          return valueNum; // invalid
        }
        hours = valueNum * 8;
        hasDHM = true;
      }
      remainingString = remainingString.substring(index + 1);
      // this.log.debug('parse d', hours, remainingString)();
    }
    index = remainingString.indexOf('h');
    if (index !== -1) {
      const valueString: string = remainingString.substring(0, index);
      if (valueString.length > 0) {
        const valueNum: number = Number(valueString);
        if (isNaN(valueNum)) {
          return valueNum; // invalid
        }
        hours += valueNum;
        hasDHM = true;
      }
      remainingString = remainingString.substring(index + 1);
      // this.log.debug('parse h', hours, remainingString)();
    }
    index = remainingString.indexOf('m');
    if (index !== -1) {
      const valueString: string = remainingString.substring(0, index);
      if (valueString.length > 0) {
        const valueNum: number = Number(valueString);
        if (isNaN(valueNum)) {
          return valueNum; // invalid
        }
        hours += valueNum / 60;
        hasDHM = true;
      }
      remainingString = remainingString.substring(index + 1);
      // this.log.debug('parse m', hours, remainingString)();
    } else if (remainingString.length > 0) { // minutes directly
      const valueNum: number = Number(remainingString);
      if (isNaN(valueNum)) {
        return valueNum; // invalid
      }
      hours += valueNum / 60;
      hasDHM = true;
    }
    if (hasDHM) {
      return hours;
    }
    return NaN; // cannot parse
  } // parse

  /**
   * update display
   */
  private displayValue(numberValue?: string): void {
    if (numberValue && numberValue.length > 0) {
      this.value = Number(numberValue);
      if (Number.isNaN(this.value)) {
        this.value = undefined;
      }
      if (this.value) {
        if (this.inputElement) {
          this.inputElement.nativeElement.value = String(this.value);
        }
        return;
      }
    }
    // none
    this.value = 0;
    if (this.inputElement) {
      this.inputElement.nativeElement.value = '';
    }
  }  // displayValue

  private displayValueDescription(stringValue?: string): void {
    this.valueDescription = stringValue == null ? '' : stringValue;
    if (this.descriptionElement) {
      this.descriptionElement.nativeElement.value = this.valueDescription;
    }
  }

} // FormHoursComponent
