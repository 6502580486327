import { CResponseImportColumn } from './c-response-import-column';
import { LoginResponse } from './login-response';
import { TzInfo } from './tz-info';
import { CResponseFk } from './c-response-fk';
import { CRequestUi } from './c-request-ui';
import { MenuItem } from './menu-item';
import { FkInfo } from './fk-info';
import { GraphNode } from './graph-node';
import { CResponseImportRow } from './c-response-import-row';
import { LoginRequest } from './login-request';
import { CRequestImport } from './c-request-import';
import { CResponseImport } from './c-response-import';
import { AccortoUtil } from './accorto-util';
import { TzInfoResponse } from './tz-info-response';
import { Graph } from './graph';
import { CRequestFk } from './c-request-fk';
import { CResponseUi } from './c-response-ui';
import { CResponseData } from './c-response-data';
import { DataRecord } from './data-record';
import { CRequestData } from './c-request-data';

/* GenTsClass utility: AccortoCUtil */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * Client Generated Utility for com.accorto.domain
 */
export class AccortoCUtil extends AccortoUtil {


  /**
   * Convert json to CRequestData
   * @return populated CRequestData
   * @param json json info
   */
  static createCRequestData(json: any): CRequestData {
    const entity: CRequestData = new CRequestData();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'dataView') {
        entity.dataView = this.createDataView(value); // cl
      } else if (key === 'isCount') {
        entity.isCount = value;
      } else if (key === 'limit') {
        entity.limit = value;
      } else if (key === 'offset') {
        entity.offset = value;
      } else if (key === 'operation') {
        entity.operation = value;
      } else if (key === 'tableName') {
        entity.tableName = value;
      } else if (key === 'record') {
        entity.record = this.createDataRecord(value); // cl
      } else if (key === 'recordId') {
        entity.recordId = value;
      } else if (key === 'recordIds') {
        entity.recordIds = [];
        for (const vv of value) {
          entity.recordIds.push(vv);
        }
      } else if (key === 'records') {
        entity.records = [];
        for (const vv of value) {
          entity.records.push(this.createDataRecord(vv));
        }
      } else if (key === 'tableId') {
        entity.tableId = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'params') {
        entity.params = {};
        for (const vv of Object.keys(value)) {
          entity.params[vv] = value[vv];
        }
      } else if (key === 'referrer') {
        entity.referrer = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'rid') {
        entity.rid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'timeMs') {
        entity.timeMs = value;
      } else if (key === 'timeString') {
        entity.timeString = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetMinutes') {
        entity.timezoneOffsetMinutes = value;
      }
    } // for loop
    return entity;
  } // createCRequestData


  /**
   * Convert json to CRequestFk
   * @return populated CRequestFk
   * @param json json info
   */
  static createCRequestFk(json: any): CRequestFk {
    const entity: CRequestFk = new CRequestFk();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'fkTable') {
        entity.fkTable = value;
      } else if (key === 'fkId') {
        entity.fkId = value;
      } else if (key === 'fkIds') {
        entity.fkIds = [];
        for (const vv of value) {
          entity.fkIds.push(vv);
        }
      } else if (key === 'limit') {
        entity.limit = value;
      } else if (key === 'searchTerm') {
        entity.searchTerm = value;
      } else if (key === 'parentMap') {
        entity.parentMap = {};
        for (const vv of Object.keys(value)) {
          entity.parentMap[vv] = value[vv];
        }
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'params') {
        entity.params = {};
        for (const vv of Object.keys(value)) {
          entity.params[vv] = value[vv];
        }
      } else if (key === 'referrer') {
        entity.referrer = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'rid') {
        entity.rid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'timeMs') {
        entity.timeMs = value;
      } else if (key === 'timeString') {
        entity.timeString = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetMinutes') {
        entity.timezoneOffsetMinutes = value;
      }
    } // for loop
    return entity;
  } // createCRequestFk


  /**
   * Convert json to CRequestImport
   * @return populated CRequestImport
   * @param json json info
   */
  static createCRequestImport(json: any): CRequestImport {
    const entity: CRequestImport = new CRequestImport();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'importData') {
        entity.importData = value;
      } else if (key === 'importIsFirstLineHeading') {
        entity.importIsFirstLineHeading = value;
      } else if (key === 'importObject') {
        entity.importObject = value;
      } else if (key === 'headingColumns') {
        entity.headingColumns = [];
        for (const vv of value) {
          entity.headingColumns.push(this.createCResponseImportColumn(vv));
        }
      } else if (key === 'mappingIsComplete') {
        entity.mappingIsComplete = value;
      } else if (key === 'mappingName') {
        entity.mappingName = value;
      } else if (key === 'mappingInfos') {
        entity.mappingInfos = value;
      } else if (key === 'testMode') {
        entity.testMode = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'params') {
        entity.params = {};
        for (const vv of Object.keys(value)) {
          entity.params[vv] = value[vv];
        }
      } else if (key === 'referrer') {
        entity.referrer = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'rid') {
        entity.rid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'timeMs') {
        entity.timeMs = value;
      } else if (key === 'timeString') {
        entity.timeString = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetMinutes') {
        entity.timezoneOffsetMinutes = value;
      }
    } // for loop
    return entity;
  } // createCRequestImport


  /**
   * Convert json to CRequestUi
   * @return populated CRequestUi
   * @param json json info
   */
  static createCRequestUi(json: any): CRequestUi {
    const entity: CRequestUi = new CRequestUi();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'uiTabId') {
        entity.uiTabId = value;
      } else if (key === 'uiTabName') {
        entity.uiTabName = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'params') {
        entity.params = {};
        for (const vv of Object.keys(value)) {
          entity.params[vv] = value[vv];
        }
      } else if (key === 'referrer') {
        entity.referrer = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'rid') {
        entity.rid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'timeMs') {
        entity.timeMs = value;
      } else if (key === 'timeString') {
        entity.timeString = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetMinutes') {
        entity.timezoneOffsetMinutes = value;
      }
    } // for loop
    return entity;
  } // createCRequestUi


  /**
   * Convert json to CResponseData
   * @return populated CResponseData
   * @param json json info
   */
  static createCResponseData(json: any): CResponseData {
    const entity: CResponseData = new CResponseData();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'primarySort') {
        entity.primarySort = value;
      } else if (key === 'recordCount') {
        entity.recordCount = value;
      } else if (key === 'recordErrors') {
        entity.recordErrors = [];
        for (const vv of value) {
          entity.recordErrors.push(vv);
        }
      } else if (key === 'records') {
        entity.records = [];
        for (const vv of value) {
          entity.records.push(this.createDataRecord(vv));
        }
      } else if (key === 'tableId') {
        entity.tableId = value;
      } else if (key === 'tableName') {
        entity.tableName = value;
      } else if (key === 'totalCount') {
        entity.totalCount = value;
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createCResponseData


  /**
   * Convert json to CResponseFk
   * @return populated CResponseFk
   * @param json json info
   */
  static createCResponseFk(json: any): CResponseFk {
    const entity: CResponseFk = new CResponseFk();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'isComplete') {
        entity.isComplete = value;
      } else if (key === 'fkTable') {
        entity.fkTable = value;
      } else if (key === 'limit') {
        entity.limit = value;
      } else if (key === 'fkInfos') {
        entity.fkInfos = [];
        for (const vv of value) {
          entity.fkInfos.push(this.createFkInfo(vv));
        }
      } else if (key === 'parentMap') {
        entity.parentMap = {};
        for (const vv of Object.keys(value)) {
          entity.parentMap[vv] = value[vv];
        }
      } else if (key === 'searchTerm') {
        entity.searchTerm = value;
      } else if (key === 'totalCount') {
        entity.totalCount = value;
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createCResponseFk


  /**
   * Convert json to CResponseImport
   * @return populated CResponseImport
   * @param json json info
   */
  static createCResponseImport(json: any): CResponseImport {
    const entity: CResponseImport = new CResponseImport();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'errors') {
        entity.errors = [];
        for (const vv of value) {
          entity.errors.push(vv);
        }
      } else if (key === 'headings') {
        entity.headings = [];
        for (const vv of value) {
          entity.headings.push(vv);
        }
      } else if (key === 'headingColumns') {
        entity.headingColumns = [];
        for (const vv of value) {
          entity.headingColumns.push(this.createCResponseImportColumn(vv));
        }
      } else if (key === 'mapping') {
        entity.mapping = {};
        for (const vv of Object.keys(value)) {
          entity.mapping[vv] = value[vv];
        }
      } else if (key === 'mappingSf') {
        entity.mappingSf = {};
        for (const vv of Object.keys(value)) {
          entity.mappingSf[vv] = value[vv];
        }
      } else if (key === 'importColumns') {
        entity.importColumns = [];
        for (const vv of value) {
          entity.importColumns.push(this.createCResponseImportColumn(vv));
        }
      } else if (key === 'mappingInfos') {
        entity.mappingInfos = value;
      } else if (key === 'messages') {
        entity.messages = [];
        for (const vv of value) {
          entity.messages.push(vv);
        }
      } else if (key === 'rows') {
        entity.rows = [];
        for (const vv of value) {
          entity.rows.push(this.createCResponseImportRow(vv));
        }
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createCResponseImport


  /**
   * Convert json to CResponseImportColumn
   * @return populated CResponseImportColumn
   * @param json json info
   */
  static createCResponseImportColumn(json: any): CResponseImportColumn {
    const entity: CResponseImportColumn = new CResponseImportColumn();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'alias') {
        entity.alias = value;
      } else if (key === 'heading') {
        entity.heading = value;
      } else if (key === 'icon') {
        entity.icon = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'name') {
        entity.name = value;
      }
    } // for loop
    return entity;
  } // createCResponseImportColumn


  /**
   * Convert json to CResponseImportRow
   * @return populated CResponseImportRow
   * @param json json info
   */
  static createCResponseImportRow(json: any): CResponseImportRow {
    const entity: CResponseImportRow = new CResponseImportRow();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'cells') {
        entity.cells = [];
        for (const vv of value) {
          entity.cells.push(vv);
        }
      } else if (key === 'messages') {
        entity.messages = [];
        for (const vv of value) {
          entity.messages.push(vv);
        }
      } else if (key === 'rowNo') {
        entity.rowNo = value;
      } else if (key === 'valueMap') {
        entity.valueMap = {};
        for (const vv of Object.keys(value)) {
          entity.valueMap[vv] = value[vv];
        }
      }
    } // for loop
    return entity;
  } // createCResponseImportRow


  /**
   * Convert json to CResponseUi
   * @return populated CResponseUi
   * @param json json info
   */
  static createCResponseUi(json: any): CResponseUi {
    const entity: CResponseUi = new CResponseUi();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'uiTab') {
        entity.uiTab = this.createUiTab(value); // cl
      } else if (key === 'uiTabId') {
        entity.uiTabId = value;
      } else if (key === 'uiTabName') {
        entity.uiTabName = value;
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createCResponseUi


  /**
   * Convert json to DataRecord
   * @return populated DataRecord
   * @param json json info
   */
  static createDataRecord(json: any): DataRecord {
    const entity: DataRecord = new DataRecord();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'changeMap') {
        entity.changeMap = {};
        for (const vv of Object.keys(value)) {
          entity.changeMap[vv] = value[vv];
        }
      } else if (key === 'errorMsg') {
        entity.errorMsg = value;
      } else if (key === 'id') {
        entity.id = value;
      } else if (key === 'info') {
        entity.info = value;
      } else if (key === 'isActive') {
        entity.isActive = value;
      } else if (key === 'isReadOnly') {
        entity.isReadOnly = value;
      } else if (key === 'isReadOnlyEval') {
        entity.isReadOnlyEval = value;
      } else if (key === 'isSf') {
        entity.isSf = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'link') {
        entity.link = value;
      } else if (key === 'meta') {
        entity.meta = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'recordType') {
        entity.recordType = value;
      } else if (key === 'rowNo') {
        entity.rowNo = value;
      } else if (key === 'sourceMap') {
        entity.sourceMap = {};
        for (const vv of Object.keys(value)) {
          entity.sourceMap[vv] = value[vv];
        }
      } else if (key === 'valueMap') {
        entity.valueMap = {};
        for (const vv of Object.keys(value)) {
          entity.valueMap[vv] = value[vv];
        }
      } else if (key === 'who') {
        entity.who = value;
      }
    } // for loop
    return entity;
  } // createDataRecord


  /**
   * Convert json to FkInfo
   * @return populated FkInfo
   * @param json json info
   */
  static createFkInfo(json: any): FkInfo {
    const entity: FkInfo = new FkInfo();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'description') {
        entity.description = value;
      } else if (key === 'fkId') {
        entity.fkId = value;
      } else if (key === 'fkTable') {
        entity.fkTable = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'parentId') {
        entity.parentId = value;
      } else if (key === 'parent2Id') {
        entity.parent2Id = value;
      } else if (key === 'tenantId') {
        entity.tenantId = value;
      } else if (key === 'validForSelection') {
        entity.validForSelection = value;
      } else if (key === 'valueMap') {
        entity.valueMap = {};
        for (const vv of Object.keys(value)) {
          entity.valueMap[vv] = value[vv];
        }
      }
    } // for loop
    return entity;
  } // createFkInfo


  /**
   * Convert json to Graph
   * @return populated Graph
   * @param json json info
   */
  static createGraph(json: any): Graph {
    const entity: Graph = new Graph();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'info') {
        entity.info = value;
      } else if (key === 'nodes') {
        entity.nodes = [];
        for (const vv of value) {
          entity.nodes.push(this.createGraphNode(vv));
        }
      } else if (key === 'record') {
        entity.record = this.createDataRecord(value); // cl
      } else if (key === 'sfId') {
        entity.sfId = value;
      }
    } // for loop
    return entity;
  } // createGraph


  /**
   * Convert json to GraphNode
   * @return populated GraphNode
   * @param json json info
   */
  static createGraphNode(json: any): GraphNode {
    const entity: GraphNode = new GraphNode();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'dependentIds') {
        entity.dependentIds = [];
        for (const vv of value) {
          entity.dependentIds.push(vv);
        }
      } else if (key === 'durationDays') {
        entity.durationDays = value;
      } else if (key === 'prerequisiteIds') {
        entity.prerequisiteIds = [];
        for (const vv of value) {
          entity.prerequisiteIds.push(vv);
        }
      } else if (key === 'record') {
        entity.record = this.createDataRecord(value); // cl
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'userInfo') {
        entity.userInfo = value;
      }
    } // for loop
    return entity;
  } // createGraphNode


  /**
   * Convert json to LoginRequest
   * @return populated LoginRequest
   * @param json json info
   */
  static createLoginRequest(json: any): LoginRequest {
    const entity: LoginRequest = new LoginRequest();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'email') {
        entity.email = value;
      } else if (key === 'pw') {
        entity.pw = value;
      } else if (key === 'sfAuth') {
        entity.sfAuth = value;
      } else if (key === 'sfEndpoint') {
        entity.sfEndpoint = value;
      } else if (key === 'sfPw') {
        entity.sfPw = value;
      } else if (key === 'sfSid') {
        entity.sfSid = value;
      } else if (key === 'sfToken') {
        entity.sfToken = value;
      } else if (key === 'sfUn') {
        entity.sfUn = value;
      } else if (key === 'un') {
        entity.un = value;
      } else if (key === 'isForgotPassword') {
        entity.isForgotPassword = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'params') {
        entity.params = {};
        for (const vv of Object.keys(value)) {
          entity.params[vv] = value[vv];
        }
      } else if (key === 'referrer') {
        entity.referrer = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'rid') {
        entity.rid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'timeMs') {
        entity.timeMs = value;
      } else if (key === 'timeString') {
        entity.timeString = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetMinutes') {
        entity.timezoneOffsetMinutes = value;
      }
    } // for loop
    return entity;
  } // createLoginRequest


  /**
   * Convert json to LoginResponse
   * @return populated LoginResponse
   * @param json json info
   */
  static createLoginResponse(json: any): LoginResponse {
    const entity: LoginResponse = new LoginResponse();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'googleOauth') {
        entity.googleOauth = value;
      } else if (key === 'menuItems') {
        entity.menuItems = [];
        for (const vv of value) {
          entity.menuItems.push(this.createMenuItem(vv));
        }
      } else if (key === 'salesforceOAuth') {
        entity.salesforceOAuth = value;
      } else if (key === 'state') {
        entity.state = value;
      } else if (key === 'session') {
        entity.session = this.createSession(value); // cl
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createLoginResponse


  /**
   * Convert json to MenuItem
   * @return populated MenuItem
   * @param json json info
   */
  static createMenuItem(json: any): MenuItem {
    const entity: MenuItem = new MenuItem();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'description') {
        entity.description = value;
      } else if (key === 'iconClass') {
        entity.iconClass = value;
      } else if (key === 'iconHref') {
        entity.iconHref = value;
      } else if (key === 'isMainMenu') {
        entity.isMainMenu = value;
      } else if (key === 'keywords') {
        entity.keywords = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'path') {
        entity.path = value;
      } else if (key === 'seqNo') {
        entity.seqNo = value;
      }
    } // for loop
    return entity;
  } // createMenuItem


  /**
   * Convert json to TzInfo
   * @return populated TzInfo
   * @param json json info
   */
  static createTzInfo(json: any): TzInfo {
    const entity: TzInfo = new TzInfo();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'fullName') {
        entity.fullName = value;
      } else if (key === 'id') {
        entity.id = value;
      } else if (key === 'offsetId') {
        entity.offsetId = value;
      } else if (key === 'offsetSeconds') {
        entity.offsetSeconds = value;
      } else if (key === 'shortName') {
        entity.shortName = value;
      } else if (key === 'tzChange') {
        entity.tzChange = value;
      } else if (key === 'tzChangeSeconds') {
        entity.tzChangeSeconds = value;
      }
    } // for loop
    return entity;
  } // createTzInfo


  /**
   * Convert json to TzInfoResponse
   * @return populated TzInfoResponse
   * @param json json info
   */
  static createTzInfoResponse(json: any): TzInfoResponse {
    const entity: TzInfoResponse = new TzInfoResponse();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'infos') {
        entity.infos = [];
        for (const vv of value) {
          entity.infos.push(this.createTzInfo(vv));
        }
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createTzInfoResponse

} // AccortoCUtil
