import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Note} from './note';
import {CResponseI} from '../model/c-response-i';
import {CResponseDataI} from '../model/c-response-data-i';

/**
 * Notification service
 * (tag added to app.component)
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  // Subject
  private dataSource = new BehaviorSubject<Note>(new Note());

  // Observable
  public notes$ = this.dataSource.asObservable();

  constructor() {
  }

  /**
   * Publish Note
   * @param note notification
   */
  add(note: Note): void {
    this.dataSource.next(note);
  }

  /**
   * Show Error
   * @param subject subject
   * @param info optional info
   * @param link optional href
   * @param autoConfirmSecs auto confirm (0)
   */
  addError(subject: string,
           info: string = '',
           link?: string,
           autoConfirmSecs: number = 0): void {
    const note = new Note(subject, info, 'e');
    note.autoConfirmSecs = autoConfirmSecs;
    note.link = link;
    this.add(note);
  }

  /**
   * Show Exception
   * @param subject subject
   * @param err the error
   * @param instructions instruction overwrite
   * @param link optional href
   * @param autoConfirmSecs auto confirm (0)
   */
  addException(subject: string,
               err: any,
               instructions: string = 'Please try again later',
               link?: string,
               autoConfirmSecs: number = 0): void {
    let msg = instructions;
    if (err.message) { // HttpErrorResponse
      msg += ' -- ' + err.message;
    } else {
      msg += ' -- ' + JSON.stringify(err);
    }
    this.addError(subject, msg, link, autoConfirmSecs);
  }

  /**
   * Publich Info
   * @param subject heading
   * @param info detail
   * @param link optional href link
   * @param autoConfirmSecs close automatically after seconds (15)
   */
  addInfo(subject: string, info: string = '',
          link?: string, autoConfirmSecs: number = 15): void {
    const note = new Note(subject, info, 'i');
    note.autoConfirmSecs = autoConfirmSecs;
    note.link = link;
    this.add(note);
  }

  /**
   * Publish Client Response
   * @param subject heading
   * @param response response with detail
   */
  addResponse(subject: string, response: CResponseI): void {
    let info = response.message;
    if (response.error) {
      info = response.error;
      if (response.message) {
        info += ' - ' + response.message;
      }
    }
    const note = new Note(subject, info, response.error ? 'e' : 's');
    note.autoConfirmSecs = response.error ? 0 : 15;
    this.add(note);
  } // addResponse

  /**
   * Publish Client Data Response
   * @param subject heading
   * @param response response with detail
   */
  addResponseData(subject: string, response: CResponseDataI): void {
    let info = response.message;
    if (response.error) {
      info = response.error;
      if (response.message) {
        info += ' - ' + response.message;
      }
    }
    const responses = response.recordErrors;
    if (responses && responses.length > 0) {
      for (let i = 0; i < responses.length; i++) {
        if (responses[ i ] && responses[ i ].length > 0) {
          info += '\n#' + (i + 1) + ': ' + responses[ i ];
        }
      }
    }
    const note = new Note(subject, info, response.error ? 'e' : 's');
    note.autoConfirmSecs = response.error ? 0 : 15;
    this.add(note);
  } // addResponse

  /**
   * Publish Success
   * @param subject heading
   * @param info detail
   * @param link optional href link
   * @param autoConfirmSecs close automatically after seconds (15)
   */
  addSuccess(subject: string | undefined,
             info: string = '',
             link?: string,
             autoConfirmSecs: number = 15): void {
    if (subject) {
      const note = new Note(subject, info, 's');
      note.autoConfirmSecs = autoConfirmSecs;
      note.link = link;
      this.add(note);
    }
  }

  /**
   * Publish Warning
   * @param subject heading
   * @param info detail
   * @param link optional href link
   * @param autoConfirmSecs close automatically after seconds (0)
   */
  addWarning(subject: string,
             info: string = '',
             link?: string,
             autoConfirmSecs: number = 0): void {
    const note = new Note(subject, info, 'w');
    note.autoConfirmSecs = autoConfirmSecs;
    note.link = link;
    this.add(note);
  }

} // NotificationService
