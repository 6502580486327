import {Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {DataColumn, DataType} from '../model/data-column';
import {DataRecord} from '../model/data-record';
import {Trl} from '../utils/trl';
import {AUtil} from '../utils/autil';
import {ModelUtil} from '../utils/model-util';
import {AccortoService} from '../accorto.service';

@Component({
  selector: 'acc-record-value',
  templateUrl: './record-value.component.html',
  styleUrls: ['./record-value.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecordValueComponent implements OnChanges, OnDestroy {

  /** Field Meta */
  @Input() column?: DataColumn;
  /** data */
  @Input() record: DataRecord = new DataRecord();
  /** Fk link class */
  @Input() cssClass: string = '';

  /** value span css classes */
  spanClass: string[] = [];
  /** Actual Record Value */
  recordValue: string | undefined | null;
  /** Active FK GraphVertex */
  isFkLink: boolean = false;
  /** Salesforce FK GraphVertex */
  sfHref?: string;
  /** FK Label */
  displayValueFk?: string;
  /** Property name */
  propertyName: string = '';
  /** Content */
  displayValue?: string;
  /** title with details */
  title?: string;
  /** Fk Subscription */
  private subscription?: Subscription;


  constructor(private config: AccortoService) {
  }

  /**
   * FK link (see FormElement.fkLink)
   * @return [recordHomeUrl, recordId, { ui: ui.name }]
   */
  get fkLink(): any[] {
    if (this.column) {
      const recordValue = this.getRecordValue();
      //
      const recordHomeUrl = AUtil.getUrlForTabName(this.column.fkTable);
      const tabName = this.column.fkTable;
      return [recordHomeUrl, recordValue, {tab: tabName}];
    }
    return [];
  } // fkLink

  /**
   * Display Value if not FK
   * @return read only display value
   */
  getDisplayValue(): string {
    const recordValue = this.getRecordValue(); // this.control.value;
    // console.log(pn, this.column.isFk, recordValue, this.record);
    if (this.column) {
      const dataType = this.column.dataType;
      //
      this.title = this.column.name + ' (' + this.column.dataType + ') ' + recordValue;
      if (this.column.dataType === DataType.BOOLEAN) {
        return Trl.formatBoolean(recordValue);
      }
      if (!recordValue) {
        return '';
      }
      // pick/fk
      if (this.column.isPick && this.column.pickOptionListMap) {
        const pl = this.column.pickOptionListMap[recordValue];
        if (pl && pl.label) {
          return pl.label;
        }
        // console.log('pick ' + recordValue, this.column.pickOptionListMap);
        return 'Pick: ' + recordValue;
      } else if (this.column.isFk) {
        return 'Fk: ' + recordValue; // should not be here
      }
      if (dataType === DataType.STRING) {
        return recordValue;
        // return Trl.formatString(value, maxLength);
      }

      // int
      if (dataType === DataType.INTEGER
        || dataType === DataType.LONG) {
        return Trl.formatInt(recordValue);
      }
      if (dataType === DataType.DECIMAL) {
        return Trl.formatNumber(recordValue);
      }
      if (dataType === DataType.DOUBLE) {
        return Trl.formatFloat(recordValue);
      }

      // Date
      if (dataType === DataType.DATE) {
        return Trl.formatDate(recordValue);
      }
      if (dataType === DataType.TIMESTAMP) {
        return Trl.formatTimestamp(recordValue);
      }
      if (dataType === DataType.TIME) {
        return Trl.formatTime(recordValue);
      }

      if (dataType === DataType.BLOB) {
        return Trl.formatString(recordValue, 30);
      }
      if (dataType === DataType.CLOB) {
        if (this.cssClass === 'slds-form-element__static') {
          return recordValue; // all
        }
        return Trl.formatString(recordValue, 30);
      }
      if (dataType === DataType.ID) {
        this.sfHref = this.config.getSfHref(recordValue);
      }
    }
    // return Trl.formatString(value, maxLength);
    return recordValue ? recordValue : '';
  } // displayValue

  /**
   * Set span class
   * @param changes simple change details
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.column) {
      return;
    }
    for (const propertyName of Object.keys(changes)) { // in changes
      // const chng = changes[ propName ];
      if (propertyName === 'column') {
        this.spanClass = [ this.cssClass ];
        const dataType = this.column.dataType;
        if (dataType === DataType.CLOB) {
          if (this.cssClass === 'slds-form-element__static') {
            this.spanClass = [ this.cssClass, 'clob' ];
          } else {
            this.spanClass = [ this.cssClass, 'clob-table' ];
          }
        }
        this.propertyName = ModelUtil.nameToProperty(this.column.name);
      } else if (propertyName === 'record') {
        this.recordValue = undefined; // force recheck
      }
    }
    //
    this.displayValue = this.getDisplayValue(); // recordValue + displayValue + title
    //
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    this.isFkLink = false;
    if (this.column.isFk) {
      if (this.recordValue) {
        this.isFkLink = true;
        /*
        const cache = this.queryService.getCacheLabelFk(this.column.fkTable, this.recordValue);
        if (cache) {
          this.displayValueFk = cache;
        } else {
          // console.debug(this.getRecordValue());
          this.displayValueFk = '.';
          this._subscription = this.queryService
            .getLabelFk(this.column.fkTable, this.recordValue) // recordType, Id
            .subscribe((v) => {
              this.displayValueFk = v;
              // console.log('-' + v)
            });
        } */
      }
    }
  } // ngOnChanges

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Get Record Value
   * @return record value for column
   */
  getRecordValue(): string | undefined | null {
    if (this.recordValue || this.recordValue === null) {
      return this.recordValue;
    }
    if (!this.column) {
      return null; // test
    }
    // find
    const pnSf: string = this.propertyName.replace(/Id$/, 'SfId');
    if (this.record.changeMap) {
      this.recordValue = this.record.changeMap[this.propertyName];
      if (!this.recordValue && this.column.isFk) {
        this.recordValue = this.record.changeMap[ pnSf ];
      }
    }
    if (!this.recordValue && this.record.valueMap) {
      this.recordValue = this.record.valueMap[ this.propertyName ];
      if (!this.recordValue && this.column.isFk) {
        this.recordValue = this.record.valueMap[ pnSf ];
      }
    }
    // console.log(pn, this.recordValue, this.record);
    if (!this.recordValue) {
      this.recordValue = null; // not found indicator
    }
    return this.recordValue;
  } // getRecordValue

} // RecordValueComponent
