
/* GenTsClass client: com.accorto.client.CRequest */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CRequest
 */
export class CRequest {

  /**
   * label: Client Id
   */
  public cid?: string; // optional=null

  /**
   * label: Description
   */
  public description?: string; // optional=null

  /**
   * label: Intl.DateTimeFormat().resolvedOptions().locale
   */
  public localeName?: string; // optional=null

  /**
   * label: Name
   */
  public name?: string; // optional=null

  /**
   * label: request parameter
   */
  public params: {[key: string]: string|undefined|null} = {};

  /**
   * label: document.referrer
   */
  public referrer?: string; // optional=null

  /**
   * label: client location.href
   */
  public requestUrl?: string; // optional=null

  /**
   * label: request id
   */
  public rid?: number; // optional=null

  /**
   * label: accorto session id
   */
  public sid?: string; // optional=null

  /**
   * label: time in ms
   */
  public timeMs?: number; // optional=null

  /**
   * label: time string
   */
  public timeString?: string; // optional=null

  /**
   * label: timezone
   */
  public timezoneName?: string; // optional=null

  /**
   * label: time offset min
   */
  public timezoneOffsetMinutes?: number; // optional=null

} // CRequest
