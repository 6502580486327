// feature selector
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {TzState} from './timezone.reducer';

// export const selectTimezoneState = state => state.timezone;
export const selectTimezoneState = createFeatureSelector<TzState>('timezone');

// TzInfoResource
export const selectTimezones = createSelector(
  selectTimezoneState,
  timezoneState => timezoneState.response
);

// TzInfo|undefined
export const selectTimezone = createSelector(
  selectTimezoneState,
  timezoneState => timezoneState.tz
);
