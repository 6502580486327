import {Component, HostBinding, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {UiFormSection} from '../model/ui-form-section';
import {DataRecord} from '../model/data-record';
import {Logger} from '../log/logger';
import {UiFormField} from '../model/ui-form-field';
import {FormManager} from '../form/form-manager';
import {DataType} from '../model/data-column';

@Component({
  selector: 'acc-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormSectionComponent implements OnChanges {

  @Input() fm?: FormManager;

  /** Section Meta */
  @Input() section: UiFormSection = new UiFormSection();
  /** data content */
  @Input() record: DataRecord = new DataRecord();
  /** Is in a Tab */
  @Input() isInTab: boolean = false;

  /** Open */
  @HostBinding('class.slds-is-open') isOpen: boolean = true;

  /** collapsed section status */
  private log: Logger = new Logger('FormSection');

  fieldList: UiFormField[] = [];

  // Form Section
  constructor() {
  }

  /**
   * @return UiFormField[] w/o ro fields if new
   */
  get formFieldList(): UiFormField[] {
    if (this.fieldList.length === 0) {
      for (const field of this.section.uiFormFieldList) {
        // new - skip r/o
        if (this.isNew && field.dataColumn && field.dataColumn.isReadOnly) {
          continue;
        }
        // skip FK's if SF
        if (this.isSfRecord && field.dataColumn?.dataType === DataType.FKID) {
          this.log.debug('formFieldList', 'skipping ' + field.name)();
          continue;
        }
        this.fieldList.push(field);
      }
    }
    return this.fieldList;
  } // formFieldList

  /**
   * @return rew record
   */
  get isNew(): boolean {
    return this.record && (!this.record.id || this.record.id.length === 0);
  }

  get isMultipleSections(): boolean {
    return (this.section.isCollapsible !== true)
      && this.section.uiTab?.formSectionList.length !== 1;
  }

  get labelName(): string {
    if (this.section.label != null) {
      return this.section.label;
    }
    return this.section.name ? this.section.name : '';
  }

  get isSfRecord(): boolean {
    return this.record && this.record.isSf === true;
  }

  /**
   * @return boolean section is collapsible
   */
  get isCollapsable(): boolean {
    return this.section && this.section.isCollapsible === true;
  }

  /**
   * @return boolean section is not collapsible and not the only section
   */
  get isNotCollapsible(): boolean {
    if (this.section) {
      if (this.section.uiTab
        && this.section.uiTab.formSectionList
        && this.section.uiTab.formSectionList.length === 1) {
        return false; // don't show the header
      }
      return !this.section.isCollapsible; // also needs slds-theme_shade
    }
    return true;
  }

  /**
   * Section collapse
   * @param changes simple changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // reset
    this.fieldList = [];

    for (const propName of Object.keys(changes)) { // in changes
      // const chng = changes[ propName ];
      if (propName === 'section') {
        this.log.setSubName(this.section.name);
        // this.log.log('ngOnChanges ' + this.section.name, 'collapsed=' + this.section.isCollapsed);
        if (this.section.isCollapsed && !this.isInTab) {
          this.isOpen = false;
        } else {
          this.isOpen = true;
        }
      }
    }
  } // ngOnChanges


  /**
   * @param event toggle collapse
   */
  onSectionClick(event: Event): void {
    this.log.log('onSectionClick collapsible=' + this.section.isCollapsible + ' fromOpen=' + this.isOpen)();
    event.preventDefault();
    this.isOpen = !this.isOpen;
  } // onSectionClick

} // FormSection
