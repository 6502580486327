import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';


import { loadDataAction } from './data.actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class DataEffects {


  loadDatas$ = createEffect(() => this.actions$.pipe(
    ofType(loadDataAction),
    tap()
    ), { dispatch: false }
  );


  constructor(private actions$: Actions) {
  }

}
