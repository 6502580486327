import { Action, createReducer, on } from '@ngrx/store';
import { CResponseFk } from '../model/c-response-fk';
import { FkCache } from './fk-cache';
import * as FkActions from './fk.actions';


export interface FkState {
  // fkTable -> fkCache
  fkCaches: { [ key: string ]: FkCache };
  // fkTable...
  fkRequested: string[];
  // fkId,..
  fkIdRequested: Set<string>;
}

export const initialState: FkState = {
  fkCaches: {},
  fkRequested: [],
  fkIdRequested: new Set<string>()
};

/**
 * FK Reducer
 */
const fkReducer0 = createReducer(
  initialState,

  /** Request - update requested */
  on(FkActions.fkIdRequestAction,
    (state, action) => {
      const fkRequested1 = [ ...state.fkRequested ];
      const fkTable1 = action.fkTable;
      if (fkRequested1.indexOf(fkTable1) === -1) {
        fkRequested1.push(fkTable1);
      }
      const fkIdRequested1 = new Set(state.fkIdRequested);
      if (action.fkId) {
        fkIdRequested1.add(action.fkId);
      }
      if (action.fkIds) {
        action.fkIds.forEach((fkId) => fkIdRequested1.add(fkId));
      }
      // console.log('fkReducer.FkIdRequest', fkRequested1, fkIdRequested1, action.payload.fkId, action.payload.fkIds);
      return {
        ...state,
        fkRequested: fkRequested1,
        fkIdRequested: fkIdRequested1
      };
    }),

  /** Request - update requested */
  on(FkActions.fkOptionsRequestAction,
    (state, action) => {
      // update requested
      const fkRequested2 = [ ...state.fkRequested ];
      const fkTable2 = action.fkTable;
      if (fkRequested2.indexOf(fkTable2) === -1) {
        fkRequested2.push(fkTable2);
      }
      // console.log('fkReducer.FkOptionsRequest', fkRequested2, action.payload.parentMap, action.payload.searchTerm);
      return {
        ...state,
        fkRequested: fkRequested2
      };
    }),

  /** Process Response */
  on(FkActions.fkInfoResponseAction,
    (state, action) => {
      const response: CResponseFk = action.response;
      // update requested
      const fkRequested = [...state.fkRequested];
      const fkTable = response.fkTable;
      if (fkTable) {
        const reqIndex = fkRequested.indexOf(fkTable);
        if (reqIndex > -1) {
          fkRequested.splice(reqIndex, 1);
        }
      }
      //
      const fkIdRequested = new Set(state.fkIdRequested);
      if (response.fkInfos) {
        response.fkInfos.forEach((fki) => {
          if (fki.fkId) {
            fkIdRequested.delete(fki.fkId);
          }
        });
      }
      // console.log('fkReducer.FkInfoResponse', fkRequested, fkIdRequested);
      if (response.error || !response.fkTable) {
        return state;
      } else {
        const fkCaches = Object.assign({}, state.fkCaches);
        const fkc: FkCache = state.fkCaches[response.fkTable];
        const cache = fkc ? fkc.clone() : new FkCache(response.fkTable, undefined);
        // console.info('Fk.reducer', cache, 'old=', fkc);
        cache.add(response);
        fkCaches[response.fkTable] = cache;
        // console.info('Fk.reducer', 'old=' + JSON.stringify(state.fkCaches), 'new=' + JSON.stringify(fkCaches));
        return {
          ...state, fkRequested, fkIdRequested,
          fkCaches
        };
      } // FkOptionResponse OK
    }),

  on(FkActions.fkErrorAction,
    (state, action) => {
      return state;
    })
); // fkReducer


export function fkReducer(state: FkState, action: Action): FkState {
  return fkReducer0(state, action);
} //
