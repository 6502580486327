import { TzInfo } from './tz-info';
import { CResponse } from './c-response';

/* GenTsClass client: com.accorto.domain.TzInfoResponse */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: TzInfoResponse
 */
export class TzInfoResponse extends CResponse {

  public infos: TzInfo[] = [];

} // TzInfoResponse
