import { GraphNode } from './graph-node';
import { DataRecord } from './data-record';

/* GenTsClass client: com.accorto.domain.Graph */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Graph
 */
export class Graph {

  /**
   * client only absolute end
   * label: Client End Ms
   */
  public endMs?: number; // optional=null

  /**
   * label: Client Graph Height
   */
  public height?: number; // optional=null

  public info?: string; // optional=null

  /**
   * label: Client Info Map
   */
  public infoMap: {[key: string]: string|undefined|null} = {};

  /**
   * label: Client Line Height px
   */
  public lineHeight?: number; // optional=null

  /**
   * label: Client Width per day px
   */
  public xDayFactor: number = 0.0;

  /**
   * label: Client Margin px
   */
  public xMargin: number = 0.0;

  /**
   * label: Client Width per month px
   */
  public xMonthFactor: number = 0.0;

  /**
   * label: Client Width per week px
   */
  public xWeekFactor: number = 0.0;

  /**
   * label: Client ZoomLevel = D|W|M
   */
  public zoomLevel: string = 'D';

  /**
   * label: CheckList Items
   */
  public nodes: GraphNode[] = [];

  /**
   * label: Parent
   */
  public record?: DataRecord; // optional=null

  /**
   * label: CheckList SfId
   */
  public sfId?: string; // optional=null

  /**
   * client only absolute start
   * label: Client Start Ms
   */
  public startMs?: number; // optional=null

  /**
   * label: Client Graph Width
   */
  public width?: number; // optional=null


  /**
   * Create node for record
   */
  public addRecord(record: DataRecord): GraphNode {
    const node = new GraphNode();
    node.record = record;
    const sfId = record.value('id');
    node.sfId = sfId ? sfId : undefined;
    node.title = record.name;
    this.nodes.push(node);
    return node;
  } // addRecord


  /**
   * Get Node with SfId
   */
  public getNode(sfId: string): GraphNode | undefined {
    for (const node of this.nodes) {
      if (node.sfId === sfId) {
        return node;
      }
    }
    return undefined;
  } // getNode

} // Graph
