import { Action, createReducer, on } from '@ngrx/store';
import { Session } from '../model/session';
import { MenuItem } from '../model/menu-item';
import * as LoginActions from './login.actions';

export const loginFeatureKey = 'login';

export interface LoginState {
  status?: string;
  heading?: string;
  session?: Session;
  googleOauth?: string;
  salesforceOAuth?: string;
  menuItems?: MenuItem[];
}

export const initialState: LoginState = {
  status: undefined,
  heading: undefined,
  session: undefined,
  googleOauth: undefined,
  salesforceOAuth: undefined,
  menuItems: undefined
};


const loginReducer0 = createReducer(
  initialState,

  on(LoginActions.loginRequestAction,
    (state, action) => {
      return {
        ...state,
        status: 'login requested'
      };
    }),
  on(LoginActions.loginResponseAction,
    (state, action) => {
      const response = action.response;
      if (response) {
        const status = response.error ? 'Error: ' + JSON.stringify(response.error) : response.message;
        return {
          ...state,
          status,
          session: response.session ? response.session : state.session,
          menuItems: response.menuItems ? response.menuItems : state.menuItems,
          googleOauth: response.googleOauth,
          salesforceOAuth: response.salesforceOAuth,
          heading: response.session ? undefined : 'Login'
        };
      } else {
        return state;
      }
    }),
  on(LoginActions.loginErrorAction,
    (state, action) => {
      return {
        ...state,
        status: 'Login Error: ' + action.err,
        heading: 'Login Error'
      };
    }),

  on(LoginActions.logoutRequestAction,
    (state, action) => {
      return {
        ...state,
        status: 'LoggedOut',
        session: undefined,
        heading: 'Login'
      };
    }),

  on(LoginActions.appStatus,
    (state, action) => {
      /* (causes Expression changed after it was checked)
      if (state.session) {
        return {
          ...state,
          status: action.status
        };
      } */
      return state;
    }),
  on(LoginActions.appHeading,
    (state, action) => {
      if (state.session) {
        return {
          ...state,
          heading: action.heading
        };
      }
      return state;
    })
);

export function loginReducer(state: LoginState, action: Action): LoginState {
  return loginReducer0(state, action);
}

