
/* GenTsClass client: com.accorto.domain.TzInfo */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: TzInfo
 */
export class TzInfo {

  /**
   * e.g. British Time - Central European Time - Pacific Time
   * label: Timezone Name
   */
  public fullName: string = ''; // default

  /**
   * e.g. Europe/London - Europe/Brussels - America/Los_Angeles
   * label: Timezone Id
   */
  public id?: string; // optional=null

  /**
   * e.g. Z - +01:00 - -08:00
   * label: Offset Id
   */
  public offsetId: string = ''; // default

  /**
   * e.g. 0 - 36000 - -28800
   * label: Offset in Seconds
   */
  public offsetSeconds: number = 0;

  /**
   * e.g. BT - CET - PT
   * label: Short Name
   */
  public shortName: string = ''; // default

  /**
   * time in ms
   * label: Timezone change
   */
  public tzChange?: number; // optional=null

  /**
   * e.g. 0 - 36000 - -28800
   * label: Offset in Seconds after Change
   */
  public tzChangeSeconds?: number; // optional=null

} // TzInfo
