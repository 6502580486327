/**
 * Calendar Week Day (column header)
 */
import {DateUtil} from '../utils/date-util';

export class WeekDay {
  constructor(public id: number,
              public title: string,
              public abbr: string) {
  }
}


/**
 * Calendar Day (td)
 */
export class Day {
  title: string;
  isToday: boolean;
  disabled: boolean;
  selected: boolean;

  constructor(public date: Date,
              month: number,
              selectedDate: Date,
              endDate: Date | undefined,
              private multi: boolean) {
    this.title = String(date.getUTCDate());
    this.isToday = DateUtil.isToday(date);
    this.disabled = date.getUTCMonth() !== month;
    if (multi && endDate) {
      this.selected = DateUtil.isBetween(date, selectedDate, endDate);
    } else {
      this.selected = DateUtil.isSameDay(date, selectedDate);
    }
  } // constructor

  get cssClass(): string[] {
    const css: string[] = [];
    if (this.isToday) {
      css.push('slds-is-today');
    }
    if (this.disabled) {
      css.push('slds-disabled-text');
    } else if (this.selected) {
      css.push('slds-is-selected');
      if (this.multi) {
        css.push('slds-is-selected-multi');
      }
    }
    return css;
  }

  /**
   * @param enabledDays optional set of enabled days (ms)
   */
  evaluateEnabled(enabledDays?: Set<number>) {
    if (enabledDays) {
      // console.log(this.date.getTime(), enabledDays, !enabledDays.has(this.date.getTime()));
      this.disabled = !enabledDays.has(this.date.getTime());
    }
  }

  /**
   * @param enabledWeekDays optional set of enabled week days (0=Sun .. 6=Sat)
   */
  evaluateEnabledWeek(enabledWeekDays?: Set<number>) {
    if (enabledWeekDays && enabledWeekDays.size > 0) {
      // console.log(this.date.getUTCDay(), enabledWeekDays, !enabledWeekDays.has(this.date.getUTCDay()));
      this.disabled = !enabledWeekDays.has(this.date.getUTCDay());
    }
  }

  toString(): string {
    return 'Day@' + this.date.toISOString();
  }

} // Day


/**
 * Calendar Week (tr)
 */
export class Week {

  // selected
  selected: boolean = false;

  days: Day[] = [];

  constructor(private multi: boolean = false) {
  }

  get cssClass(): string[] {
    const css: string[] = [];
    if (this.multi && this.selected) {
      css.push('slds-has-multi-record-selection');
    }
    return css;
  }

  addDay(day: Day): void {
    this.days.push(day);
    if (day.selected) {
      this.selected = true;
    }
  }

} // Week
