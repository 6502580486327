import { DataColumnI } from './data-column-i';
import { TenantUserI } from './tenant-user-i';
import { TenantI } from './tenant-i';

/* GenTsInterface domain: DataTable */
/* tslint:disable max-line-length no-inferrable-types */

export enum DataQueryScope {
  ALL = 'ALL',
  ORG = 'ORG',
  USR = 'USR'
}

/**
 * name: DataTable
 */
export interface DataTableI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  isSystemTable?: boolean;

  isDefinitionReadOnly?: boolean;

  isTransaction?: boolean;

  isRecordsReadOnly?: boolean;

  /**
   * Record Level
   * label: Read Only Logic
   */
  readOnlyLogic?: string;

  parentTable?: string;

  parentIdColumn?: string;

  parentLinkColumn?: string;

  defaultSort?: string;

  isCsvFirstRowReader?: boolean;

  csvHeaderRow?: string;

  sfOrgId?: string;

  sfUserId?: string;

  /**
   * label: Row Count
   */
  statRowCount?: number;

  /**
   * label: Column Count
   */
  statColCount?: number;

  statKeyPrefix?: string;

  labelPlural?: string;

  help?: string;

  iconRef?: string;

  columnList?: DataColumnI[];

  /**
   * Transient Column Map
   * label: Map: name(LowerCase) -> DataColumn
   */
  columnListMap?: { [key: string]: DataColumnI };

  dataQueryScope?: DataQueryScope;

} // DataTable

/** DataTable Definition */
export const DataTableD = {
  recordTypeName: 'DataTable',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  isSystemTable: {
    n: 'isSystemTable',
    t: 'boolean'
  },
  isDefinitionReadOnly: {
    n: 'isDefinitionReadOnly',
    t: 'boolean'
  },
  isTransaction: {
    n: 'isTransaction',
    t: 'boolean'
  },
  isRecordsReadOnly: {
    n: 'isRecordsReadOnly',
    t: 'boolean'
  },
  readOnlyLogic: {
    n: 'readOnlyLogic',
    t: 'string'
  },
  parentTable: {
    n: 'parentTable',
    t: 'string'
  },
  parentIdColumn: {
    n: 'parentIdColumn',
    t: 'string'
  },
  parentLinkColumn: {
    n: 'parentLinkColumn',
    t: 'string'
  },
  defaultSort: {
    n: 'defaultSort',
    t: 'string'
  },
  isCsvFirstRowReader: {
    n: 'isCsvFirstRowReader',
    t: 'boolean'
  },
  csvHeaderRow: {
    n: 'csvHeaderRow',
    t: 'string'
  },
  sfOrgId: {
    n: 'sfOrgId',
    t: 'string'
  },
  sfUserId: {
    n: 'sfUserId',
    t: 'string'
  },
  statRowCount: {
    n: 'statRowCount',
    t: 'number'
  },
  statColCount: {
    n: 'statColCount',
    t: 'number'
  },
  statKeyPrefix: {
    n: 'statKeyPrefix',
    t: 'string'
  },
  labelPlural: {
    n: 'labelPlural',
    t: 'string'
  },
  help: {
    n: 'help',
    t: 'string'
  },
  iconRef: {
    n: 'iconRef',
    t: 'string'
  },
  columnList: {
    array: true,
    n: 'columnList',
    t: 'DataColumnI'
  },
  columnListMap: {
    n: 'columnListMap',
    t: '{ [key: string]: DataColumnI }'
  },
  dataQueryScope: {
    n: 'dataQueryScope',
    t: 'DataQueryScope'
  },
}; // DataTable
