import { createAction } from '@ngrx/store';

export enum DataActionTypes {
  LoadData = '[Data] Load Data'
}


export const loadDataAction = createAction(
  DataActionTypes.LoadData
);

