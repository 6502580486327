import { DataTableI } from './data-table-i';
import { TenantUserI } from './tenant-user-i';
import { TenantI } from './tenant-i';
import { DataViewWhereI } from './data-view-where-i';

/* GenTsInterface domain: DataView */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: DataView
 */
export interface DataViewI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk DataTableI
   */
  dataTable?: DataTableI;

  /**
   * parent fk DataTableI key
   */
  dataTableId?: number|string;

  /**
   * label: Specific for User
   */
  isUserLevel?: boolean;

  /**
   * Query columns
   * label: Select Columns
   */
  selectColumns?: string[];

  /**
   * Additional Query columns (unchecked)
   * label: Additional Columns
   */
  selectAddlColumns?: string[];

  /**
   * Group by columns, added to column list
   * help: for salesforce use sf columns, e.g. projectSfId
   * label: Group By
   */
  groupBys?: string[];

  /**
   * propertyName ASC|DESC NULLS FIRST|LAST
   * label: Order By
   */
  orderBys?: string[];

  /**
   * label: Where clauses
   */
  whereList?: DataViewWhereI[];

  /**
   * (1 AND 2) OR 3
   * label: Where Logic
   */
  whereLogic?: string;

} // DataView

/** DataView Definition */
export const DataViewD = {
  recordTypeName: 'DataView',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  dataTable: {
    n: 'dataTable',
    t: 'DataTableI'
  },
  dataTableId: {
    n: 'dataTableId',
    t: 'number|string'
  },
  isUserLevel: {
    n: 'isUserLevel',
    t: 'boolean'
  },
  selectColumns: {
    array: true,
    n: 'selectColumns',
    t: 'string'
  },
  selectAddlColumns: {
    array: true,
    n: 'selectAddlColumns',
    t: 'string'
  },
  groupBys: {
    array: true,
    n: 'groupBys',
    t: 'string'
  },
  orderBys: {
    array: true,
    n: 'orderBys',
    t: 'string'
  },
  whereList: {
    array: true,
    n: 'whereList',
    t: 'DataViewWhereI'
  },
  whereLogic: {
    n: 'whereLogic',
    t: 'string'
  },
}; // DataView
