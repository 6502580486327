import { DataRecordI } from './data-record-i';
import { UiGridField } from './ui-grid-field';

/* GenTsClass client: com.accorto.domain.DataRecord */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: DataRecord
 */
export class DataRecord implements DataRecordI {

  /**
   * label: Changed Value Map
   */
  public changeMap: {[key: string]: string|undefined|null} = {};

  /**
   * label: Details
   */
  public details?: any; // optional=null

  /**
   * label: Record Error Message
   */
  public errorMsg?: string; // optional=null

  /**
   * from initial value
   * label: Record ~Id
   */
  public id?: string; // optional=null

  /**
   * label: Record Info Message
   */
  public info?: string; // optional=null

  /**
   * from initial value
   * label: Record ~Active
   */
  public isActive: boolean = true;

  /**
   * Tenant ownership, DataTable.isRecordsReadOnly
   * label: Record R/O
   */
  public isReadOnly?: boolean; // optional=null

  /**
   * DataTable.readOnlyLogicLogic
   * label: Record ReadOnly
   */
  public isReadOnlyEval?: boolean; // optional=null

  /**
   * client only
   * label: Selected
   */
  public isSelected?: boolean; // optional=null

  /**
   * label: Salesforce Record
   */
  public isSf?: boolean; // optional=null

  /**
   * from initial value
   * label: Record ~Label
   */
  public label?: string; // optional=null

  public link?: string; // optional=null

  /**
   * Sort Info
   * label: Query Meta Info
   */
  public meta?: string; // optional=null

  /**
   * from initial value
   * label: Record ~Name
   */
  public name?: string; // optional=null

  /**
   * label: Record Type
   */
  public recordType?: string; // optional=null

  /**
   * 0..x - new records negative
   * label: Row Number
   */
  public rowNo?: number; // optional=null

  /**
   * label: Attachments
   */
  public sourceMap: {[key: string]: string|undefined|null} = {};

  /**
   * label: Original Value Map
   */
  public valueMap: {[key: string]: string|undefined|null} = {};

  /**
   * label: Created/By Updated/By
   */
  public who?: string; // optional=null


  /**
   * Clone Record
   */
  public clone(): DataRecord {
    const newRecord = new DataRecord();
    Object.assign(newRecord, this);
    return newRecord;
  } // clone


  /**
   * @return code|wbs: label|name
   */
  public codeLabel(): string {
    const labelName = (this.label == null ? this.name ? this.name : '?' : this.label);
    const code = this.value('code');
    if (code) {
      return code + ': ' + labelName;
    }
    const wbs = this.value('wbs');
    if (wbs) {
      return wbs + ': ' + labelName;
    }
    return labelName;
  } // codeLabel


  /**
   * @return list of changes (checked) - property names
   */
  public getChanges(): string[] {
    const changes: string[] = [];
    if (this.changeMap) {
      const notChanged: string[] = [];
      for (const key of Object.keys(this.changeMap)) {
        let value = this.changeMap[ key ];
        if (value === 'undefined' || value === '' || value === null) {
          value = undefined;
          this.changeMap[ key ] = value;
        } else { // ensure it is a string
          this.changeMap[ key ] = String(value);
        }
        let oldValue = this.valueMap[ key ];
        if (oldValue === '' || oldValue === null) {
          oldValue = undefined;
        }
        if (Object.is(value, oldValue)) {
          notChanged.push(key);
        } else {
          changes.push(key);
        }
      }
      for (const key of notChanged) {
        delete this.changeMap[ key ];
      }
    }
    return changes;
  } // getChanges


  /**
   * @return true if there are changes
   */
  public isChanged(): boolean {
    return this.changeMap && Object.keys(this.changeMap).length > 0;
  } // isChanged


  /**
   * @return header label or name
   */
  public labelName(): string {
    return this.label == null ? this.name ? this.name : '?' : this.label;
  } // labelName


  /**
   * Set value for property name - return true if changed
   */
  public setValue(propertyName: string, value: string): boolean {
    const oldValue = this.value(propertyName);
    this.changeMap[propertyName] = value === 'undefined' || value === 'null' || value === '' ? null : value;
    if (this.changeMap[propertyName] === this.valueMap[propertyName]) {
      delete this.changeMap[propertyName];
    }
    return oldValue !== this.value(propertyName);
  } // setValue


  /**
   * @return #rowNo tableLabel: value
   */
  public title(gf: UiGridField, index: number): string {
    if (gf && gf.name) {
      const value = this.value(gf.name);
      if (value) {
        return '#' + (index + 1) + ' ' + (gf.dataColumn ? gf.dataColumn.label : '?') + ': ' + value;
      }
      return '-';
    }
    return 'n/a';
  } // title


  /**
   * @return value of property name
   */
  public value(key: string): string|undefined|null {
    if (key) {
      if (this.changeMap && this.changeMap.hasOwnProperty(key)) {
        return this.changeMap[key];
      }
      if (this.valueMap) {
        return this.valueMap[key];
      }
    }
    return undefined;
  } // value


  /**
   * get (changed) boolean value
   */
  public valueBoolean(key: string, defaultValue: boolean = false): boolean {
    const value = this.value(key);
    if (value) {
      return value === 'true' || value === 'Yes';
    }
    return defaultValue;
  } // valueBoolean


  /**
   * @return number value of property name
   */
  public valueNumber(key: string): number|undefined {
    const value = this.value(key);
    if (value != null && value.length > 0) {
      const nn = Number(value);
      if (!Number.isNaN(nn)) {
        return nn;
      }
    }
    return undefined;
  } // valueNumber


  /**
   * @return current values (flat)
   */
  public values(): {[key: string]: string} {
    const vv: {[key: string]: string} = {};
    if (this.valueMap) {
      Object.assign(vv, this.valueMap);
    }
    if (this.changeMap) {
      Object.assign(vv, this.changeMap);
    }
    return vv;
  } // values

} // DataRecord
