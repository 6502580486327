import { createAction, props } from '@ngrx/store';
import { TzInfo } from '../model/tz-info';
import { TzInfoResponse } from '../model/tz-info-response';

export enum TimezoneActionTypes {
  TimezoneRequest = '[Timezone] Request',
  TimezoneResponse = '[Timezone] Response',
  TimezoneError = '[Timezone] Error',
  TimezoneSet = '[Timezone] Set',
}

export const timezoneRequestAction = createAction(
  TimezoneActionTypes.TimezoneRequest
);

export const timezoneReponseAction = createAction(
  TimezoneActionTypes.TimezoneResponse,
  props<{ response: TzInfoResponse }>()
);

export const timezoneErrorAction = createAction(
  TimezoneActionTypes.TimezoneError,
  props<{ err: any }>()
);

export const timezoneSetAction = createAction(
  TimezoneActionTypes.TimezoneSet,
  props<{ tz: TzInfo }>()
);
